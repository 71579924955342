import { ScrollView, Text, View, Image, TextInput, useWindowDimensions } from "react-native"
import { MyInformation } from "../components/MyInformation"
import Sizes from "../constants/Sizes"
import Heights from "../constants/Heights"
import useIsMobile from "../hooks/useIsMobile";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { AppFooter } from "../components/AppFooter";
import { useEffect, useState } from "react";
import { partnerService } from "../services/PartnerService";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AppGap } from "../components/AppGap";
import { AppModal } from "../components/AppModal";
import { AppButton } from "../components/AppButton";
import TextStyles from "../constants/TextStyles";
import Colors from "../constants/Colors";
import { CountryType } from "../types/enums/CountryType";
import CommonStyles from "../constants/CommonStyles";
import { Picker } from "@react-native-picker/picker";
import { activationService } from "../services/ActivationService";
import { numbersOnly } from "../utilities/utilities";
import { validateEmail } from "../utilities/utilities";
import FontFamilys from "../constants/FontFamilys";
import ResponsiveWidths from "../constants/ResponsiveWidths";
import DropDownPicker from "react-native-dropdown-picker";
import CanadaPostAutoCompleteAddress from "../components/CanadaPostAutoCompleteAddress";
import DropdownStyles from "../constants/DropdownStyles";
import GoogleAutoCompleteAddress from "../components/GoogleAutoCompleteAddress";
import { useNavigation } from "@react-navigation/native";

interface ShippingInfo {
  bizId: string,
  contactPersonsName: string,
  email: string,
  phoneNumber: string,
  streetAddress: string,
  city: string,
  province: string,
  postalCode: string,
  country: string
  streetAddressInLocalLanguage: string,
  planTypeId: string,
  simCardQuantity: string,
  note: string,
  carrierId: string,
  shipmentTypeId: string,
}

export default function OrderSimCardsScreen() {
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const navigation = useNavigation();
  const dimensions = useWindowDimensions();
  const user = useSelector((state: RootState) => state.user);
  const isCanada = user.country === CountryType.Canada;
  const countryColor = isCanada ? Colors.blue : Colors.green;
  const overlayColor = isCanada ? Colors.blueOverlay : Colors.greenOverlay;
  const notesWidth = 375;
  const formWidth = 600;
  const [unfilledInputs, setUnfilledInputs] = useState<any[]>([]);
  const [unfilledInputError, setUnfilledInputError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showAddressSearch, setShowAddressSearch] = useState(false);
  const [showRestOfAddressInfo, setShowRestOfAddressSearch] = useState(false);
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const inputBorderColor = isMobile ? Colors.white : Colors.grey;
  const inputLabelColor = isMobile ? Colors.white : Colors.black;
  const extraWideScreen = !(dimensions.width < ResponsiveWidths.xxl);

  // main data types that we get from backend
  const [partnerPlanTypeList, setPartnerPlanTypeList] = useState<Array<{
    PlanTypeId: number,
    PlanDescription: string
  }>>([]);

  const [shipmentTypeList, setShipmentTypeList] = useState<Array<{
    ShipmentTypeId: number,
    ShipmentDescription: string
  }>>([]);

  const [shippingCarrierList, setShippingCarrierList] = useState<Array<{
    CarrierId: number,
    CarrierName: string
  }>>([]);

  const [countryList, setCountryList] = useState<Array<{
    CountryCode: string,
    CountryCodeISO: string,
    CountryName: string
  }>>([]);

  const [shippingInfo, setShippingInfo] = useState<ShippingInfo>({
    bizId: user.userInfo.bid,
    contactPersonsName: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    province: "",
    postalCode: "",
    country: "",
    streetAddressInLocalLanguage: "",
    planTypeId: "",
    simCardQuantity: "",
    note: "",
    carrierId: "",
    shipmentTypeId: ""
  });

  // dropdown-related strings, only for use of dropdown control, not for form submission/validation.
  const [shippingInfoCountry, setShippingInfoCountry] = useState("");
  const [shippingInfoCarrierId, setShippingInfoCarrierId] = useState("");
  const [shippingInfoSimCardQuantity, setShippingInfoSimCardQuantity] = useState("");
  const [shippingInfoPlanTypeId, setShippingInfoPlanTypeId] = useState("");
  const [shippingInfoShipmentTypeId, setShippingInfoShipmentTypeId] = useState("");

  // dropdown-related booleans
  const [openCountry, setOpenCountry] = useState(false);
  const [openCarrierId, setOpenCarrierId] = useState(false);
  const [openSimCardQuantity, setOpenSimCardQuantity] = useState(false);
  const [openPlanTypeId, setOpenPlanTypeId] = useState(false);
  const [openShipmentTypeId, setOpenShipmentTypeId] = useState(false);

  // useEffect(() => {

  // }, [openCountry, openCarrierId, openSimCardQuantity])

  const [simCardQuantityOptions, setSimCardQuantityOptions] = useState([
    { value: "25", label: "25" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "200", label: "200" },
    { value: "300", label: "300" },
    { value: "400", label: "400" },
    { value: "500", label: "500" }
  ]);

  useEffect(() => {
    const getCountries = async () => {
      var getCountriesResponse = await activationService.getCountries();

      if (!getCountriesResponse || !getCountriesResponse.data) {
        return;
      }

      if (getCountriesResponse && getCountriesResponse.data) {
        setCountryList(getCountriesResponse.data);
      }
    }

    const getShippingBasicInfo = async () => {
      var response = await partnerService.getShippingBasicInfo({
        isCanadaCarriers: true
      }, {
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      });

      if (!response || !response.data) {
        return;
      }

      if (response && response.data) {
        setPartnerPlanTypeList(response.data.PartnerPlanTypeList);
        setShipmentTypeList(response.data.ShipmentTypeList);
        setShippingCarrierList(response.data.ShippingCarrierList);
      }
    }
    getShippingBasicInfo().catch(err => console.error(err));
    getCountries().catch(err => console.error(err));
  }, []);

  const onChangeShippingInfo = (input: string, field: keyof ShippingInfo) => {
    if (["bizId", "phoneNumber"].includes(field)) {
      input = numbersOnly(input);
    }

    setShippingInfo({
      ...shippingInfo,
      [field]: input
    });
  }
  const onSelectAddress = (address: { streetAddress: string, city: string, province: string, country: string, postalCode: string }) => {
    setShippingInfo({
      ...shippingInfo,
      streetAddress: address.streetAddress,
      city: address.city,
      province: address.province,
      country: address.country,
      postalCode: address.postalCode
    });
  }

  const insertBulkShippingInfo = async () => {
    let dateOfRequest = new Date().toISOString();
    let carrierId = isCanada ? +shippingInfo.carrierId : 3;
    var insertBulkShippingInfoResponse = await partnerService.insertBulkShippingInfo({
      CompanyName: "",
      DateOfRequest: dateOfRequest,
      BizId: +user.userInfo.bid,
      ReceiverName: shippingInfo.contactPersonsName,
      Email: shippingInfo.email,
      DestinationContactPhoneNum: shippingInfo.phoneNumber,
      StreetAddress: shippingInfo.streetAddress,
      City: shippingInfo.city,
      Province: shippingInfo.province,
      PostalCode: shippingInfo.postalCode,
      Country: shippingInfo.country,
      AddressLocalLang: shippingInfo.streetAddressInLocalLanguage,
      carrierId: carrierId,
      Quantity: +shippingInfo.simCardQuantity,
      PlanTypeId: +shippingInfo.planTypeId,
      ShipmentTypeId: +shippingInfo.shipmentTypeId,
      Note: shippingInfo.note
    }, {
      headers: {
        aaid: user.userInfo.aaid,
        token: user.userInfo.token
      }
    })

    if (!insertBulkShippingInfoResponse || !insertBulkShippingInfoResponse.data) {
      console.log("Something went wrong in insertBulkShippingInfo.");
      return;
    }

    if (insertBulkShippingInfoResponse && insertBulkShippingInfoResponse.data) {
      console.log("response here: ", insertBulkShippingInfoResponse.data);
    }
  }

  const onClickSubmitOrder = () => {
    let emailError = false;
    if (shippingInfo.email && !validateEmail(shippingInfo.email)) {
      emailError = true;
    }
    setShowEmailError(emailError);

    let phoneError = false;
    if (shippingInfo.phoneNumber && shippingInfo.phoneNumber.length > 16) {
      phoneError = true;
    }
    setShowPhoneError(phoneError);

    let inputs = [] as any[];
    Object.keys(shippingInfo).forEach(prop => {
      // check if prop is empty, check if prop is needed or not
      if (!shippingInfo[prop as keyof ShippingInfo] && !["addressAuto", "streetAddressInLocalLanguage", "note"].includes(prop)) {
        inputs.push(prop);
      }
    });
    setUnfilledInputs(inputs);

    if (inputs.length > 0 || emailError) {
      setUnfilledInputError(true);
    } else {
      setUnfilledInputError(false);
      insertBulkShippingInfo().then(() => {
        setShippingInfo({
          bizId: user.userInfo.bid,
          contactPersonsName: "",
          email: "",
          phoneNumber: "",
          streetAddress: "",
          city: "",
          province: "",
          postalCode: "",
          country: "",
          streetAddressInLocalLanguage: "",
          planTypeId: "",
          simCardQuantity: "",
          note: "",
          carrierId: "",
          shipmentTypeId: "",
        } as ShippingInfo);
        setModalVisible(true);
      }).catch(err => console.error(err));
    }
  }

  const handleCloseModal = () => {
    setModalVisible(false);
    navigation.navigate("Home");
  }

  const unfilledInput = (input: string) => {
    return unfilledInputs.includes(input);
  }

  const SimCardOrderInput = (props: { valueName: keyof ShippingInfo, labelText: string }) => {
    const isNote = props.valueName === "note" as keyof ShippingInfo;
    return (
      <View style={{ marginBottom: Sizes.medium }}>
        <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput(props.valueName) ? Colors.red : inputLabelColor }}>{props.labelText}</Text>
        <TextInput
          style={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput(props.valueName) ? Colors.red : inputBorderColor }}
          onChangeText={(e) => onChangeShippingInfo(e, props.valueName)}
          value={shippingInfo[props.valueName]}
          multiline={isNote}
          numberOfLines={isNote ? 4 : 1}
        />
      </View>
    )
  }

  const addressInput = (props: { valueName: keyof ShippingInfo | "addressAuto", labelText: string, selectedCountry: string | undefined }) => {
    const countryIso = countryList.find(country => country.CountryCode === props.selectedCountry)?.CountryCodeISO;
    const isCanadaOrUSA = countryIso === "CA" || countryIso === "US";

    return (
      <View style={{ marginBottom: isMobile ? isCanadaOrUSA ? Sizes.medium : 0 : Sizes.medium, zIndex: 999 }}>
        <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput(props.valueName) ? Colors.red : inputLabelColor }}>{props.labelText}</Text>
        {isCanadaOrUSA ?
          <CanadaPostAutoCompleteAddress onAutoCompleteAddress={getShippingAddressAutoComplete} selectedCountry={countryIso || "CA"} styles={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput("country") ? Colors.red : inputBorderColor }} />
          :
          <GoogleAutoCompleteAddress onAutoCompleteAddress={getShippingAddressAutoComplete} country={countryIso} />
        }
      </View>
    )
  }

  const getShippingAddressAutoComplete = (full_address: any) => {
    onSelectAddress({
      streetAddress: full_address[0],
      city: full_address[1],
      country: full_address[5],
      postalCode: full_address[4],
      province: full_address[6]
    });
  };

  const handleChangeValueFromCountryDropDown = (item: any) => {
    if (item) {
      setShowAddressSearch(true);
    }
    onSelectAddress({
      streetAddress: "",
      city: "",
      country: "",
      postalCode: "",
      province: ""
    });
  };

  return (
    <>
      {!isMobile &&
        <>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large, marginBottom: Sizes.extraLarge }}>
                  <MyInformation />
                  <AppGap direction='vertical' size={Sizes.large}></AppGap>
                  <View style={{ width: extraWideScreen ? (notesWidth + formWidth + 50) : "100%", margin: "auto" }}>
                    <AppGap direction='vertical' size={Sizes.large}></AppGap>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: "space-between" }}>
                      <View style={{ paddingVertical: Sizes.large }}>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white, zIndex: 1, fontStyle: 'italic' }}>Submit Your</Text>
                        <Text style={{ ...TextStyles.subtitle, color: Colors.white, zIndex: 1 }}>SIM Cards Shipping Request</Text>
                        <View style={{ backgroundColor: countryColor, height: '100%', width: '2000%', marginLeft: (-marginHorizontal - 500), position: 'absolute', top: 0 }}></View>
                      </View>
                      <View style={{ position: "relative" }}>
                        <Image source={require('../assets/images/order-sim-card.png')} style={{ width: 195, height: 140, borderRadius: Sizes.medium, position: "absolute", right: 0, top: -20 }}></Image>
                      </View>
                    </View>
                    <AppGap direction='vertical' size={Sizes.large}></AppGap>
                    <View>
                      <Text style={{ ...TextStyles.subtitle, color: Colors.darkBlue }}>Please read these notes before submitting the form:</Text>
                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ flex: 1, flexDirection: 'column', marginTop: Sizes.medium, maxWidth: notesWidth }}>
                          <Text style={{ marginVertical: Sizes.small }}>• If the shipping address is in non-English speaking countries, please enter the address in the local language as well to ensure smooth delivery.</Text>
                          <Text style={{ marginTop: Sizes.small }}>• Estimated delivery time:</Text>
                          <View style={{ marginBottom: Sizes.small, marginLeft: Sizes.medium }}>
                            <Text style={{ fontStyle: "italic" }}>- <Text style={{ fontWeight: "700" }}>Canadian destinations:</Text> 3 to 7 business days</Text>
                            <Text style={{ fontStyle: "italic" }}>- <Text style={{ fontWeight: "700" }}>Other destinations:</Text> 5 to 15 business days</Text>
                          </View>
                          <Text style={{ marginVertical: Sizes.small }}><Text style={{ color: Colors.red, fontWeight: "700" }}>DO NOT</Text> submit the form multiple times. Once you submit and wish to change a detail, please contact your Account Manager.</Text>
                          <Text style={{ marginVertical: Sizes.small }}>• Please use this form for ordering min 20-max 500 SIM cards.  If you wish to order the amount out of this range, kindly contact your Account Manager.</Text>
                          <Text style={{ marginVertical: Sizes.small }}>• If you have any questions regarding shipping, please ask us in the "Note" or directly contact your PhoneBox Account Manager.</Text>
                          <Text style={{ marginTop: Sizes.medium, fontStyle: "italic" }}>- The PhoneBox Shipping Team</Text>
                        </View>
                        <AppGap size={130} />
                        <View style={{ flex: 1, flexDirection: "column", marginTop: Sizes.medium, maxWidth: formWidth }}>
                          {SimCardOrderInput({ valueName: "contactPersonsName", labelText: "Contact Person's Name *" })}
                          {SimCardOrderInput({ valueName: "email", labelText: "Email *" })}
                          {SimCardOrderInput({ valueName: "phoneNumber", labelText: "Phone Number *" })}
                          <View style={{ marginBottom: Sizes.medium, zIndex: openCountry ? 1000 : 1 }}>
                            <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("country") ? Colors.red : inputLabelColor }}>Country *</Text>
                            <DropDownPicker
                              style={{ ...DropdownStyles.desktop, ...CommonStyles.input, borderColor: unfilledInput("country") ? Colors.red : inputBorderColor }}
                              searchable={true}
                              open={openCountry}
                              value={shippingInfoCountry}
                              setOpen={setOpenCountry}
                              setValue={setShippingInfoCountry}
                              onChangeValue={handleChangeValueFromCountryDropDown}
                              schema={{
                                label: 'CountryName',
                                value: 'CountryCode'
                              }}
                              placeholder="Select a Country"
                              // @ts-ignore
                              items={countryList}
                            />
                          </View>
                          {showAddressSearch &&
                            addressInput({ valueName: "addressAuto", labelText: "Address Autofill *", selectedCountry: shippingInfoCountry })
                          }
                          {shippingInfo.country !== "" && shippingInfo.city !== "" &&
                            <>
                              {SimCardOrderInput({ valueName: "streetAddress", labelText: "Address *" })}
                              {SimCardOrderInput({ valueName: "city", labelText: "City *" })}
                              {SimCardOrderInput({ valueName: "province", labelText: "Province/State *" })}
                              {SimCardOrderInput({ valueName: "postalCode", labelText: "Zip Code/Postal Code *" })}
                              {SimCardOrderInput({ valueName: "streetAddressInLocalLanguage", labelText: "Street Address in Local Language (optional)" })}
                            </>
                          }
                          {isCanada &&
                            <View style={{ marginBottom: Sizes.medium }}>
                              <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("carrierId") ? Colors.red : inputLabelColor }}>Network Provider *</Text>
                              <Picker
                                style={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput("carrierId") ? Colors.red : inputBorderColor }}
                                onValueChange={(itemValue: string) => onChangeShippingInfo(itemValue, "carrierId")}
                                selectedValue={shippingInfo.carrierId}
                              >
                                <Picker.Item key={99} label="Select a Carrier" value=""></Picker.Item>
                                {shippingCarrierList.map((carrier, index) => {
                                  return (<Picker.Item key={index} label={carrier.CarrierName} value={carrier.CarrierId} />)
                                })}
                              </Picker>
                            </View>
                          }
                          <View style={{ marginBottom: Sizes.medium }}>
                            <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("simCardQuantity") ? Colors.red : inputLabelColor }}>SIM Card Quantity *</Text>
                            <Picker
                              style={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput("simCardQuantity") ? Colors.red : inputBorderColor }}
                              onValueChange={(itemValue: string) => onChangeShippingInfo(itemValue, "simCardQuantity")}
                              selectedValue={shippingInfo.simCardQuantity}
                            >
                              <Picker.Item key={99} label="Select a SIM Card Quantity" value=""></Picker.Item>
                              {simCardQuantityOptions.map((quantity, index) => {
                                return (<Picker.Item key={index} label={quantity.label} value={quantity.value} />)
                              })}
                            </Picker>
                          </View>
                          <View style={{ marginBottom: Sizes.medium }}>
                            <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("planTypeId") ? Colors.red : inputLabelColor }}>Plan Type *</Text>
                            <Picker
                              style={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput("planTypeId") ? Colors.red : inputBorderColor }}
                              onValueChange={(itemValue: string) => onChangeShippingInfo(itemValue, "planTypeId")}
                              selectedValue={shippingInfo.planTypeId}
                            >
                              <Picker.Item key={99} label="Select a Plan" value=""></Picker.Item>
                              {partnerPlanTypeList.map((plan, index) => {
                                return (<Picker.Item key={index} label={plan.PlanDescription} value={plan.PlanTypeId} />)
                              })}
                            </Picker>
                          </View>
                          <View style={{ marginBottom: Sizes.medium }}>
                            <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("shipmentTypeId") ? Colors.red : inputLabelColor }}>Shipment Type *</Text>
                            <Picker
                              style={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput("shipmentTypeId") ? Colors.red : inputBorderColor }}
                              onValueChange={(itemValue: string) => onChangeShippingInfo(itemValue, "shipmentTypeId")}
                              selectedValue={shippingInfo.shipmentTypeId}
                            >
                              <Picker.Item key={99} label="Select a Shipment Type" value=""></Picker.Item>
                              {shipmentTypeList.map((shipment, index) => {
                                return (<Picker.Item key={index} label={shipment.ShipmentDescription} value={shipment.ShipmentTypeId} />)
                              })}
                            </Picker>
                          </View>
                          {SimCardOrderInput({ valueName: "note", labelText: "Note" })}
                          {unfilledInputError &&
                            <Text style={{ color: Colors.red }}>Please fill in all of the required fields.</Text>
                          }
                          {showEmailError &&
                            <Text style={{ color: Colors.red }}>Please enter a correctly formatted email.</Text>
                          }
                          {showPhoneError &&
                            <Text style={{ color: Colors.red }}>Please enter a valid phone number.</Text>
                          }
                          <View style={{ marginTop: Sizes.large }}>
                            <AppButton text='Submit' onPress={() => onClickSubmitOrder()} />
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <AppGap direction='vertical' size={150} />
          </ScrollView>
          <AppModal visible={modalVisible} onClose={handleCloseModal}>
            <View>
              <Text style={{ ...TextStyles.heading, textAlign: "center" }}>Thank you!</Text>
              <View style={{ padding: Sizes.large }}>
                <Text style={{ textAlign: "center" }}>We have received your order.</Text>
                <Text style={{ textAlign: "center" }}>Close this modal to be redirected to the dashboard.</Text>
              </View>
              <AppButton onPress={handleCloseModal} text="Close"></AppButton>
            </View>
          </AppModal>
          <AppFooter />
        </>
      }
      {isMobile &&
        <ScrollView style={{ flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue, paddingTop: Heights.mainPagePaddingTop }} keyboardShouldPersistTaps={'handled'}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
            <View style={{ flex: 1, paddingTop: Sizes.extraLarge }}>
              <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingTop: Sizes.extraLarge }}>Order Sim Cards</Text>
              <AppGap direction='vertical' size={Sizes.medium}></AppGap>
              <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16 }}>Submit Your SIM Cards Shipping Request</Text>
              <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
              <View style={{ backgroundColor: overlayColor }}>
                <View style={{ paddingVertical: Sizes.large }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.white, paddingBottom: Sizes.medium }}>Please read these notes before submitting the form:</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginVertical: Sizes.small }}>• If the shipping address is in non-English speaking countries, please enter the address in the local language as well to ensure smooth delivery.</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginTop: Sizes.small }}>• Estimated delivery time:</Text>
                  <View style={{ marginBottom: Sizes.small, marginLeft: Sizes.medium }}>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontStyle: "italic" }}>- <Text style={{ fontWeight: "700" }}>Canadian destinations:</Text> 3 to 7 business days</Text>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontStyle: "italic" }}>- <Text style={{ fontWeight: "700" }}>Other destinations:</Text> 5 to 15 business days</Text>
                  </View>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginVertical: Sizes.small }}><Text style={{ color: Colors.red, fontWeight: "700" }}>DO NOT</Text> submit the form multiple times. Once you submit and wish to change a detail, please contact your Account Manager.</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginVertical: Sizes.small }}>• Please use this form for ordering min 20-max 500 SIM cards.  If you wish to order the amount out of this range, kindly contact your Account Manager.</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginVertical: Sizes.small }}>• SIM cards will be powered by Rogers.</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginVertical: Sizes.small }}>• If you have any questions regarding shipping, please ask us in the "Note" or directly contact your PhoneBox Account Manager.</Text>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginTop: Sizes.medium, fontStyle: "italic" }}>- The PhoneBox Shipping Team</Text>
                </View>
                <View style={{ backgroundColor: overlayColor, height: '100%', width: '50%', right: '100%', position: 'absolute', top: 0, zIndex: -1 }}></View>
                <View style={{ backgroundColor: overlayColor, height: '100%', width: '50%', left: '100%', position: 'absolute', top: 0, zIndex: -1 }}></View>
              </View>
              <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
              <View style={{ flex: 1, flexDirection: "column", marginTop: Sizes.medium, maxWidth: formWidth }}>
                {SimCardOrderInput({ valueName: "contactPersonsName", labelText: "Contact Person's Name *" })}
                {SimCardOrderInput({ valueName: "email", labelText: "Email *" })}
                {SimCardOrderInput({ valueName: "phoneNumber", labelText: "Phone Number *" })}
                <View style={{ marginBottom: Sizes.medium, zIndex: openCountry ? 1000 : 1 }}>
                  <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("country") ? Colors.red : inputLabelColor }}>Country *</Text>
                  <DropDownPicker
                    style={{ ...DropdownStyles.mobile, borderColor: unfilledInput("country") ? Colors.red : inputBorderColor }}
                    searchable={true}
                    open={openCountry}
                    listMode={"MODAL"}
                    value={shippingInfoCountry}
                    setOpen={setOpenCountry}
                    setValue={setShippingInfoCountry}
                    onChangeValue={handleChangeValueFromCountryDropDown}
                    schema={{
                      label: 'CountryName',
                      value: 'CountryCode'
                    }}
                    placeholder="Select a Country"
                    // @ts-ignore
                    items={countryList}
                  />
                </View>
                {showAddressSearch && addressInput({ valueName: "addressAuto", labelText: "Address Autofill*", selectedCountry: shippingInfoCountry })}
                {shippingInfo.country !== "" && shippingInfo.city !== "" &&
                  <>
                    {SimCardOrderInput({ valueName: "streetAddress", labelText: "Address *" })}
                    {SimCardOrderInput({ valueName: "city", labelText: "City *" })}
                    {SimCardOrderInput({ valueName: "province", labelText: "Province/State *" })}
                    {SimCardOrderInput({ valueName: "postalCode", labelText: "Zip Code/Postal Code *" })}
                    {SimCardOrderInput({ valueName: "streetAddressInLocalLanguage", labelText: "Street Address in Local Language (optional)" })}
                  </>
                }
                {isCanada &&
                  <View style={{ marginBottom: Sizes.medium, zIndex: openCarrierId ? 10 : 1 }}>
                    <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("carrierId") ? Colors.red : inputLabelColor }}>Network Provider *</Text>
                    <DropDownPicker
                      style={{ ...DropdownStyles.mobile, borderColor: unfilledInput("carrierId") ? Colors.red : inputBorderColor }}
                      placeholder="Select a Carrier"
                      open={openCarrierId}
                      listMode={"MODAL"}
                      value={shippingInfoCarrierId}
                      setOpen={setOpenCarrierId}
                      setItems={setShippingCarrierList}
                      setValue={setShippingInfoCarrierId}
                      onChangeValue={(item: any) => setShippingInfo({ ...shippingInfo, carrierId: item })}
                      schema={{
                        label: 'CarrierName',
                        value: 'CarrierId'
                      }}
                      // @ts-ignore
                      items={shippingCarrierList}
                    />
                  </View>
                }
                <View style={{ marginBottom: Sizes.medium, zIndex: openSimCardQuantity ? 10 : 1 }}>
                  <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("simCardQuantity") ? Colors.red : inputLabelColor }}>SIM Card Quantity *</Text>
                  <DropDownPicker
                    style={{ ...DropdownStyles.mobile, borderColor: unfilledInput("simCardQuantity") ? Colors.red : inputBorderColor }}
                    placeholder="Select a SIM Card Quantity"
                    open={openSimCardQuantity}
                    listMode={"MODAL"}
                    items={simCardQuantityOptions}
                    value={shippingInfoSimCardQuantity}
                    setOpen={setOpenSimCardQuantity}
                    setItems={setSimCardQuantityOptions}
                    setValue={setShippingInfoSimCardQuantity}
                    onChangeValue={(item: any) => setShippingInfo({ ...shippingInfo, simCardQuantity: item })}
                  />
                </View>
                <View style={{ marginBottom: Sizes.medium, zIndex: openPlanTypeId ? 10 : 1 }}>
                  <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("planTypeId") ? Colors.red : inputLabelColor }}>Plan Type *</Text>
                  <DropDownPicker
                    style={{ ...DropdownStyles.mobile, borderColor: unfilledInput("planTypeId") ? Colors.red : inputBorderColor }}
                    placeholder="Select a Plan Type"
                    open={openPlanTypeId}
                    listMode={"MODAL"}
                    value={shippingInfoPlanTypeId}
                    setOpen={setOpenPlanTypeId}
                    setItems={setPartnerPlanTypeList}
                    setValue={setShippingInfoPlanTypeId}
                    onChangeValue={(item: any) => setShippingInfo({ ...shippingInfo, planTypeId: item })}
                    schema={{
                      value: 'PlanTypeId',
                      label: 'PlanDescription'
                    }}
                    // @ts-ignore
                    items={partnerPlanTypeList}
                  />
                </View>
                <View style={{ marginBottom: Sizes.medium, zIndex: openShipmentTypeId ? 10 : 1 }}>
                  <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("shipmentTypeId") ? Colors.red : inputLabelColor }}>Shipment Type *</Text>
                  <DropDownPicker
                    style={{ ...DropdownStyles.mobile, borderColor: unfilledInput("shipmentTypeId") ? Colors.red : inputBorderColor }}
                    placeholder="Select a Shipment Type"
                    open={openShipmentTypeId}
                    listMode={"MODAL"}
                    value={shippingInfoShipmentTypeId}
                    setOpen={setOpenShipmentTypeId}
                    setItems={setShipmentTypeList}
                    setValue={setShippingInfoShipmentTypeId}
                    onChangeValue={(item: any) => setShippingInfo({ ...shippingInfo, shipmentTypeId: item })}
                    schema={{
                      value: 'ShipmentTypeId',
                      label: 'ShipmentDescription'
                    }}
                    // @ts-ignore
                    items={shipmentTypeList}
                  />
                </View>
                {SimCardOrderInput({ valueName: "note", labelText: "Note" })}
                {unfilledInputError &&
                  <Text style={{ color: Colors.red }}>Please fill in all of the required fields.</Text>
                }
                {showEmailError &&
                  <Text style={{ color: Colors.red }}>Please enter a correctly formatted email.</Text>
                }
                {showPhoneError &&
                  <Text style={{ color: Colors.red }}>Please enter a valid phone number.</Text>
                }
                <View style={{ marginTop: Sizes.large }}>
                  <AppButton text='Submit' onPress={() => onClickSubmitOrder()} />
                </View>
                {/* {showSuccessMessage &&
                  <View style={{ marginTop: Sizes.medium }}>
                    <Text style={{ color: countryColor, textAlign: "center" }}>Thank you!  We have received your order.</Text>
                  </View>
                } */}
              </View>
            </View>
          </View>
          <AppGap size={100} direction={'vertical'}></AppGap>
          <AppModal visible={modalVisible} onClose={handleCloseModal}>
            <View>
              <Text style={{ ...TextStyles.heading, textAlign: "center" }}>Thank you!</Text>
              <View style={{ padding: Sizes.large }}>
                <Text style={{ textAlign: "center" }}>We have received your order.</Text>
                <Text style={{ textAlign: "center" }}>Close this modal to be redirected to the dashboard.</Text>
              </View>
              <AppButton onPress={handleCloseModal} text="Close"></AppButton>
            </View>
          </AppModal>
        </ScrollView>
      }
    </>
  )
}
