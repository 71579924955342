import { View, Text, ScrollView } from "react-native";
import { RootStackScreenProps } from "../types";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import Heights from "../constants/Heights";
import Sizes from "../constants/Sizes";
import { MyInformation } from "../components/MyInformation";
import { AppGap } from "../components/AppGap";
import TextStyles from "../constants/TextStyles";
import CommonStyles from "../constants/CommonStyles";
import Colors from "../constants/Colors";
import { CountryType } from "../types/enums/CountryType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { AppFooter } from "../components/AppFooter";
import { AppLabel } from "../components/AppLabel";
import PieChart from 'react-native-pie-chart';
import { useEffect, useState } from "react";
import { partnerService } from "../services/PartnerService";
import { setCanadaClients, setCanadaClientsReport } from "../store/clients";
import PickerDates from "../constants/PickerDates";
import { Month } from "../types/models/Month";
import FontFamilys from "../constants/FontFamilys";
import { AppButton } from "../components/AppButton";
import Victory from "../imports/victory";
import useIsMobile from "../hooks/useIsMobile";

const VictoryChart = Victory.VictoryChart;
const VictoryLine = Victory.VictoryLine;
const VictoryAxis = Victory.VictoryAxis;
const VictoryScatter = Victory.VictoryScatter;
const VictoryTooltip = Victory.VictoryTooltip;
const VictoryArea = Victory.VictoryArea;

type Dataset = {
  postpaid: number[],
  prepaid: number[]
}

type CircleGraphProps = {
  postpaidCount: number,
  prepaidCount: number,
  title: string
}

type GraphGroupProps = {
  title: string,
  datasets: Dataset
}

// we need to preserve if the index is negative,
// indicating that it is a month from the previous year.
// see 'createMonthList' for usage.
type MonthWithIndex = Month & {
  index: number
}

export default function TrendingScreen({navigation}: RootStackScreenProps<'Trending'>) {
  const user = useSelector((state: RootState) => state.user);
  const clients = useSelector((state: RootState) => state.clients);
  const marginHorizontal = useHorizontalContainerMargin();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const backgroundColor = user.country === CountryType.Canada ? Colors.lightBlue : Colors.greenOverlay;
  const [monthList, setMonthList] = useState<MonthWithIndex[]>([]);
  const [activationDatasets, setActivationDatasets] = useState<Dataset>({postpaid: [], prepaid: []});
  const [terminationDatasets, setTerminationDatasets] = useState<Dataset>({postpaid: [], prepaid: []});
  const now = new Date();
  const currentYear = now.getFullYear();
  let sixMonthsAgo = new Date();
  const currentMonth = sixMonthsAgo.getMonth();
  sixMonthsAgo.setMonth(currentMonth - 6);
  let lastYear = sixMonthsAgo.getFullYear();

  const createDatasets = () => {
    let postpaidValues = [] as number[];
    let prepaidValues = [] as number[];

    let postpaidTermValues = [] as number[];
    let prepaidTermValues = [] as number[];

    monthList.forEach(month => {
      let activeYear = month.index < 0 ? lastYear : currentYear;

      let activationClients = clients.canadaClientsReport.filter(entry => {
        let activationDate = new Date(entry.ActivationDate);
        return (new Date (`${activeYear}-${month.value}-01`) <= activationDate) && (activationDate <= new Date(`${activeYear}-${month.value}-28`))
      });

      let terminationClients = clients.canadaClientsReport.filter(entry => {
        let terminationDate = new Date(entry.TerminationDate);
        return (new Date (`${activeYear}-${month.value}-01`) <= terminationDate) && (terminationDate <= new Date(`${activeYear}-${month.value}-28`))
      });

      let prepaidAcc = 0;
      let postpaidAcc = 0;
      activationClients.forEach(client => {
        if (client.PlanType === "Postpaid") {
          postpaidAcc++;
        } else {
          prepaidAcc++;
        }
      });

      let prepaidTerm = 0;
      let postpaidTerm = 0;
      terminationClients.forEach(client => {
        if (client.PlanType === "Postpaid") {
          postpaidTerm++;
        } else {
          prepaidTerm++;
        }
      });

      postpaidTermValues.push(postpaidTerm);
      prepaidTermValues.push(prepaidTerm);

      postpaidValues.push(postpaidAcc);
      prepaidValues.push(prepaidAcc);
    });

    setActivationDatasets({prepaid: prepaidValues, postpaid: postpaidValues});
    setTerminationDatasets({prepaid: prepaidTermValues, postpaid: postpaidTermValues});
  }

  const createMonthList = () => {
    let list = [] as number[];
    for (let i = 0; i < 6; i++) {
      list.unshift(currentMonth - i);
    }

    // months with negative indices are from last year
    let monthList = list.map(num => {
      return { ...PickerDates.months.at(num), index: num };
    });

    setMonthList(monthList as MonthWithIndex[]);
  }

  useEffect(() => {
    const getClientsReportCanada = async () => {
      var response = await partnerService.getActivationsCanada({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        },
        params: {
          startDate: sixMonthsAgo.toISOString().slice(0,10),
          endDate: now.toISOString().slice(0,10)
        }
      })

      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setCanadaClients(responseData));
        dispatch(setCanadaClientsReport());
      }
    }
    getClientsReportCanada().catch(err => { console.error(err) });
    createMonthList();
  }, []);

  useEffect(() => {
    createDatasets();
  }, [clients]);

  const handlePressOrderSimCards = () => {
    navigation.navigate('OrderSimCards');
  }

  const CircleGraph = (props: CircleGraphProps) => {
    const widthAndHeight = !isMobile ? 100 : 80;

    let series = [] as number[];
    let sliceColor = [] as string[];

    if (props.postpaidCount > 0) {
      series.push(props.postpaidCount);
      sliceColor.push(Colors.blue);
    }
    if (props.prepaidCount > 0) {
      series.push(props.prepaidCount);
      sliceColor.push(Colors.darkBlue);
    }
    if (!props.postpaidCount && !props.prepaidCount) {
      return null;
    }

    return (
      <>
        {!isMobile &&
          <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, width: '50%' }}>
            <Text style={{ ...TextStyles.semiSubtitle }}>{props.title}</Text>
            <View style={{ padding: Sizes.medium, marginHorizontal: 'auto' }}>
              <PieChart
                widthAndHeight={widthAndHeight}
                series={series}
                sliceColor={sliceColor}
                coverRadius={0.8}
                coverFill={'#FFF'}
              />
            </View>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                <View style={{ backgroundColor: Colors.blue, height: Sizes.medium, width: Sizes.medium, borderRadius: Sizes.medium, marginRight: Sizes.medium }}/>
                <Text style={{ ...TextStyles.paragraph }}>Monthly</Text>
              </View>
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                <View style={{ backgroundColor: Colors.darkBlue, height: Sizes.medium, width: Sizes.medium, borderRadius: Sizes.medium, marginRight: Sizes.medium }}/>
                <Text style={{ ...TextStyles.paragraph }}>Prepaid</Text>
              </View>
            </View>
          </View>
        }
        {isMobile &&
          <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
            <Text style={{ ...TextStyles.semiSubtitle }}>{props.title}</Text>
            <View style={{ padding: Sizes.medium, marginHorizontal: 'auto', alignItems: 'center', justifyContent: 'center' }}>
              <PieChart
                widthAndHeight={widthAndHeight}
                series={series}
                sliceColor={sliceColor}
                coverRadius={0.8}
                coverFill={'#FFF'}
              />
            </View>
            <View style={{ flex: 1, flexDirection: "row", alignItems: 'center', justifyContent: 'center' }}>
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                <View style={{ backgroundColor: Colors.blue, height: Sizes.medium, width: Sizes.medium, borderRadius: Sizes.medium, marginRight: Sizes.small }}/>
                <Text style={{ ...TextStyles.paragraph }}>Monthly</Text>
              </View>
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center", marginLeft: Sizes.medium }}>
                <View style={{ backgroundColor: Colors.darkBlue, height: Sizes.medium, width: Sizes.medium, borderRadius: Sizes.medium, marginRight: Sizes.small }}/>
                <Text style={{ ...TextStyles.paragraph }}>Prepaid</Text>
              </View>
            </View>
          </View>
        }
      </>
    )
  }

  const LineGraph = (props: any) => {
    const datasets = props.datasets;

    let XmonthLabel = monthList.map(month => { return month.label});
    let XmonthValue = monthList.map(month => { return month.value});

    let prepaidData = datasets.prepaid.map((value: number, index: number) => {
      return {x: XmonthValue[index], y: value, label: value}
    })

    let postpaidData = datasets.postpaid.map((value: number, index: number) => {
      return {x: XmonthValue[index], y: value, label: value}
    })

    const chartStyles = {
      axis: {
        tickLabels: { fontSize: 9, fontFamily: "Arial" },
        axis: { stroke: "transparent" }
      },
      area: {
        data: {fill: "#EEEEEE" },
        labels: { fontSize: 0 }
      },
      line: {
        prepaid: {
          data: { stroke: Colors.darkBlue, strokeWidth: 3 },
          labels: { fontSize: 0 },
        },
        postpaid: {
          data: { stroke: Colors.blue, strokeWidth: 3 },
          labels: { fontSize: 0 },
        }
      }
    }

    return (
      <>
        {!isMobile &&
          <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
          <Text style={{ ...TextStyles.semiSubtitle, paddingBottom: Sizes.medium }}>{props.title}</Text>          
          <VictoryChart width={400} height={270} domainPadding={{ y: 15 }}>
            <VictoryAxis 
              tickValues={XmonthValue}
              tickFormat={XmonthLabel}
              style={chartStyles.axis}
            />
            <VictoryAxis 
              dependentAxis
              style={chartStyles.axis}
            />
            <VictoryArea
              interpolation="natural" 
              data={prepaidData}
              style={chartStyles.area}
            />
            <VictoryLine 
              interpolation="natural"
              data={prepaidData}
              style={chartStyles.line.prepaid}
            />
            <VictoryLine
              interpolation="natural" 
              data={postpaidData}
              style={chartStyles.line.postpaid}
            />
            <VictoryScatter 
              data={prepaidData}
              size={4}
              style={{data: { fill: Colors.darkBlue }}}
              labelComponent={
                <VictoryTooltip
                  style={{fontSize: 13, fontWeight: "bold"}}
                  flyoutStyle={{ fill: "#fff" }}
                  flyoutPadding={{ top: 5, bottom: 5, left: 10, right: 10 }}
                  renderInPortal={false}
                />
              }
            />
            <VictoryScatter 
              data={postpaidData}
              size={4}
              style={{ data: { fill: Colors.blue }}}
              labelComponent={
                <VictoryTooltip
                  style={{fontSize: 13, fontWeight: "bold"}}
                  flyoutStyle={{ fill: "#fff" }}
                  flyoutPadding={{ top: 5, bottom: 5, left: 12, right: 12 }}
                  renderInPortal={false}
                />
              }
            />
          </VictoryChart>
          </View>
        }
        {isMobile &&
          <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
            <Text style={{ ...TextStyles.semiSubtitle, paddingBottom: Sizes.medium }}>{props.title}</Text>   
            <View style={{ flex: 1, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <VictoryChart width={330} height={270} domainPadding={{ y: 30, x: 10 }}>
                <VictoryAxis 
                  tickValues={XmonthValue}
                  tickFormat={XmonthLabel}
                  style={chartStyles.axis}
                />
                <VictoryAxis 
                  dependentAxis
                  style={chartStyles.axis}
                />
                <VictoryArea
                  interpolation="natural" 
                  data={prepaidData}
                  style={chartStyles.area}
                />
                <VictoryLine 
                  interpolation="natural"
                  data={prepaidData}
                  style={chartStyles.line.prepaid}
                />
                <VictoryLine
                  interpolation="natural" 
                  data={postpaidData}
                  style={chartStyles.line.postpaid}
                />
                <VictoryScatter 
                  data={prepaidData}
                  size={3}
                  style={{data: { fill: Colors.darkBlue }}}
                  labelComponent={
                    <VictoryTooltip
                      style={{fontSize: 12, fontWeight: "bold"}}
                      flyoutStyle={{ fill: "#fff" }}
                      flyoutPadding={{ top: 5, bottom: 5, left: 10, right: 10 }}
                      renderInPortal={false}
                    />
                  }
                />
                <VictoryScatter 
                  data={postpaidData}
                  size={3}
                  style={{ data: { fill: Colors.blue }}}
                  labelComponent={
                    <VictoryTooltip
                      style={{fontSize: 12, fontWeight: "bold"}}
                      flyoutStyle={{ fill: "#fff" }}
                      flyoutPadding={{ top: 5, bottom: 5, left: 12, right: 12 }}
                      renderInPortal={false}
                    />
                  }
                />
              </VictoryChart>
            </View>   
          </View>        
        }
      </>

    );
  }

  const GraphGroup = (props: GraphGroupProps) => {
    const postpaidCount = props.datasets.postpaid.reduce((partialSum: number, a: number) => partialSum + a, 0);
    const prepaidCount = props.datasets.prepaid.reduce((partialSum: number, a: number) => partialSum + a, 0);
    const totalCount = prepaidCount + postpaidCount;

    return (
      <>
        {!isMobile &&
          <View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "space-between" }}>
              <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, width: '50%' }}>
                <Text style={{ ...TextStyles.semiSubtitle }}>{`Total ${props.title}`}</Text>
                <View style={{ padding: Sizes.medium, borderRadius: Sizes.medium, backgroundColor: backgroundColor, marginHorizontal: 'auto', marginVertical: 'auto' }}>
                  <Text numberOfLines={1} style={{ paddingHorizontal: Sizes.medium, fontFamily: FontFamilys.semiBold, fontSize: 50, color: Colors.blue, textAlign: 'center' }}>{totalCount}</Text>
                </View>
              </View>
              <AppGap size={Sizes.medium} direction="horizontal"/>
              <CircleGraph prepaidCount={prepaidCount} postpaidCount={postpaidCount} title="Plan Type"/>
            </View>
            <AppGap size={Sizes.medium} direction="vertical"/>
            <LineGraph datasets={props.datasets} title={props.title}/>  
          </View>
        }
        {isMobile &&
          <View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: "space-between" }}>
              <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, width: '48%' }}>
                <Text style={{ ...TextStyles.semiSubtitle, marginBottom: Sizes.medium }}>{`Total ${props.title}`}</Text>
                <View style={{ padding: Sizes.medium, borderRadius: Sizes.medium, backgroundColor: backgroundColor, marginTop: Sizes.medium }}>
                  <Text numberOfLines={1} style={{ paddingHorizontal: Sizes.medium, fontFamily: FontFamilys.semiBold, fontSize: 30, color: Colors.blue, textAlign: 'center' }}>{totalCount}</Text>
                </View>
              </View>
              <AppGap size={Sizes.medium} direction="horizontal"/>
              <View style={{ width: '48%' }}>
                <CircleGraph prepaidCount={prepaidCount} postpaidCount={postpaidCount} title="Plan Type"/>
              </View>
            </View>
            <AppGap size={Sizes.medium} direction="vertical"/>
            <LineGraph datasets={props.datasets} title={props.title}/>  
          </View>
        }
      </>
    )
  }

  return (
    <>
    {!isMobile &&
      <>
        <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
            <View style={{ flex: 1 }}>
              <View style={{ marginTop: Sizes.large }}>
                <MyInformation />
                <AppGap size={Sizes.large} direction="vertical"/>
                <AppLabel color={countryColor} text='6 Months Trending'/>
                <AppGap size={Sizes.large} direction="vertical"/>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <GraphGroup title="Activations" datasets={activationDatasets}/>
                  <AppGap direction="horizontal" size={Sizes.large}/>
                  <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, borderColor: Colors.blue, borderWidth: 1 }}>
                    <Text style={{ ...TextStyles.semiSubtitle, color: Colors.blue }}>Do you have enough SIM Cards?</Text>
                    <AppGap direction="vertical" size={Sizes.medium}/>
                    <AppButton text="SIM Card inventory" size="sm" shape="rect" textSize={14} onPress={() => navigation.navigate('Inventory')}/>
                    <AppGap direction="vertical" size={Sizes.medium}/>
                    <AppButton text="Order SIM Card" size="sm" shape="rect" textSize={14} onPress={handlePressOrderSimCards}/>
                  </View>
                  <AppGap direction="horizontal" size={Sizes.large}/>
                  <GraphGroup title="Terminations" datasets={terminationDatasets}/>
                </View>
              </View>
              <AppGap size={100} direction={'vertical'}></AppGap>
            </View>
          </View>
        </ScrollView>
        <AppFooter/>
      </>
    }
    {isMobile &&
      <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
          <View style={{ flex: 1 }}>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>6 Months Trending</Text>
            <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.large  }}>Activations</Text>
            <GraphGroup title="Activations" datasets={activationDatasets}/>
            <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.large  }}>Terminations</Text>
            <GraphGroup title="Terminations" datasets={terminationDatasets}/>
            <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.large  }}>Do you have enough SIM Cards?</Text>
            <AppButton text="SIM Card inventory" shape="rect" textSize={14} onPress={() => navigation.navigate('Inventory')}/>
            <AppGap direction="vertical" size={Sizes.medium}/>
            <AppButton text="Order SIM Card" shape="rect" textSize={14} onPress={handlePressOrderSimCards}/>
          </View>
        </View>
        <AppGap size={150} direction={'vertical'}></AppGap>
      </ScrollView>
    }
    </>
  )
}