import React, { useState, useEffect } from "react";
import DropDownPicker from "react-native-dropdown-picker";
import useIsMobile from "../hooks/useIsMobile";


interface CPAutoCompleteAddressProps {
    // autoCompleteType: string;
    onAutoCompleteAddress: Function;
    selectedCountry: string;
    styles?: any;
}

interface Address {
    street: string;
    line1: string;
    line2: string;
    city: string;
    postalCode: string;
    provinceShort: string;
    countryShort: string;
    provinceFull: string;
    countryFull: string;
}

const CanadaPostAutoCompleteAddress: React.FC<CPAutoCompleteAddressProps> = (props: CPAutoCompleteAddressProps) => {
    const isMobile = useIsMobile();
    const [query, setQuery] = useState("");
    const [results, setResults] = useState([] as any[]);
    const [locations, setLocations] = useState([] as any[]);
    const [selecedLocation, setSelecedLocation] = useState({} as any);
    const [isLoading, setIsLoading] = useState(false);
    const [openDropdown, setOpenDropdown] = useState(false);

    const apiFindUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws?Key=${process.env.CANADA_POST_API_KEY}`;
    const apiRetrieveUrl = `https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws?Key=${process.env.CANADA_POST_API_KEY}`;

    const handleSelect = async (event : any) => {
        if (event.next === "Find") {
            let selectedEvent = event;
            setIsLoading(true);
            handleSearchLastId(selectedEvent.id, selectedEvent.addressCount);   
        }
        else if (event.next === "Retrieve") {
            handleRetrieve(event);
        }
    }

    const handleRetrieve = async (event : any) => {
        let selectedEventId = event.id;
        let url = `${apiRetrieveUrl}&Id=${encodeURIComponent(selectedEventId)}`;

        try {
            const response = await fetch(url);
            const jsonData = await response.json();
            const data = jsonData.Items[0];

            if (data) {
                let address: Address = {
                    street: data.Line1 + (data.Line2 ? ', ' + data.Line2 : ''),
                    line1: data.Line1,
                    line2: data.Line2,
                    city: data.City,
                    postalCode: data.PostalCode,
                    provinceShort: data.Province,
                    countryShort: data.CountryIso2,
                    provinceFull: data.ProvinceName,
                    countryFull: data.CountryName
                };
                sendCompleteAddress(address);
                setSelecedLocation({} as any);
                setLocations([] as any[]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const sendCompleteAddress = (address: Address) => {
        let addressArray = [address.street, address.city, address.provinceShort, address.countryShort, address.postalCode, address.countryFull, address.provinceFull];
        props.onAutoCompleteAddress(addressArray);
    }

    const handleChange = (event : any) => {
        if (event.length <= 1) {
            return;
        }
        setIsLoading(true);
        setOpenDropdown(true);
        setQuery(event);
        handleSearchTerm(event);
    }

    const handleSearchTerm = async (query :any) => {
        let url = `${apiFindUrl}&SearchTerm=${encodeURIComponent(query)}&Country=${encodeURIComponent(props.selectedCountry)}&MaxResults=${encodeURIComponent(8)}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            setResults(data.Items);
        } catch (error) {
            console.log(error);
        }
    }

    const handleSearchLastId = async (id: any, retrieveMaximum: any) => {
        let url = `${apiFindUrl}&LastId=${encodeURIComponent(id)}&MaxResults=${encodeURIComponent(retrieveMaximum)}`;

        try {
            const response = await fetch(url);
            const data = await response.json();
            let newResults = data.Items;
            setResults(newResults);
        
            let tempLocations = newResults.map((result : any) => {
                let maxAddressCount = 10;
                return {
                    id: result.Id,
                    name: result.Text + ", " + result.Description,
                    next: result.Next,
                    addressCount: maxAddressCount,
                }
            });
            setLocations(tempLocations);
            setIsLoading(false);
            setOpenDropdown(true);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let tempLocations = results.map((result) => {
            const parts = result.Description.split('-');
            let maxAddressCount = 10;
            if (parts.length > 1) {
                const addressCountString = parts[1].trim().split(' ')[0];
                maxAddressCount = parseInt(addressCountString);
            }
    
            return {
                id: result.Id,
                name: result.Text + ", " + result.Description,
                next: result.Next,
                addressCount: maxAddressCount,
            }
        });
        setLocations(tempLocations);
        setIsLoading(false);
        if (results.length > 0){
            setOpenDropdown(true);
        }
    }, [results]);

    return (
            <DropDownPicker 
                style={{
                    ...props.styles,
                    minHeight: 40,
                }}
                searchable={true}
                disableLocalSearch={true}
                placeholder="Search for an address"
                searchPlaceholder="123 Main Drive ..."
                listMode={isMobile ?  "MODAL": "FLATLIST"}
                onChangeSearchText={(query) => handleChange(query)}
                open={openDropdown}
                setOpen={setOpenDropdown}
                value={selecedLocation}
                setValue={setSelecedLocation}
                onSelectItem={(event) => handleSelect(event)}
                schema={{
                    value: 'id',
                    label: 'name'
                }}
                // @ts-ignore
                items={locations}
            />
    );
}

export default CanadaPostAutoCompleteAddress;