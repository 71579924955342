import { StyleSheet } from 'react-native';
import Colors from './Colors';
import FontFamilys from './FontFamilys';

const styles = StyleSheet.create({
    mobile: {
        minHeight: 40
    },
    desktop: {
        minHeight: 40
    }
});

export default styles;