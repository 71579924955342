import { View, Text, TextInput, ScrollView, NativeSyntheticEvent, TextInputChangeEventData, SafeAreaView } from 'react-native';
import { RootStackScreenProps } from '../types';
import { MyInformation } from '../components/MyInformation';
import Sizes from '../constants/Sizes';
import Heights from '../constants/Heights';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import TextStyles from '../constants/TextStyles';
import FontFamilys from '../constants/FontFamilys';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppFooter } from '../components/AppFooter';
import { AppLabel } from '../components/AppLabel';
import { AppGap } from '../components/AppGap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';
import { useEffect, useState } from 'react';
import { PartnerInfo } from '../types/models/PartnerInfo';
import useIsMobile from '../hooks/useIsMobile';
import { FontAwesome } from '@expo/vector-icons'; 
import PageLayoutStyles from '../constants/PageLayoutStyles';

export default function AccountManagerInfoScreen({navigation}: RootStackScreenProps<'AccountManagerInfo'>) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const [languagesString, setLanguagesString] = useState('English');
  const languagesList = [user.partnerInfo.Language1, user.partnerInfo.Language2, user.partnerInfo.Language3, user.partnerInfo.Language4];
  const marginHorizontal = useHorizontalContainerMargin();
  const labelWidth = 140;
  const textWidth = 'auto';
  const isCanada = user.country === CountryType.Canada
  const countryColor = isCanada ? Colors.blue : Colors.green;

  useEffect(() => {
    const createLanguagesString = () => {
      if (languagesList.some(lang => lang !== null)) {
        // let langString = languagesList.map(lang => lang !== null ? `${lang}` : null).join(", ");
        let langString = '';
        languagesList.forEach((lang) => {
          if (lang !== null) {
            langString = langString + lang + ', '
          }
        });
        langString = langString.slice(0,-2);
        setLanguagesString(langString);
      }
    }
    createLanguagesString();
  }, [user.partnerInfo]);
  
  return (
    <>
      {!isMobile &&
        <>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop, backgroundColor: Colors.lightGrey }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large }}>
                  <MyInformation />
                  <AppGap size={Sizes.large} direction={'vertical'}/>
                  <AppLabel color={countryColor} text='My Account Manager'></AppLabel>
                  <AppGap size={Sizes.large} direction={'vertical'}/>
                  <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
                    <View style={{ paddingBottom: Sizes.medium, flexDirection: 'row', width: 240, alignItems: 'center' }}>
                      <FontAwesome name="user" size={12} color="black" />
                      <Text style={{ ...TextStyles.paragraph, paddingLeft: 10, fontFamily: FontFamilys.bold, color: Colors.black }}>{user.partnerInfo.MarketerName}</Text> 
                    </View>    
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: 240 }}>
                      <View style={{ width: labelWidth }}>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.black }}>Phone Number</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.black }}>Email</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.black }}>Location</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.black }}>Languages</Text>
                      </View>
                      <View style={{ width: textWidth }}>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{user.partnerInfo.MarketerPhoneNumber}</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{user.partnerInfo.MarketerEmail}</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{user.partnerInfo.Street}, {user.partnerInfo.Province}, {user.partnerInfo.Country}</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.black }}>{languagesString}</Text>
                      </View>
                    </View>
                  </View>
                  <Text style={{ ...TextStyles.semiSubtitle, paddingVertical: Sizes.medium }}>Partner Support Channel</Text>
                  <View style={{ ...CommonStyles.card, backgroundColor: Colors.blue }}>
                    <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Phone number</Text>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>+1 844-906-0505</Text>
                    <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Operation time</Text>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Mon-Fri 6:00am-6:00pm PST</Text>
                  </View>
                  <AppGap size={100} direction={'vertical'} />
                </View>
              </View>
            </View>
          </ScrollView>
          <AppFooter/>
        </>
      }
      {isMobile &&
        <ScrollView style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingBottom: Sizes.extraLarge, paddingTop: Sizes.extraLarge }}>My Account Manager</Text>
          </View>
          <View style={{ borderRadius: 10, backgroundColor: Colors.white }}>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Name</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.MarketerName}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Email</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.MarketerEmail}</Text>
              </View>
            </View>
            <View>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Languages</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{languagesString}</Text>
              </View>
            </View>
          </View>
          <Text style={{ ...TextStyles.semiSubtitle, paddingVertical: Sizes.medium, color: Colors.white, marginTop: Sizes.large }}>Partner Support Channel</Text>
          <View>
            <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.blue }}>Phone number</Text>
            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>+1 844-906-0505</Text>
          </View>
          <View>
            <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.blue }}>Email</Text>
            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>partnersupport@gophonebox.com</Text>
          </View>
          <View>
            <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.blue }}>Operation time</Text>
            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Mon-Fri 6:00am-6:00pm PST</Text>
          </View>
          <AppGap size={100} direction={'vertical'} />
        </ScrollView>
      }
    </>
  )
}