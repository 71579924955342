import { StyleSheet } from 'react-native';
import Colors from './Colors';
import FontFamilys from './FontFamilys';

const styles = StyleSheet.create({
    title: {
        fontSize: 40,
        fontFamily: FontFamilys.bold
    },
    mobileTitle: {
        fontSize: 36,
        fontFamily: FontFamilys.bold
    },
    navLink: {
        fontSize: 19,
        fontFamily: FontFamilys.regular
    },
    heading: {
        fontSize: 19,
        fontFamily: FontFamilys.semiBold
    },
    subtitle: {
        fontSize: 17,
        fontFamily: FontFamilys.bold
    },
    semiSubtitle: {
        fontSize: 14,
        fontFamily: FontFamilys.semiBold,
        letterSpacing: 0.28
    },
    paragraph: {
        fontSize: 12,
        fontFamily: FontFamilys.regular
    },
    small: {
        color: Colors.grey,
        fontSize: 8,
        fontFamily: FontFamilys.regular,
    }
});

export default styles;