import { StyleSheet, Text, View } from "react-native";
import Colors from "../constants/Colors";
import FontFamilys from "../constants/FontFamilys";
import FontSizes from "../constants/FontSizes";

type AppLabelProps = {
  color?: string,
  text: string,
  textSize?: number
}

export function AppLabel(props: AppLabelProps) {
  const color = props.color ? props.color : Colors.blue;
  const styles = StyleSheet.create({
    label: {
      height: 35,
      backgroundColor: color,
      borderRadius: 10,
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      padding: 10,
      paddingHorizontal: 20,
      flexBasis: 'auto',
      alignSelf: 'flex-start'
    },
    labelText: {
      color: Colors.white,
      fontSize: props.textSize ? props.textSize : 14,
      fontFamily: FontFamilys.bold
    }
  });
  return (
    <View style={{ ...styles.label }}>
      <Text style={styles.labelText}>{props.text}</Text>
    </View>
  )
}