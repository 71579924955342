import { instance } from "../api/axios";
import { BoxOneHeader } from "../types/headers/BoxOneHeader";
import { ApiResponse } from "../types/responses/ApiResponse";
import { ProfileRequest } from "../types/requests/ProfileRequest";
import { ProfileResponse } from "../types/responses/ProfileResponse";
import { GetActivationsCanadaRequest } from "../types/requests/GetActivationsCanadaRequest";
import { GetActivationsCanadaResponse } from "../types/responses/GetActivationsCanadaResponse";
import { GetCommissionReportCanadaRequest } from "../types/requests/GetCommissionReportCanadaRequest";
import { GetCommissionReportCanadaResponse } from "../types/responses/GetCommissionReportCanadaResponse";
import { GetSimCardsRequest } from "../types/requests/GetSimCardsRequest";
import { GetSimCardsResponse } from "../types/responses/GetSimCardsResponse";
import { UpdateProfileRequest } from "../types/requests/UpdateProfileRequest";
import { UpdateProfileResponse } from "../types/responses/UpdateProfileResponse";
import { ChangePasswordRequest } from "../types/requests/ChangePasswordRequest";
import { ChangePasswordResponse } from "../types/responses/ChangePasswordResponse";
import { GetSummaryNumbersRequest } from "../types/requests/GetSummaryNumbersRequest";
import { GetSummaryNumbersResponse } from "../types/responses/GetSummaryNumbersResponse";
import { RequestTempPasswordRequest } from "../types/requests/RequestTempPasswordRequest";
import { RequestTempPasswordResponse } from "../types/responses/RequestTempPasswordResponse";
import { GetInvoiceReportByPartnerIdRequest } from "../types/requests/GetInvoiceReportByPartnerIdRequest";
import { GetInvoiceReportByPartnerIdResponse } from "../types/responses/GetInvoiceReportByPartnerIdResponse";
import { GetDetailedInvoiceReportRequest } from "../types/requests/GetDetailedInvoiceReportRequest";
import { GetDetailedInvoiceReportResponse } from "../types/responses/GetDetailedInvoiceReportResponse";
import { GetScheduledActivationCanadaRequest } from "../types/requests/GetScheduledActivationCanadaRequest";
import { GetScheduledActivationCanadaResponse } from "../types/responses/GetScheduledActivationCanadaResponse";
import { GetShippingBasicInfoRequest } from "../types/requests/GetShippingBasicInfoRequest";
import { GetShippingBasicInfoResponse } from "../types/responses/GetShippingBasicInfoResponse";
import { InsertBulkShippingInfoRequest } from "../types/requests/InsertBulkShippingInfoRequest";
import { InsertBulkShippingInfoResponse } from "../types/responses/InsertBulkShippingInfoResponse";
import { GetAppLaunchMessageRequest } from "../types/requests/GetAppLaunchMessageRequest";
import { GetAppLaunchMessageResponse } from "../types/responses/GetAppLaunchMessageResponse";
import { MuteAppLaunchMessageRequest } from "../types/requests/MuteAppLaunchMessageRequest";
import { MuteAppLaunchMessageResponse } from "../types/responses/MuteAppLaunchMessageResponse";

export const partnerService = {
  changePassword: async (request: ChangePasswordRequest, headers: BoxOneHeader): Promise<ApiResponse<ChangePasswordResponse>> => await instance.post<ChangePasswordRequest, ApiResponse<ChangePasswordResponse>>('/Partner/ChangePassword', request, headers),
  getProfile: async (request: ProfileRequest): Promise<ApiResponse<ProfileResponse>> => await instance.get<ProfileRequest, ApiResponse<ProfileResponse>>('/Partner/Profile', request),
  getActivationsCanada: async (request: GetActivationsCanadaRequest): Promise<ApiResponse<GetActivationsCanadaResponse>> => await instance.get<GetActivationsCanadaRequest, ApiResponse<GetActivationsCanadaResponse>>('/Partner/Activations', request),
  getCommissionReportCanada: async (request: GetCommissionReportCanadaRequest): Promise<ApiResponse<GetCommissionReportCanadaResponse>> => await instance.get<GetCommissionReportCanadaRequest, ApiResponse<GetCommissionReportCanadaResponse>>('/Partner/PartnerCommission', request),
  getSimCards: async (request: GetSimCardsRequest): Promise<GetSimCardsResponse> => await instance.get<GetSimCardsRequest, GetSimCardsResponse>('/Partner/SIMCards', request),
  requestTempPassword: async (headers: BoxOneHeader, params: string): Promise<ApiResponse<RequestTempPasswordResponse>> => await instance.post<RequestTempPasswordRequest, ApiResponse<RequestTempPasswordResponse>>('/Partner/RequestTempPassword?' + params, {}, headers),
  summaryNumbers: async (headers: BoxOneHeader): Promise<ApiResponse<GetSummaryNumbersResponse>> => await instance.get<GetSummaryNumbersRequest, ApiResponse<GetSummaryNumbersResponse>>('/Partner/SummaryNumbers', headers),
  updateProfile: async (headers: BoxOneHeader, params: string): Promise<ApiResponse<UpdateProfileResponse>> => await instance.post<UpdateProfileRequest, ApiResponse<UpdateProfileResponse>>('/Partner/UpdateProfile?' + params, {}, headers),
  getInvoiceReportByPartnerId: async (request: GetInvoiceReportByPartnerIdRequest): Promise<ApiResponse<GetInvoiceReportByPartnerIdResponse>> => await instance.get<GetInvoiceReportByPartnerIdRequest, ApiResponse<GetInvoiceReportByPartnerIdResponse>>('/Partner/GetInvoiceReportByPartnerId', request),
  getDetailedInvoiceReport: async (request: GetDetailedInvoiceReportRequest): Promise<ApiResponse<GetDetailedInvoiceReportResponse>> => await instance.get<GetDetailedInvoiceReportRequest, ApiResponse<GetDetailedInvoiceReportResponse>>('/Partner/GetDetailedInvoiceReport', request),
  getScheduledActivationCanada: async (request: GetScheduledActivationCanadaRequest): Promise<ApiResponse<GetScheduledActivationCanadaResponse>> => await instance.get<GetScheduledActivationCanadaRequest, ApiResponse<GetScheduledActivationCanadaResponse>>('/Partner/ScheduledActivations', request),
  getShippingBasicInfo: async (request: GetShippingBasicInfoRequest, headers: BoxOneHeader): Promise<ApiResponse<GetShippingBasicInfoResponse>> => await instance.get<GetShippingBasicInfoRequest, ApiResponse<GetShippingBasicInfoResponse>>('/Partner/GetShippingBasicInfo', { ...headers, params: request }),
  insertBulkShippingInfo: async (request: InsertBulkShippingInfoRequest, headers: BoxOneHeader): Promise<ApiResponse<InsertBulkShippingInfoResponse>> => await instance.post<InsertBulkShippingInfoRequest, ApiResponse<InsertBulkShippingInfoResponse>>('/Partner/InsertBulkShippingInfo', request, headers),
  getAppLaunchMessage: async (headers: BoxOneHeader): Promise<ApiResponse<GetAppLaunchMessageResponse>> => await instance.get<GetAppLaunchMessageRequest, ApiResponse<GetAppLaunchMessageResponse>>('/Partner/AppLaunchMessage', headers),
  muteAppLaunchMessage: async (headers: BoxOneHeader): Promise<ApiResponse<MuteAppLaunchMessageResponse>> => await instance.post<MuteAppLaunchMessageRequest, ApiResponse<MuteAppLaunchMessageResponse>>('/Partner/MuteAppLaunchMessage', {}, headers),
}