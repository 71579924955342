import { Pressable, Platform, View, Text, Image, Linking, useWindowDimensions } from 'react-native';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';
import Sizes from '../constants/Sizes';
import { AppVerticalBar } from './AppVerticalBar';
import FontFamilys from '../constants/FontFamilys';
import { FontAwesome } from '@expo/vector-icons';
import { AppGap } from './AppGap';
import Heights from '../constants/Heights';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import * as WebBrowser from 'expo-web-browser';

type LinkInput = "android" | "ios";

export function AppFooter() {
    const marginHorizontal = useHorizontalContainerMargin();
    const dimensions = useWindowDimensions();

    const shouldRenderVertical = dimensions.width <= 1100;

    const handleClickLink = (link: LinkInput) => {
      let URL;
      if (link === "android") {
        URL = "https://play.google.com/store/apps/details?id=com.phoneboxexpo.partnerportal2023";
      } else if (link === "ios") {
        URL = "https://apps.apple.com/us/app/phonebox-partner/id6472232403";
      } else {
        return;
      }

      if(Platform.OS === 'web'){
        window.open(URL);
      } else {
        WebBrowser.openBrowserAsync(URL);
      }
    }

    return (
        <View style={{ height: shouldRenderVertical ? Heights.verticalFooter : Heights.footer, backgroundColor: Colors.blue, padding: Sizes.large, position: 'absolute', bottom: 0, width: '100%', flex: 1, flexDirection: shouldRenderVertical ? "column" : "row", justifyContent: "space-between" }}>
            <View style={{ marginLeft: marginHorizontal }}>
                <Image source={require('../assets/images/logo_white.png')} style={{ width: 100, height: 17 }} />
                <AppGap direction="vertical" size={15}></AppGap>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontFamily: FontFamilys.bold }}>Social Media</Text>
                    <AppGap size={Sizes.medium}></AppGap>
                    <AppVerticalBar size={12} color={Colors.white}></AppVerticalBar>
                    <AppGap size={Sizes.medium}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://www.facebook.com/gophonebox')}>
                        <View style={{ flexDirection: 'row' }}>
                            <FontAwesome name="facebook-official" size={15} color={Colors.white}></FontAwesome>
                            <AppGap size={Sizes.medium}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Facebook</Text>
                        </View>
                    </Pressable>
                    <AppGap size={Sizes.medium}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://twitter.com/gophonebox')}>
                        <View style={{ flexDirection: 'row' }}>
                            <FontAwesome name="twitter-square" size={15} color={Colors.white}></FontAwesome>
                            <AppGap size={Sizes.medium}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Twitter</Text>
                        </View>
                    </Pressable>
                    <AppGap size={Sizes.medium}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://www.instagram.com/gophonebox')}>
                        <View style={{ flexDirection: 'row' }}>
                            <FontAwesome name="instagram" size={15} color={Colors.white}></FontAwesome>
                            <AppGap size={Sizes.medium}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Instagram</Text>
                        </View>
                    </Pressable>
                    <AppGap size={30}></AppGap>
                    <Pressable onPress={() => Linking.openURL('https://blog.gophonebox.com')}>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontFamily: FontFamilys.bold }}>Blog</Text>
                            <AppGap size={Sizes.medium}></AppGap>
                            <AppVerticalBar size={12} color={Colors.white}></AppVerticalBar>
                            <AppGap size={Sizes.medium}></AppGap>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>All PhoneBox activities in one site</Text>
                        </View>
                    </Pressable>
                </View>
                <AppGap direction="vertical" size={Sizes.medium}></AppGap>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <FontAwesome name="copyright" size={Sizes.medium} color={Colors.white}></FontAwesome>
                    <AppGap size={Sizes.small}></AppGap>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>{new Date().getFullYear()} PhoneBox. All Rights Reserved.</Text>
                </View>
            </View>
            <View 
              style={{ 
                marginRight: marginHorizontal, 
                marginLeft: shouldRenderVertical ? marginHorizontal : 0, 
                marginTop: shouldRenderVertical ? Sizes.medium : 0
              }}>
                  <View style={{
                    flex: 1,
                    flexDirection: shouldRenderVertical ? "row" : "column",
                    paddingLeft: Sizes.medium
                  }}>
                      <View>
                          <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>Download our Partner App!</Text>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Manage your clients with ease.</Text>
                      </View>
                      <View style={{ flex: 1, flexDirection: "row" }}>
                          <Pressable 
                            style={{ marginTop: Sizes.small, marginLeft: shouldRenderVertical ? Sizes.large : 0 }}
                            onPress={() => handleClickLink("ios")}
                          >
                            <Image 
                              source={require('../assets/images/app-store.png')}
                              style={{ height: 40, width: 140 }}
                            />
                          </Pressable>
                          <Pressable 
                            style={{ marginTop: Sizes.small, marginLeft: Sizes.small }}
                            onPress={() => handleClickLink("android")}
                          >
                              <Image 
                                source={require('../assets/images/google-play-logo.png')}
                                style={{ height: 40, width: 140 }}
                              />
                        </Pressable>
                      </View>
                  </View>
            </View>
        </View>
    );
}