import CountrySelectComponent from '../components/CountrySelectComponent'
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export default function CountrySelectionScreen() {
    const user = useSelector((state: RootState) => state.user);
    const canadaOnly = ((user.userInfo.scCanada || user.userInfo.scUSALyca) && !user.userInfo.scUSAPlintron);
    const usaOnly = (!user.userInfo.scCanada && !user.userInfo.scUSALyca && user.userInfo.scUSAPlintron);
    const noCountry = !user.userInfo.scCanada && !user.userInfo.scUSALyca && !user.userInfo.scUSAPlintron;

  return (
    <CountrySelectComponent canadaOnly={canadaOnly} usaOnly={usaOnly} noCountry={noCountry}/>
  )
}
