/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.createURL('/')],
  config: {
    screens: {
      Root: {
        path: 'dashboard',
        screens: {
          Home: {
            screens: {
              HomeScreen: 'home',
            },
          },
          ClientReport: 'client-report',
          CommissionReport: 'commission-report',
          OrderSimCards: 'order-sim-cards',
          ScheduledActivationReport: 'scheduled-activation-report',
          Info: 'info',
          SupportTicket: 'support-ticket',
        },
      },
      ChangePassword: 'change-password',
      InvoiceReport: 'invoice-report',
      Landing: '',
      News: 'news',
      Info: 'info',
      Videos: 'videos',
      Inventory: 'inventory',
      Promotions: 'promotions',
      Trending: 'trending',
      AccountManagerInfo: 'account-manager-info',
      Activation: 'activation',
      NotFound: '*'
    },
  },
};

export default linking;
