import { FontAwesome } from '@expo/vector-icons';
import { Text, View } from 'react-native';
import Colors from '../constants/Colors';
import FontFamilys from '../constants/FontFamilys';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';

type AppAlertProps = {
  text: string;
}

export function AppAlert(props: AppAlertProps) {
  const isMobile = useIsMobile();
  return (
    <>
      <View style={{ borderRadius: 10, borderColor: isMobile ? Colors.white : Colors.red, borderWidth: isMobile ? 0 : 1, flexDirection: 'row', alignItems: 'center', padding: 10, backgroundColor: Colors.white }}>
        <FontAwesome name="exclamation-triangle" color={Colors.red} size={isMobile ? 20 : 30}></FontAwesome>
        <Text style={{ ...TextStyles.paragraph, color: Colors.black, fontFamily: FontFamilys.bold, marginLeft: 10 }}>{props.text}</Text>
      </View>
    </>
  );
}
