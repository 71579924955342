import { View, Text, TextInput, ScrollView, Pressable } from "react-native"
import useIsMobile from "../hooks/useIsMobile"
import { DataTable } from "react-native-paper";
import * as Clipboard from "expo-clipboard";
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import Colors from "../constants/Colors";
import { Picker } from "@react-native-picker/picker";
import CommonStyles from "../constants/CommonStyles";
import Sizes from "../constants/Sizes";
import { useEffect, useState } from "react";
import { partnerService } from "../services/PartnerService";
import { useDispatch } from "react-redux";
import { setCanadaScheduledActivationReport, setCanadaClients, setUSAScheduledClients, setUSAScheduledActivationReport } from "../store/clients";
import { ScheduledActivationReportEntry } from "../types/models/ScheduledActivationReportEntry";
import TextStyles from "../constants/TextStyles";
import { Table, TD, TBody, TR, P } from '@expo/html-elements';
import { CountryType } from "../types/enums/CountryType";
import FontFamilys from "../constants/FontFamilys";
import { representativeService } from "../services/RepresentativeService";
import { AppGap } from "./AppGap";
import { AppModal } from "./AppModal";
import DropDown from "./DropDown";
import { setShowCopiedAbsolute } from "../store/user";

export default function ScheduledActivationReport() {
	const isMobile = useIsMobile();
	const user = useSelector((state: RootState) => state.user);
	const clients = useSelector((state: RootState) => state.clients);
	const horizontalContainerMargin = useHorizontalContainerMargin();
	const isCanada = user.country === CountryType.Canada;
	const countryColor = isCanada ? Colors.blue : Colors.green;
	const lightCountryColor = isCanada ? Colors.lightBlue : Colors.lightGreen;
	const dispatch = useDispatch();
	const ScheduledActivationReport = isCanada ? clients.canadaScheduledActivationReport : clients.usaScheduledActivationReport;
	const [selectedSearchOption, setSelectedSearchOption] = useState<keyof ScheduledActivationReportEntry>('Name');
	const [searchTerms, setSearchTerms] = useState('');
	const [pageModalVisible, setPageModalVisible] = useState(false);
	// TODO: remove condition once USA API is updated
	const tableHead = isCanada ? [
		{ title: 'Scheduled Date', MobWidth: 130, width: '10%' },
		{ title: 'Name', MobWidth: 150, width: '15%' },
		{ title: 'Email', MobWidth: 300, width: '20%' },
		{ title: 'Plan Price', MobWidth: 100, width: '10%' },
		{ title: 'Plan Cap', MobWidth: 100, width: '10%' },
		{ title: 'SIM Number', MobWidth: 200, width: '15%' },
		{ title: 'SIM Type', MobWidth: 100, width: '10%' },
		{ title: 'Plan Type', MobWidth: 100, width: '10%' }
	] : [
		{ title: 'Scheduled Date', MobWidth: 130, width: '10%' },
		{ title: 'Name', MobWidth: 150, width: '15%' },
		{ title: 'Email', MobWidth: 300, width: '20%' },
		{ title: 'Plan Price', MobWidth: 100, width: '10%' },
		{ title: 'Plan Cap', MobWidth: 100, width: '15%' },
		{ title: 'SIM Type', MobWidth: 100, width: '15%' },
		{ title: 'Plan Type', MobWidth: 100, width: '15%' }
	]

	const tableHeadMobWidth = tableHead.reduce((acc, curr) => { return acc + curr.MobWidth }, 0);

	const searchOptions: Array<keyof ScheduledActivationReportEntry> = isCanada ? [
		'ActivationDate',
		'Name',
		'Email',
		'PlanPrice',
		'PlanCapacity',
		'simNum',
		'isESim',
		'PlanType'
	] : [
		'ActivationDate',
		'Name',
		'Email',
		'PlanPrice',
		'PlanCapacity',
		'isESim',
		'PlanType'
	]

	const searchOptionsItems = isCanada ? [
		{ label: 'Activation Date', value: 'ActivationDate' },
		{ label: 'Name', value: 'Name' },
		{ label: 'Email', value: 'Email' },
		{ label: 'Plan Price', value: 'PlanPrice' },
		{ label: 'Plan Capacity', value: 'PlanCapacity' },
		{ label: 'SIM Number', value: 'simNum' },
		{ label: 'SIM Type', value: 'isESim' },
		{ label: 'Plan Type', value: 'PlanType' }
	] : [
		{ label: 'Activation Date', value: 'ActivationDate' },
		{ label: 'Name', value: 'Name' },
		{ label: 'Email', value: 'Email' },
		{ label: 'Plan Price', value: 'PlanPrice' },
		{ label: 'Plan Capacity', value: 'PlanCapacity' },
		{ label: 'SIM Type', value: 'isESim' },
		{ label: 'Plan Type', value: 'PlanType' }
	]

	const searchConditions = (entry: ScheduledActivationReportEntry) => {
		if (entry[selectedSearchOption]?.toLowerCase().includes(searchTerms.toLowerCase())) {
			return entry
		}
	}

	const [page, setPage] = useState(0);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const paginationLabel = `${(page * itemsPerPage) + 1} - ${(itemsPerPage * (page + 1))} of ${ScheduledActivationReport.filter(searchConditions).length}`;
	const numberOfPages = (Math.floor(ScheduledActivationReport.length / itemsPerPage) + 1);
	const reportLength = ScheduledActivationReport.filter(searchConditions).length;
	const pageOptions = [
		5,
		10,
		20,
		reportLength
	];

	const perPageItems = pageOptions.map((page) => (
		{ label: `${page}/page`, value: page }
	));

	useEffect(() => {
		const getScheduledActivationReportCanada = async () => {
			const response = await partnerService.getScheduledActivationCanada({
				headers: {
					aaid: user.userInfo.aaid,
					token: user.userInfo.token
				}
			})

			if (response && response.data) {
				let responseData = JSON.parse(response.data.toString());
				dispatch(setCanadaClients(responseData));
				dispatch(setCanadaScheduledActivationReport());
			}
		}

		const getScheduledActivationReportUS = async () => {
			const response = await representativeService.getPartnerScheduledActivationsReportUS({
				data: {
					i_rep: user.userInfo.i_rep
				}
			})

			if (response && response.data && response.data.data) {
				dispatch(setUSAScheduledClients(response.data.data.listCustomers));
				dispatch(setUSAScheduledActivationReport());
			}
		}

		if (isCanada) {
			getScheduledActivationReportCanada().catch(err => { console.error(err) });
		} else {
			getScheduledActivationReportUS().catch(err => { console.error(err) });
		}
	}, [])

	const copyToClipboard = async (textToCopy: string) => {
		await Clipboard.setStringAsync(textToCopy);

		dispatch(setShowCopiedAbsolute(true));
		setTimeout(() => {
			dispatch(setShowCopiedAbsolute(false));
		}, 1800);
	}

	const MobileScheduledActivationReportEntryTD = (props: { width: number, data: string }) => {
		return (
			<TD style={{ minWidth: props.width, maxWidth: props.width }}>
				<Pressable style={{ paddingTop: 16, paddingBottom: 10 }} onPress={() => copyToClipboard(props.data)}>
					<Text numberOfLines={1} style={{ ...TextStyles.paragraph, minWidth: props.width, maxWidth: props.width, textAlign: 'center' }}>
						{props.data}
					</Text>
				</Pressable>
			</TD>
		)
	}


	return (
		<>
			{!isMobile &&
				<View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 10 }}>
					<View style={{ flex: 1, flexDirection: 50 >= horizontalContainerMargin ? 'column' : 'row', justifyContent: 'space-between' }}>
						<View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
							<Text>Search by:</Text>
							<Picker style={{ ...CommonStyles.input, marginHorizontal: Sizes.small }} selectedValue={selectedSearchOption} onValueChange={(itemValue: keyof ScheduledActivationReportEntry) => setSelectedSearchOption(itemValue)}>
								{searchOptions.map((key, index) => {
									return (<Picker.Item key={key} label={tableHead[index].title} value={key} />)
								})}
							</Picker>
							<TextInput style={{ ...CommonStyles.input }} onChangeText={setSearchTerms} value={searchTerms}></TextInput>
						</View>
					</View>
					<DataTable style={{ paddingTop: Sizes.small }}>
						<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
							<View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
								<Text style={{ paddingRight: Sizes.medium }}>Total: {ScheduledActivationReport.filter(searchConditions).length}</Text>
								<Picker style={{ width: 90, height: 30 }} selectedValue={itemsPerPage} onValueChange={(itemValue: number) => setItemsPerPage(itemValue)}>
									{pageOptions.map((page) => {
										return (<Picker.Item key={page} label={`${page}/page`} value={page} />)
									})}
								</Picker>
							</View>
							<DataTable.Pagination
								page={page}
								numberOfPages={numberOfPages}
								onPageChange={(page) => setPage(page)}
								label={paginationLabel}
							/>
						</View>
						<View style={{ marginBottom: Sizes.medium, flexDirection: 'row', justifyContent: 'flex-end' }}>
							<Text style={{ ...TextStyles.paragraph }}>P = Physical SIM</Text>
							<Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>E = eSIM</Text>
							<Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>CP = SimCP</Text>
							<Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>OT = One Time</Text>
						</View>
						{ScheduledActivationReport.length === 0 ?
							<Text style={{ padding: 20, width: '100%', ...TextStyles.paragraph, textAlign: 'center', color: countryColor, backgroundColor: Colors.lightBlue }}>No results found</Text>
							:
							<DataTable.Header style={{ backgroundColor: lightCountryColor }}>
								{tableHead.map(head => {
									return (<DataTable.Title key={head.title} style={{ minWidth: head.width }} textStyle={{ color: countryColor, fontFamily: FontFamilys.bold, fontSize: 12, textAlign: "center", width: "100%" }}>{head.title}</DataTable.Title>)
								})}
							</DataTable.Header>
						}
						{ScheduledActivationReport.filter(searchConditions).sort((a, b) => { return (new Date(a.ActivationDate).getTime() - new Date(b.ActivationDate).getTime()) }).slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((entry, index) => {
							// if no SIM num, distribute entry.simNum col width % to other cols
							let calcWidth = isCanada ? "10%" : "15%";
							return (
								<Table style={{ borderBottomWidth: 1, borderColor: Colors.lightGrey, paddingHorizontal: 10 }} key={index}>
									<TBody>
										<TR style={{ maxWidth: "100%" }}>
											<TD style={{ paddingVertical: 10, width: "10%", textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "10%", textAlign: "center" }}>{entry.ActivationDate}</Text></TD>
											<TD style={{ paddingVertical: 10, width: "15%", textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "15%", textAlign: "center" }}>{entry.Name}</Text></TD>
											<TD style={{ paddingVertical: 10, width: "20%", textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "20%", textAlign: "center" }}>{entry.Email}</Text></TD>
											<TD style={{ paddingVertical: 10, width: "10%", textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "10%", textAlign: "center" }}>{entry.PlanPrice}</Text></TD>
											<TD style={{ paddingVertical: 10, width: calcWidth, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "10%", textAlign: "center" }}>{entry.PlanCapacity}</Text></TD>
											{isCanada &&  // will not appear in USA version
												<TD style={{ paddingVertical: 10, width: "15%", textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "15%", textAlign: "center" }}>{entry.simNum}</Text></TD>
											}
											<TD style={{ paddingVertical: 10, width: calcWidth, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "10%", textAlign: "center" }}>{entry.isESim}</Text></TD>
											<TD style={{ paddingVertical: 10, width: calcWidth, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, maxWidth: "10%", textAlign: "center" }}>{entry.PlanType}</Text></TD>
										</TR>
									</TBody>
								</Table>
							)
						})}
					</DataTable>
				</View>
			}
			{isMobile &&
				<>
					<View style={{ flex: 1, zIndex: 1000, marginHorizontal: horizontalContainerMargin }}>
						<Text style={{ fontFamily: FontFamilys.regular, fontSize: 12, color: Colors.white, marginBottom: 5 }}>Search By:</Text>
						<View style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
							<View style={{ width: '49%', marginRight: '2%' }}>
								<DropDown value={selectedSearchOption} items={searchOptionsItems} setValue={setSelectedSearchOption} />
							</View>
							<TextInput style={{ ...CommonStyles.input, flex: 1, borderColor: Colors.white, color: Colors.white, width: '49%' }} onChangeText={setSearchTerms} value={searchTerms}></TextInput>
						</View>
					</View>
					<View style={{ backgroundColor: Colors.white, marginTop: 40 }}>
						<DataTable style={{ paddingTop: Sizes.large }}>
							<View style={{ paddingLeft: 20, zIndex: 500, marginBottom: Sizes.medium }}>
								<View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
									<Text style={{ ...TextStyles.paragraph }}>Total: {ScheduledActivationReport.filter(searchConditions).length}</Text>
									<View style={{ flexDirection: 'row', paddingRight: 20 }}>
										<Text style={{ ...TextStyles.paragraph }}>P= Physical SIM</Text>
										<Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>E= eSIM</Text>
									</View>
								</View>
								<View style={{ zIndex: 500, flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between', paddingTop: 5 }}>
									<View style={{ width: '33%' }}>
										<DropDown value={itemsPerPage} items={perPageItems} setValue={setItemsPerPage} placeholder={`${itemsPerPage}/page`} minHeight={30} />
									</View>
									<DataTable.Pagination
										page={page}
										numberOfPages={numberOfPages}
										onPageChange={(page) => setPage(page)}
										label={paginationLabel}
										style={{ width: '66%' }}
									/>
								</View>
							</View>
							<ScrollView horizontal={ScheduledActivationReport.length !== 0 ? true : false}>
								<View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 10 }}>
									{ScheduledActivationReport.length === 0 ?
										<Text style={{ padding: 20, width: '100%', ...TextStyles.paragraph, textAlign: 'center', color: countryColor, backgroundColor: Colors.lightBlue }}>No results found</Text>
										:
										<Table style={{ backgroundColor: lightCountryColor, width: tableHeadMobWidth }}>
											<TBody style={{ width: tableHeadMobWidth }}>
												<TR style={{ maxWidth: tableHeadMobWidth }}>
													{tableHead.map(head => {
														return (
															<TD key={head.title} style={{ minWidth: head.MobWidth, maxWidth: head.MobWidth, color: countryColor, paddingVertical: 12, fontSize: 12, fontFamily: FontFamilys.semiBold, textAlign: "center" }}>
																{head.title}
															</TD>
														)
													})}
												</TR>
											</TBody>
										</Table>
									}
									{ScheduledActivationReport.filter(searchConditions).slice(page * itemsPerPage, (page + 1) * itemsPerPage).map((entry, index) => {
										return (
											<Table style={{ borderBottomWidth: 1, borderColor: Colors.lightGrey }} key={index}>
												<TBody>
													<TR style={{ maxWidth: "100%" }}>
														<MobileScheduledActivationReportEntryTD width={130} data={entry.ActivationDate} />
														<MobileScheduledActivationReportEntryTD width={150} data={entry.Name} />
														<MobileScheduledActivationReportEntryTD width={300} data={entry.Email} />
														<MobileScheduledActivationReportEntryTD width={100} data={entry.PlanPrice} />
														<MobileScheduledActivationReportEntryTD width={100} data={entry.PlanCapacity} />
														{isCanada && entry.simNum &&  // will not appear in USA version
															<MobileScheduledActivationReportEntryTD width={200} data={entry.simNum} />
														}
														<MobileScheduledActivationReportEntryTD width={100} data={entry.isESim} />
														<MobileScheduledActivationReportEntryTD width={100} data={entry.PlanType} />
													</TR>
												</TBody>
											</Table>
										)
									})}
								</View>
							</ScrollView>
						</DataTable>
					</View>
					<AppGap size={100} direction={'vertical'}></AppGap>
					<AppModal onClose={() => setPageModalVisible(false)} visible={pageModalVisible} headerTitle='Items Per Page' width={300}>
						<Picker
							itemStyle={{ width: '100%', fontSize: 15, fontFamily: FontFamilys.regular, color: Colors.darkBlue }}
							style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.white, ...CommonStyles.input, marginRight: Sizes.small }}
							selectedValue={itemsPerPage} onValueChange={(itemValue: number) => setItemsPerPage(itemValue)}>
							{pageOptions.map((page) => {
								return (<Picker.Item key={page} label={`${page}/page`} value={page} />)
							})}
						</Picker>
					</AppModal>
				</>
			}
		</>
	)
}
