import { StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';

type AppVerticalBarProps = {
  size?: number;
  color?: string;
}

export function AppVerticalBar(props: AppVerticalBarProps) {
  return (
    <View style={FlexStyles.rowVerticalCenter}>
      <View style={{ height: props.size ? props.size : 30, borderRightColor: props.color ? props.color : Colors.green, borderRightWidth: 2 }}></View>
    </View>
  );
}
