export enum LangIdType {
  English = 1,
  French = 2,
  Portuguese = 3,
  Spanish = 4,
  Chinese = 5,
  Japanese = 6,
  Korean = 7,
  Vietnamese = 8,
  Arabic = 9
}