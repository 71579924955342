import { View, Text, ScrollView } from "react-native";
import { RootStackScreenProps } from "../types";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import Heights from "../constants/Heights";
import Sizes from "../constants/Sizes";
import { MyInformation } from "../components/MyInformation";
import { AppGap } from "../components/AppGap";
import { AppFooter } from "../components/AppFooter";
import TextStyles from "../constants/TextStyles";

export default function PromotionsScreen({navigation}: RootStackScreenProps<'Promotions'>) {
  const marginHorizontal = useHorizontalContainerMargin();
  
  return (
    <>
      <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
          <View style={{ flex: 1 }}>
            <View style={{ marginTop: Sizes.large }}>
              <MyInformation />
              <AppGap direction='vertical' size={100}></AppGap>
              <View style={{ alignItems: 'center' }}>
                <Text style={{ ...TextStyles.subtitle }}>No Special Promotions at the moment.</Text>
                <Text style={{ ...TextStyles.paragraph }}>Please wait for an update!</Text>
              </View>
            </View>
            <AppGap size={100} direction={'vertical'}></AppGap>
          </View>
        </View>
      </ScrollView>
      <AppFooter/>
    </>
  )
}