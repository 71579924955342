import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Client } from '../types/models/Client';
import { Commission } from '../types/models/Commission';
import { ClientReportEntry } from '../types/models/ClientReportEntry';
import { CommissionReportEntry } from '../types/models/CommissionReportEntry';
import { Customer } from '../types/models/Customer';
import { CustomerStatusHistory } from '../types/models/CustomerStatusHistory';
import { Account } from '../types/models/Account';
import { AccountInfo } from '../types/models/AccountInfo';
import { CardList } from '../types/models/CardList';
import { findLatestStatus, formatPhoneNumber, getPlanType, sortByActivationDate, sortByActivationDateCommission } from '../utilities/utilities';
import { Xdr } from '../types/models/Xdr';
import { AccountActivationDatePair } from '../types/models/AccountActivationDatePair';
import { InvoiceReportEntry } from '../types/models/InvoiceReportEntry';
import { ScheduledActivationReportEntry } from '../types/models/ScheduledActivationReportEntry';
import { USAScheduledClient } from '../types/models/USAScheduledClient';

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    canadaActivationsCount: 0,
    canadaClients: [] as Client[],
    canadaClientsReport: [] as ClientReportEntry[],
    canadaCommission: [] as Commission[],
    canadaCommissionReport: [] as CommissionReportEntry[],
    canadaScheduledActivationReport: [] as ScheduledActivationReportEntry[],
    invoiceReport: [] as InvoiceReportEntry[],
    invoiceSummaryStatistics: {
      SuspendedAccountCount: 0,
      TerminatedAccountCount: 0,
      TotalActiveClientCount: 0,
      TotalPaymentAmount: 0
    },
    usaAccountInfoList: [] as AccountInfo[],
    usaAccountList: [] as Account[],
    usaActivationDateList: [] as AccountActivationDatePair[],
    usaActivationsCount: 0,
    usaClientsReport: [] as ClientReportEntry[],
    usaCommissionReport: [] as CommissionReportEntry[],
    usaCustomerList: [] as Customer[],
    usaScheduledClients: [] as USAScheduledClient[],
    usaScheduledActivationReport: [] as ScheduledActivationReportEntry[],
    usaSimCardList: [] as CardList[],
    usaStatusHistoryList: [] as CustomerStatusHistory[],
    xdrList: [] as Xdr[],
  },
  reducers: {
    setCanadaActivationsCount(state, action: PayloadAction<Client[]>) {
      state.canadaActivationsCount = 0;
      action.payload.forEach(client => {
        if (client.ActivationDate !== "" && client.TerminationDate === "") {
          state.canadaActivationsCount++
        }
      })
    },
    setCanadaClients(state, action: PayloadAction<Client[]>) {
      state.canadaClients = action.payload;
    },
    setCanadaClientsReport(state) {
      state.canadaClientsReport = [];
      state.canadaClients.forEach(client => {
        let indices = [2, 0, 1];
        let PlanType = getPlanType(client);
        let SimType = client.isESim === "0" ? "P" : "E";
        state.canadaClientsReport.push({
          ActivationDate: client.ActivationDate,
          Name: client.Name,
          Email: client.Email,
          Phonenumber: client.Phonenumber,
          PlanPrice: client.PlanPrice,
          PlanCapacity: client.PlanCapacity,
          TerminationDate: client.TerminationDate,
          simNum: client.simNum,
          isESim: SimType,
          PlanType: PlanType,
          CNum: client.CNum ? client.CNum : ""
        })
      })
    },
    setCanadaCommission(state, action: PayloadAction<Commission[]>) {
      state.canadaCommission = action.payload;
    },
    setCanadaCommissionReport(state) {
      state.canadaCommissionReport = [];
      state.canadaCommission.forEach(commission => {
        state.canadaCommissionReport.push({
          ClientName: commission.ClientName,
          PhoneNum: formatPhoneNumber(commission.PhoneNum, false, true) || "",
          SimNum: commission.SimNum,
          PlanPrice: commission.PlanPrice,
          PlanType: commission.PlanType,
          CommissionAmount: commission.CommissionAmount,
          CommissionCurrency: commission.CommissionCurrency,
          ActivationDate: commission.ActivationDate,
          SuspensionDate: commission.SuspensionDate,
          SuspensionType: commission.SuspensionType,
          TerminationDate: commission.TerminationDate,
          CNum: commission.CNum
        })
      })
      state.canadaCommissionReport = sortByActivationDateCommission(state.canadaCommissionReport);
    },
    setCanadaScheduledActivationReport(state) {
      state.canadaScheduledActivationReport = [];
      state.canadaClients.forEach(client => {
        let PlanType = getPlanType(client);
        let SimType = client.isESim.toLowerCase() === "true" ? "E" : "P"
        state.canadaScheduledActivationReport.push({
          ActivationDate: client.ActivationDate,
          Name: client.Name,
          Email: client.Email,
          PlanPrice: client.PlanPrice,
          PlanCapacity: client.PlanCapacity,
          simNum: client.simNum,
          isESim: SimType,
          PlanType: PlanType
        })
      })
    },
    setInvoiceReport(state, action: PayloadAction<InvoiceReportEntry[]>) {
      state.invoiceReport = action.payload;
    },
    setInvoiceSummaryStatistics(state, action: PayloadAction<{
      SuspendedAccountCount: number,
      TerminatedAccountCount: number,
      TotalActiveClientCount: number,
      TotalPaymentAmount: number
    }>) {
      state.invoiceSummaryStatistics = action.payload;
    },
    setUSAActivationsCount(state, action: PayloadAction<number>) {
      state.usaActivationsCount = action.payload;
    },
    setUSAActivationDateList(state, action: PayloadAction<AccountActivationDatePair[]>) {
      state.usaActivationDateList = action.payload;
    },
    setUSACustomerList(state, action: PayloadAction<Customer[]>) {
      state.usaCustomerList = action.payload;
    },
    setUSAStatusHistoryList(state, action: PayloadAction<CustomerStatusHistory[]>) {
      state.usaStatusHistoryList = action.payload;
    },
    setUSAAccountList(state, action: PayloadAction<Account[]>) {
      state.usaAccountList = action.payload;
    },
    setUSAAccountInfoList(state, action: PayloadAction<AccountInfo[]>) {
      state.usaAccountInfoList = action.payload;
    },
    setUSASimCardList(state, action: PayloadAction<CardList[]>) {
      state.usaSimCardList = action.payload;
    },
    setXdrList(state, action: PayloadAction<Xdr[]>) {
      state.xdrList = action.payload;
    },
    setUSACommissionReport(state, action: PayloadAction<CommissionReportEntry[]>) {
      state.usaCommissionReport = action.payload;
    },
    setUSAClientsReport(state, action: PayloadAction<ClientReportEntry[]>) {
      state.usaClientsReport = action.payload;
    },
    setUSAScheduledClients(state, action: PayloadAction<USAScheduledClient[]>) {
      state.usaScheduledClients = action.payload;
    },
    setUSAScheduledActivationReport(state) {
      state.usaScheduledActivationReport = [];
      state.usaScheduledClients.forEach(client => {
        let SimType = client.eSIM.toLowerCase() === "yes" ? "E" : "P"
        state.usaScheduledActivationReport.push({
          ActivationDate: client.scheduledActivationDate.slice(0, 10),
          Name: client.customerName,
          Email: client.email,
          PlanPrice: client.planPrice.toString(),
          PlanCapacity: client.planCapacity,
          isESim: SimType,
          PlanType: client.planType
        })
      })
    }
  }
})

export const {
  setCanadaActivationsCount,
  setCanadaClients,
  setCanadaClientsReport,
  setCanadaCommission,
  setCanadaCommissionReport,
  setCanadaScheduledActivationReport,
  setInvoiceReport,
  setInvoiceSummaryStatistics,
  setUSAActivationsCount,
  setUSAActivationDateList,
  setUSACustomerList,
  setUSAStatusHistoryList,
  setUSAAccountList,
  setUSAAccountInfoList,
  setUSASimCardList,
  setUSAClientsReport,
  setUSACommissionReport,
  setUSAScheduledActivationReport,
  setUSAScheduledClients,
  setXdrList
} = clientsSlice.actions;

export default clientsSlice.reducer;