import React, { useEffect } from 'react'
import { useState } from 'react';
import { Text, ScrollView, View, Pressable, useWindowDimensions } from 'react-native';
import { RootStackScreenProps } from '../types';
import useIsMobile from '../hooks/useIsMobile';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import TextStyles from '../constants/TextStyles';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';
import { AppGap } from '../components/AppGap';
import { AppTextInput } from '../components/AppTextInput';
import { AppButton } from '../components/AppButton';
import { partnerService } from '../services/PartnerService';
import { AppContainer } from '../components/AppContainer';
import { AppRow } from '../components/AppRow';
import { AppColumn } from '../components/AppColumn';
import { FontAwesome } from '@expo/vector-icons';
import FontFamilys from '../constants/FontFamilys';
import { AppAlert } from '../components/AppAlert';
import { AppHeader } from '../components/AppHeader';
import CommonStyles from '../constants/CommonStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { MarginType } from '../types/enums/MarginType';
import Heights from '../constants/Heights';

export default function ForgotPasswordScreen({ navigation }: RootStackScreenProps<'ForgotPassword'>) {

    const isMobile = useIsMobile()
		const dimensions = useWindowDimensions();
		const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);
		const [email, setEmail] = useState('');
		const [loginErrorMsg, setLoginErrorMsg] = useState('');
		const [loggingIn, setLoggingIn] = useState(false);
		const [ isDisabled, setIsDisabled ] = useState(true);

		const handleSendTempPassword = () => {
			const requestTempPassword = async () => {
				let params = new URLSearchParams({
					email: email
				}).toString();
				var response = await partnerService.requestTempPassword(
					{ headers: 
						{
							aaid: '1',
							token: '1'
						}
					}, 
					params
				);
				if (!response || !response.data) {
					setLoginErrorMsg('No email found, please verify your email or contact your account executive to set up an email.');
				}
				if (response && response.data) {
					if (response.data === "temp password sent") {
						setLoginErrorMsg('A temporary password has been sent to your email.');
					} else {
						setLoginErrorMsg('Account not found.');
					}
				}
			}
			requestTempPassword().catch(err => setLoginErrorMsg('No email found, please verify your email or contact your account executive to set up an email.'));
		}

  return (
    <>
			{!isMobile && 
			<>
				<AppHeader onlyLogo={true}></AppHeader>
				<ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
					<View style={{ ...PageLayoutStyles.container, marginHorizontal: marginHorizontal }}>
						<View style={{ ...CommonStyles.card, backgroundColor: Colors.white, padding: 0, borderColor: Colors.darkBlue, borderWidth: 2, width: dimensions.width > 1512 ? 840 : 'auto', marginHorizontal: dimensions.width > 1512 ? 'auto' : 0 }}>
							<AppContainer>
								<AppRow>
									<AppColumn>
										<View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
											<Text style={{ ...TextStyles.semiSubtitle, color: Colors.black, fontSize: 24 }}>Temporary Password</Text>
											<View>
												<AppGap direction="vertical" size={Sizes.large}></AppGap>
												<AppTextInput placeholder="Email Address" onChangeText={(text) => setEmail(text.trim())}></AppTextInput>
												<AppGap direction="vertical" size={Sizes.large}></AppGap>
											</View>
											<AppButton text="Send Temp Password" shape="rect" size='sm' textSize={14} onPress={handleSendTempPassword} loading={loggingIn}></AppButton>
											<View style={{ padding: Sizes.large }}>
												<Pressable onPress={() => navigation.navigate("Landing")} style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
													<FontAwesome name="angle-left" size={15} style={{ marginRight: Sizes.small, fontFamily: FontFamilys.bold }}></FontAwesome>
													<Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Back</Text>
												</Pressable>
											</View>
											{loginErrorMsg.length > 0 &&
												<View style={{ marginTop: Sizes.medium }}>
													<AppAlert text={loginErrorMsg}></AppAlert>
												</View>
											}
										</View>
									</AppColumn>
								</AppRow>
							</AppContainer>
						</View>
					</View>
				</ScrollView>
			</>
			}
			{isMobile &&
				<View style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', justifyContent: 'space-between', backgroundColor: Colors.darkBlue }}>
					<View>
						<Text style={{ ...TextStyles.mobileTitle, color: Colors.white }}>Temporary Password</Text>
						<View>
							<AppGap direction="vertical" size={Sizes.large}></AppGap>
							<AppTextInput placeholder="Email Address" onChangeText={(text) => {setEmail(text.trim()); setIsDisabled(true); }}></AppTextInput>
							<AppGap direction="vertical" size={Sizes.large}></AppGap>
						</View>
					</View>
					<View style={{ marginBottom: Sizes.extraLarge }}>
						<AppButton text="Send Temp Password" shape="rect" onPress={handleSendTempPassword} loading={loggingIn} ></AppButton>
						{loginErrorMsg.length > 0 &&
							<View style={{ marginTop: Sizes.medium }}>
								<AppAlert text={loginErrorMsg}></AppAlert>
							</View>
						}
					</View>
				</View>
			}
    </>
  )
}
