import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { RootState } from "../store";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import * as WebBrowser from 'expo-web-browser';
import * as Clipboard from 'expo-clipboard';
import { RootStackScreenProps } from "../types";
import { ScrollView, View, Text, Platform, Animated } from "react-native";
import { AppGap } from "../components/AppGap";
import { AppButton } from "../components/AppButton";
import { LabelledLink } from "../types/models/LabelledLink";
import { CountryType } from "../types/enums/CountryType";
import { PartnerSimType } from "../types/enums/PartnerSimType";
import VendorPartners from "../constants/VendorPartners";
import Colors from "../constants/Colors";
import TextStyles from "../constants/TextStyles";
import Heights from "../constants/Heights";
import FontFamilys from "../constants/FontFamilys";
import Sizes from "../constants/Sizes";
import CommonStyles from "../constants/CommonStyles";
import FadingCopiedText from "../components/FadingCopiedText";
import { getAffiliateLinkLanguageFromLangId } from "../utilities/utilities";

export default function AffiliateLinksScreen({navigation}: RootStackScreenProps<'AffiliateLinks'>) {
  const user = useSelector((state: RootState) => state.user);
  const marginHorizontal = useHorizontalContainerMargin();
  const [activationLinks, setActivationLinks] = useState<Array<LabelledLink>>([]);
  const affiliateLinkLanguageParam = getAffiliateLinkLanguageFromLangId(user.userInfo.LangId);
  const hasBothSimTypeLinks = user.userInfo.PartnerSimTypeDesc === PartnerSimType.eSIMAndPhysical && activationLinks[0] && activationLinks[1];
  const hasOneSimTypeLink = user.userInfo.PartnerSimTypeDesc !== PartnerSimType.eSIMAndPhysical && activationLinks[0];
  const [showCopiedDialogue, setShowCopiedDialogue] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    let possiblyEmptyIRep = user.userInfo.i_rep === null ? '' : user.userInfo.i_rep
    let physicalActivationLink;
    let eSIMLink = `https://esim.gophonebox.com/?bn=${user.userInfo.bid}&i_rep=${possiblyEmptyIRep}`

    // dynamic link setting
    let physicalActivationLinkOrigin = "https://gophonebox.com";

    if (affiliateLinkLanguageParam !== "") {
      physicalActivationLinkOrigin += `/${affiliateLinkLanguageParam}`;
    }

    if (user.country === CountryType.USA) {
      physicalActivationLink = physicalActivationLinkOrigin + `/activation?bn=${user.userInfo.bid}&i_rep=${user.userInfo.i_rep}`;
    } else {
      physicalActivationLink = physicalActivationLinkOrigin + `/activation?bn=${user.userInfo.bid}&i_rep=${possiblyEmptyIRep}`;
    }

    if (VendorPartners.includes(+user.userInfo.bid) || +user.userInfo.bid === 3968) {
      physicalActivationLink = "https://prepaid.gophonebox.com/vendor";
    }

    if (user.userInfo.category?.toLowerCase() === 'wholesale') {
      physicalActivationLink = `https://prepaid.gophonebox.com/simcp?bn=${user.userInfo.bid}`;
    }

    if (user.userInfo.PartnerSimTypeDesc === PartnerSimType.eSIMAndPhysical) {
      setActivationLinks([
        { label: 'SIM cards & eSIM', dest: physicalActivationLink },
        { label: 'eSIM only', dest: eSIMLink }
      ]);
    } else if (user.userInfo.PartnerSimTypeDesc === PartnerSimType.eSIM) {
      setActivationLinks([
        { label: 'eSIM only', dest: eSIMLink }
      ]);
    } else {
      setActivationLinks([
        { label: 'SIM cards & eSIM', dest: physicalActivationLink }
      ])
    }
  }, [user]);

  useEffect(() => {
    if (showCopiedDialogue) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 120,
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 120,
        useNativeDriver: true
      }).start();
    }
  }, [fadeAnim, showCopiedDialogue]);

  const onPressVisit = (link: string) => {
    if (Platform.OS === 'web') {
      window.open(link);
    } else {
      WebBrowser.openBrowserAsync(link);
    }
  }

  const onPressCopy = async (link: string) => {
    await Clipboard.setStringAsync(link);

    setShowCopied(true);
    setTimeout(() => {
      setShowCopied(false);
    }, 1800);
  }

  const AffiliateLink = (props: { link: LabelledLink }) => {
    return (
      <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
        <Text style={{ ...TextStyles.subtitle, color: Colors.black, marginBottom: Sizes.large }}>{props.link.label}</Text>
        <Text style={{ ...TextStyles.semiSubtitle, color: Colors.black, marginBottom: Sizes.large }}>{props.link.dest}</Text>
        <View style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
          <AppButton text="Visit" onPress={() => onPressVisit(props.link.dest)} />
          <AppButton text="Copy" onPress={() => onPressCopy(props.link.dest)} />
        </View>
      </View>
    )
  }

  return (
    <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
      <View style={{ flex: 1, marginHorizontal: marginHorizontal }}>
        <Text style={{ ...TextStyles.title, color: Colors.white }}>Affiliate Links</Text>
        <AppGap direction='vertical' size={Sizes.medium}></AppGap>
        <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16 }}>Visit your affiliate links or copy them to share.</Text>
        <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
        {hasBothSimTypeLinks &&
          <>
            <AffiliateLink link={activationLinks[0]} />
            <AppGap direction='vertical' size={Sizes.large}/>
            <AffiliateLink link={activationLinks[1]} />
          </>
        }
        {hasOneSimTypeLink &&
          <AffiliateLink link={activationLinks[0]} />
        }
        {/* <Animated.View style={{ opacity: fadeAnim }}>
          <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, justifyContent: 'center', alignItems: 'center' }}>
            <View style={{ backgroundColor: Colors.red, borderRadius: Sizes.medium, position: "absolute", bottom: -80 }}>
              <Text style={{ fontSize: FontSizes.medium, fontFamily: FontFamilys.bold, color: Colors.white, textAlign: "center", padding: 10 }}>Copied!</Text>
            </View>
          </View>
        </Animated.View> */}
        <View style={{ marginTop: Sizes.extraLarge }}>
          <FadingCopiedText showCopied={showCopied}/>
        </View>
      </View>
      <AppGap size={100} direction={'vertical'}></AppGap>
    </ScrollView>
  )
}