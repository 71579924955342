import { ScrollView, View, Text, Image, Pressable, Platform } from "react-native";
import { RootStackScreenProps } from "../types";
import { MyInformation } from "../components/MyInformation";
import Sizes from "../constants/Sizes";
import Heights from "../constants/Heights";
import Newsletters from "../constants/Newsletters";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { AppGap } from "../components/AppGap";
import TextStyles from "../constants/TextStyles";
import Colors from "../constants/Colors";
import CommonStyles from "../constants/CommonStyles";
import { CountryType } from "../types/enums/CountryType";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AppFooter } from "../components/AppFooter";
import useIsMobile from "../hooks/useIsMobile";
import FontFamilys from "../constants/FontFamilys";
import { Feather } from '@expo/vector-icons';
import * as WebBrowser from 'expo-web-browser';

export default function NewsScreen({navigation}: RootStackScreenProps<'News'>) {
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const overlayColor = user.country === CountryType.Canada ? 'rgba(4, 148, 202, 0.3)' : 'rgba(133, 190, 110, 0.3)';
  const newslettersWidth = isMobile ? 'auto' : marginHorizontal * 4 > 500 ? marginHorizontal * 4 : 500 ;

  const openLink = (link: string) => {
    if(Platform.OS === 'web') {
      window.open(link);
    } else {
      WebBrowser.openBrowserAsync(link);
    }
  }

  // useEffect(() => {
  //   const getNewsletter = async () => {
  //     const newsResponse = await axios.get("https://phonebox.azurewebsites.net/Newsletters/Notification/Partners_Newsletter_September.html")
      
  //     if (newsResponse) {
  //       console.log(newsResponse);
  //     }
  //   }
  //   getNewsletter().catch(err => console.error(err));
  // }, []);

  return (
    <>
    {!isMobile &&
      <>
        <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
            <View style={{ flex: 1 }}>
              <View style={{ marginTop: Sizes.large, marginBottom: 100 }}>
                <MyInformation />
                <AppGap direction='vertical' size={Sizes.large}></AppGap>
                <View style={{ flex: 1, flexDirection: 'row' }}>
                  <View>
                    <View>
                      <Text style={{ ...TextStyles.subtitle, color: Colors.darkBlue }}>News & Notifications</Text>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>Browse Past PhoneBox Monthly Newsletters Below</Text>
                    </View>
                    <AppGap direction='vertical' size={Sizes.large}></AppGap>
                    <View style={{ paddingVertical: Sizes.large }}>
                      <Text style={{ ...TextStyles.subtitle, color: countryColor }}>What is this page for?</Text>
                      <Text style={{ ...TextStyles.paragraph, color: countryColor }}>By clicking each date, you will find the newsletter we have sent you in the past.</Text>
                      <Text style={{ ...TextStyles.paragraph, color: countryColor }}>Don't worry if you missed your email. You will find it here!</Text>
                      <AppGap direction='vertical' size={Sizes.medium}></AppGap>
                      <Text style={{ ...TextStyles.paragraph, color: countryColor }}>If you wish to subscribe, please contact your Account Manager.</Text>
                      <View style={{ backgroundColor: overlayColor, height: '100%', width: '400%', marginLeft: (-marginHorizontal), position: 'absolute', top: 0 }}></View>
                    </View>
                    <AppGap direction='vertical' size={Sizes.large}></AppGap>
                    <View>
                      <Text style={{ ...TextStyles.subtitle, color: Colors.darkBlue, marginBottom: Sizes.large }}>Recent Newsletters</Text>
                      <View style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', width: newslettersWidth }}>
                        {Newsletters.map(newsletter => (
                          <Pressable onPress={() => openLink(newsletter.link)}  key={newsletter.link} style={{ marginBottom: Sizes.medium, marginRight: Sizes.medium }}>
                            <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, width: 220 }}>
                              <Text style={{ color: countryColor, ...TextStyles.semiSubtitle, textAlign: 'center' }}>{newsletter.title}</Text>
                              {newsletter.highlights?.map(highlight => (
                                <Text key={highlight} style={{ ...TextStyles.paragraph, padding: 2 }}>&bull; {highlight}</Text>
                              ))}
                            </View>
                          </Pressable>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View style={{ marginLeft: 'auto' }}>
                    <Image source={require('../assets/images/news.png')} style={{ width: 244, height: 367 }}></Image>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        <AppFooter/>
      </>
    }
    {isMobile &&
      <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
          <View style={{ flex: 1 }}>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>Newsletters</Text>
            <AppGap direction='vertical' size={Sizes.medium}></AppGap>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16 }}>Browse Past PhoneBox Monthly Newsletters Below.</Text>
            <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
            <View style={{ paddingVertical: Sizes.large, height: 200 }}>
              <Text style={{ ...TextStyles.subtitle, color: Colors.white, paddingBottom: Sizes.medium }}>What is this page for?</Text>
              <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>By clicking each date, you will find the newsletter we have sent you in the past.</Text>
              <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Don't worry if you missed your email. You will find it here! If you wish to subscribe, <Text style={{ fontFamily: FontFamilys.semiBold }}>please contact your Account Manager.</Text></Text>
              <View style={{ backgroundColor: overlayColor, height: '100%', width: '450%', marginLeft: (-marginHorizontal), position: 'absolute', top: 0, zIndex: -1 }}></View>
            </View>
            <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginVertical: Sizes.large }}>Recent Newsletters</Text>
            <View >
              <View style={{ flex: 1, flexDirection: 'column' }}>
                {Newsletters.map(newsletter => (
                  <Pressable onPress={() => openLink(newsletter.link)}  key={newsletter.link} style={{ marginBottom: Sizes.large }}>
                    <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
                      <View style={{ paddingRight: Sizes.medium }}>
                        <Text style={{ color: countryColor, ...TextStyles.semiSubtitle, paddingBottom: Sizes.small }}>{newsletter.title}</Text>
                        {newsletter.highlights?.map(highlight => (
                          <Text key={highlight} style={{ ...TextStyles.paragraph, padding: 2 }}>&bull; {highlight}</Text>
                        ))}
                      </View>
                      <View style={{ position: 'absolute', right: 15, bottom: 15, borderColor: countryColor, borderWidth: 1.5, borderRadius: 500 }}>
                        <Feather name="arrow-up-right" color={ countryColor } size={ Sizes.large } />
                      </View>
                    </View>
                  </Pressable>
                ))}
              </View>
            </View>
          </View>
        </View>
        <AppGap size={100} direction={'vertical'}></AppGap>
      </ScrollView>
    }
    </>
  )
}