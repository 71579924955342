import { View, Text, Pressable } from "react-native";
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
import TextStyles from "../constants/TextStyles";
import Colors from "../constants/Colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { partnerService } from "../services/PartnerService";
import { RootState } from "../store";
import { setInvoiceReport, setInvoiceSummaryStatistics } from "../store/clients";
import { parseMonth } from "../utilities/utilities";
import useIsMobile from "../hooks/useIsMobile";
import { AppModal } from "./AppModal";
import { Picker } from "@react-native-picker/picker";
import CommonStyles from "../constants/CommonStyles";
import PickerDates from "../constants/PickerDates";
import { FontAwesome } from "@expo/vector-icons";
import Sizes from "../constants/Sizes";
import { CountryType } from "../types/enums/CountryType";
import { AppGap } from "./AppGap";
import FontFamilys from "../constants/FontFamilys";
import { DataTable } from "react-native-paper";
import { Month } from "../types/models/Month";

type DetailedInvoiceReport = {
  Category: string,
  Currency: string,
  Date: string,
  DueAmount: number, 
  Memo: string
}

type ShowMoreInfoByCnum = {
  Cnum: string,
  detailedInvoiceReport: DetailedInvoiceReport[]
}

export default function InvoiceReport() {
  const user = useSelector((state: RootState) => state.user);
  const clients = useSelector((state: RootState) => state.clients);
  const now = new Date();
  const isMobile = useIsMobile();
  // adjustment for requirement of releasing on the 10th;
  const dontShowYet = now.getDate() < 10;
  const currentInvoiceEndMonth = dontShowYet ? now.getMonth() - 1 : now.getMonth(); 
  const currentInvoiceMonth = dontShowYet ? now.getMonth() - 2 : now.getMonth() - 1; 
  const parsedEndMonth = parseMonth(currentInvoiceEndMonth);
  const parsedMonth = parseMonth(currentInvoiceMonth);
  const dispatch = useDispatch();
  const [startMonth, setStartMonth] = useState(parsedMonth);
  const [startYear, setStartYear] = useState(2023);
  const [endMonth, setEndMonth] = useState(parsedEndMonth);
  const [endYear, setEndYear] = useState(2023);
  const countryColor = Colors.blue;
  const lightCountryColor = Colors.lightBlue;
  const [modalVisible, setModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const invoiceReport = clients.invoiceReport;
  const reportLength = invoiceReport.length;
  const paginationLabel = `${(page * itemsPerPage) + 1} - ${(itemsPerPage * (page + 1))} of ${reportLength}`;
  const numberOfPages = (Math.floor(invoiceReport.length / itemsPerPage) + 1);
  const exportFileExtension = "";
  const downloadFilename = `InvoiceReport-${startYear}-${startMonth}`;
  const curMonth = PickerDates.months.find(month => month.value === startMonth)?.label;
  const [errorMsg, setErrorMsg] = useState("");
  const pageOptions = [
    5,
    10,
    20,
    reportLength
  ];

  const [showMoreCnums, setShowMoreCnums] = useState<Array<string>>([]);
  const [showMoreInfoByCnum, setShowMoreInfoByCnum] = useState<Array<ShowMoreInfoByCnum>>([]);

  const tableHead = [
    'Activation Date',
    'Termination Date',
    'Clients Name',
    'Phone Number',
    'Total',
  ];

  const showMoreTableHead = [
    'Date',
    'Currency',
    'Due Amount',
    'Category',
    'Memo'
  ];

  useEffect(() => {
    const getInvoiceReport = async () => {
      var response = await partnerService.getInvoiceReportByPartnerId({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        },
        params: {
          startDate: `${startYear}-${startMonth}-02`,
          endDate: `${endYear}-${endMonth}-01`
        }
      })

      if (!response || !response.data) {
        console.error("Something went wrong in invoice report.");
      }

      if (response.data) {
        dispatch(setInvoiceReport(response.data.CustomerInvoiceInfoList));
        dispatch(setInvoiceSummaryStatistics({
          SuspendedAccountCount: response.data.SuspendedAccountCount,
          TerminatedAccountCount: response.data.TerminatedAccountCount,
          TotalActiveClientCount: response.data.TotalActiveClientCount,
          TotalPaymentAmount: response.data.TotalPaymentAmount,
        }));
      }
    }
    getInvoiceReport().catch(err => { console.error(err) });
  }, [startYear, startMonth]);
  
  const getDetailedInvoiceReport = async (cnum: string) => {
    var response = await partnerService.getDetailedInvoiceReport({
      headers: {
        aaid: user.userInfo.aaid,
        token: user.userInfo.token
      },
      params: {
        cNum: cnum,
        startDate: `${startYear}-${startMonth}-02`,
        endDate: `${endYear}-${endMonth}-01`
      }
    })
    if (!response || !response.data) {
      console.error("Something went wrong in getDetailedInvoiceReport")
    }
    
    if (response && response.data) {
      let newInfoByCnum = {
        Cnum: cnum,
        detailedInvoiceReport: response.data
      }
      let showMoreInfoByCnumCopy = showMoreInfoByCnum.slice();
      showMoreInfoByCnumCopy?.push(newInfoByCnum);
      setShowMoreInfoByCnum(showMoreInfoByCnumCopy);
    }
  }

  const onChangeYear = (year: number) => {
    if (year > now.getFullYear()) {
      setErrorMsg("Please select a previous or current report.");
      return;
    }
    if (year === now.getFullYear() && +startMonth > now.getMonth())  {
      setErrorMsg("Please select a previous or current report.");
      return;
    }
    if (year === now.getFullYear() && dontShowYet && (+startMonth > now.getMonth() - 1)) {
      setErrorMsg("Please select a previous or current report.");
      return;
    }
    setErrorMsg("");
    setStartYear(year);
    if (endMonth === '01') {
      setEndYear(year + 1);
    } else {
      setEndYear(year);
    }
  }

  const onChangeMonth = (monthString: string) => {
    if (+monthString > now.getMonth() && startYear >= now.getFullYear()) {
      setErrorMsg("Please select a previous or current report.");
      return;
    }
    if (+monthString > now.getMonth() && startYear >= now.getFullYear()) {
      setErrorMsg("Please select a previous or current report.");
      return;
    }
    if (dontShowYet && (+startMonth > now.getMonth() - 1) && startYear >= now.getFullYear()) {
      setErrorMsg("Please select a previous or current report.");
      return;
    }
    setErrorMsg("");
    setEndMonth(parseMonth(+monthString));
    if (parseMonth(+monthString) === '01') {
      setEndYear(startYear + 1);
    } else {
      setEndYear(startYear);
    }
    setStartMonth(monthString);
  }

  const onChangeExportFileExtension = (value: string) => {
    switch (value) {
      case "CSV": 
        generateCSV();
        break;
      case "PDF":
        // generatePDF();
        break;
      case "":
        break;
    }
  }

  const generateCSV = () => {
    const rows = [
      tableHead,
    ];

    invoiceReport.forEach(entry => {
      let dueAmount = entry.PendingAmount ? entry.PendingAmount.toFixed(2).toString() : "0.00"
      let entryRow = [entry.ActivationDate, entry.TerminationDate, entry.CustomerName, entry.PhoneNumber, dueAmount];
      rows.push(entryRow);
    });
    
    let csvContent = "data:text/csv;charset=utf-8,";
    
    rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${downloadFilename}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  // const generatePDF = () => {
  //   var doc = new jsPDF();
  //   doc.text("INVOICE REPORT", 75, 10);
  //   doc.setFontSize(12);
  //   doc.text(`Partner Name: ${user.partnerInfo.BusinessName}`, 15, 20);
  //   doc.text(`Commission for ${curMonth} ${startYear}`, 145, 20);
  //   doc.text(`Date of Export: ${now.toISOString().slice(0,10)}`, 145, 30);
  //   doc.text("Summary", 15, 30);
  //   doc.setFontSize(8);
  //   doc.text(`Invoice Amount: ${clients.invoiceSummaryStatistics.TotalPaymentAmount}`, 15, 35);
  //   doc.setFontSize(12);
  //   doc.text("Summary Details", 15, 45);
  //   doc.setFontSize(8);
  //   doc.text(`Total Active Clients: ${clients.invoiceSummaryStatistics.TotalActiveClientCount}`, 15, 50);
  //   doc.text(`Total Suspended Clients: ${clients.invoiceSummaryStatistics.SuspendedAccountCount}`, 85, 50);
  //   doc.text(`Total Terminated Clients: ${clients.invoiceSummaryStatistics.TerminatedAccountCount}`, 150, 50);
  //   doc.setFontSize(12);
  //   doc.text("Partner Commission Details", 15, 60);
  //   let pdfBody = [] as string[][];
  //   let fillColor = user.country === CountryType.Canada ? [4, 148, 202] as [number, number, number] : [130, 195, 109] as [number, number, number];
  //   invoiceReport.forEach(entry => {
  //     let dueAmount = entry.PendingAmount ? entry.PendingAmount.toFixed(2).toString() : "0.00"
  //     let entryRow = [entry.ActivationDate, entry.TerminationDate, entry.CustomerName, entry.PhoneNumber, dueAmount];
      
  //     pdfBody.push(entryRow);
  //   });
  //   autoTable(doc, {
  //     headStyles: { fillColor: fillColor },
  //     startY: 65,
  //     head: [tableHead],
  //     body: pdfBody,
  //     styles: { fontSize: 8 }
  //   });
  //   doc.save(`${downloadFilename}.pdf`);

  // }

  const onPressShowMore = (cnum: string) => {
    if (showMoreCnums.includes(cnum)) {
      setShowMoreCnums(showMoreCnums.filter(c => c !== cnum));
      setShowMoreInfoByCnum(showMoreInfoByCnum?.filter(info => info.Cnum !== cnum))
    } else {
      setShowMoreCnums([...showMoreCnums, cnum]);
      getDetailedInvoiceReport(cnum).catch(err => console.error(err));
    }
  }

  return (
    <>
      {(!isMobile || isMobile) &&
        <>
          <View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 30, marginBottom: Sizes.large }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <View>
                <Text style={{ ...TextStyles.semiSubtitle }}>Invoice Report</Text>
                <View style={{ paddingHorizontal: Sizes.large, paddingTop: Sizes.medium, margin: Sizes.large, borderRadius: 10, backgroundColor: lightCountryColor }}>
                  <Text style={{ ...TextStyles.paragraph }}>Invoice Amount</Text>
                  <Text style={{ ...TextStyles.title, color: countryColor }}>${clients.invoiceSummaryStatistics.TotalPaymentAmount}</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', maxWidth: 260, position: "relative", zIndex: 0 }}>
                <Picker style={{ width: 120, height: 35, color: "#FFF", fontSize: 14, fontFamily: FontFamilys.bold, padding: 4, borderRadius: Sizes.small, backgroundColor: Colors.red, borderColor: Colors.red }} selectedValue={exportFileExtension} onValueChange={(value: string) => onChangeExportFileExtension(value)}>
                  <Picker.Item value={""} label={"Export as..."}></Picker.Item>
                  <Picker.Item value={"PDF"} label={"PDF"}></Picker.Item>
                  <Picker.Item value={"CSV"} label={"CSV"}></Picker.Item>
                </Picker>
                <AppGap direction='horizontal' size={Sizes.large}></AppGap>
                <Pressable onPress={() => setModalVisible(true)} style={{ ...CommonStyles.input, flex: 1, flexDirection: 'row', alignItems: 'center', alignContent: 'center', maxHeight: 35 }}>
                  <FontAwesome name="calendar" size={16}></FontAwesome>
                  <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: Sizes.medium }}>{startYear}-{startMonth}</Text>
                </Pressable>
              </View>
            </View>
          </View>
          <View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 30 }}>
            <View>
              <Text style={{ ...TextStyles.semiSubtitle }}>Summary Details</Text>
              <View style={{ flex: 1, flexDirection: 'row', padding: 20, alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', flexBasis: 'fit-content', backgroundColor: lightCountryColor, maxWidth: 200, justifyContent: 'center', borderRadius: 10 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, padding: Sizes.medium }}>Total Active Clients:</Text>
                  <Text>{clients.invoiceSummaryStatistics.TotalActiveClientCount}</Text>
                </View>
                <AppGap direction='horizontal' size={20}/>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', flexBasis: 'fit-content', backgroundColor: lightCountryColor, maxWidth: 240, justifyContent: 'center', borderRadius: 10 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, padding: Sizes.medium }}>Total Suspended Clients:</Text>
                  <Text>{clients.invoiceSummaryStatistics.SuspendedAccountCount}</Text>
                </View>
                <AppGap direction='horizontal' size={20}/>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', flexBasis: 'fit-content', backgroundColor: lightCountryColor, maxWidth: 240, justifyContent: 'center', borderRadius: 10 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, padding: Sizes.medium }}>Total Terminated Clients:</Text>
                  <Text >{clients.invoiceSummaryStatistics.TerminatedAccountCount}</Text>
                </View>
              </View>
            </View>
            <DataTable style={{ paddingTop: Sizes.small }}>
              <View>
                <Text style={{ ...TextStyles.semiSubtitle }}>Invoice Report</Text>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ paddingRight: Sizes.medium }}>Total: {invoiceReport.length}</Text>
                  <Picker style={{ width: 90, height: 30 }} selectedValue={itemsPerPage} onValueChange={(itemValue: number) => setItemsPerPage(itemValue)}>
                    {pageOptions.map((page) => {
                      return (<Picker.Item key={page} label={`${page}/page`} value={page}/>)
                    })}
                  </Picker>
                </View>
                <DataTable.Pagination 
                  page={page}
                  numberOfPages={numberOfPages}
                  onPageChange={(page) => setPage(page)}
                  label={paginationLabel}
                />
              </View>
              <DataTable.Header style={{ marginLeft: 20, backgroundColor: lightCountryColor, width: '100%' }}>
                {tableHead.map(head => {
                  return (<DataTable.Title key={head} style={{ flex: 1 }} textStyle={{ color: countryColor, fontFamily: FontFamilys.bold, fontSize: 12, textAlign: "center", width: "100%" }}>{head}</DataTable.Title>)
                })}
              </DataTable.Header>
              {invoiceReport.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((entry, index) => {
                return (
                  <View key={index}>
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                      {entry.PendingAmount > 0 &&
                        <Pressable onPress={() => onPressShowMore(entry.Cnum)} style={{ marginVertical: 'auto' }}>
                          {showMoreCnums.includes(entry.Cnum) ? (
                              <FontAwesome name='minus-square' color={Colors.grey} size={24}></FontAwesome>
                            ) : (
                              <FontAwesome name='plus-square' color={Colors.red} size={24}></FontAwesome>
                            )
                          }
                        </Pressable>
                      }
                      {!(entry.PendingAmount > 0) &&
                        <AppGap direction='horizontal' size={24} />
                      }
                      <DataTable.Row style={{ width: '100%' }}>
                        <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.ActivationDate}</DataTable.Cell>
                        <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.TerminationDate}</DataTable.Cell>
                        <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.CustomerName}</DataTable.Cell>
                        <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.PhoneNumber}</DataTable.Cell>
                        <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.PendingAmount ? entry.PendingAmount.toFixed(2) : "0.00"}</DataTable.Cell>
                      </DataTable.Row>
                    </View>
                    {showMoreCnums.includes(entry.Cnum) &&
                      <View style={{ padding: Sizes.small, borderRadius: Sizes.small, borderColor: Colors.lightGrey, borderWidth: 1, marginTop: Sizes.medium }}>
                        <DataTable>
                          <DataTable.Header style={{ marginLeft: 20, width: '95%' }}>
                            {showMoreTableHead.map(head => {
                              return (<DataTable.Title key={head} style={{ flex: 1 }} textStyle={{ color: countryColor, fontFamily: FontFamilys.bold, fontSize: 12, textAlign: "center", width: '100%' }}>{head}</DataTable.Title>)
                            })}
                          </DataTable.Header>
                          {showMoreInfoByCnum?.find(info => info.Cnum === entry.Cnum)?.detailedInvoiceReport.map((report, i) => {
                            return (
                              <DataTable.Row key={i} style={{ marginLeft: 20, width: '95%' }}>
                                <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{report.Date}</DataTable.Cell>
                                <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{report.Currency}</DataTable.Cell>
                                <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{report.DueAmount.toFixed(2)}</DataTable.Cell>
                                <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{report.Category}</DataTable.Cell>
                                <DataTable.Cell textStyle={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{report.Memo}</DataTable.Cell>
                              </DataTable.Row>
                            )
                          })}
                        </DataTable> 
                      </View>
                    }
                  </View>
                )
              })}
            </DataTable>
          </View>
          <AppModal onClose={() => setModalVisible(false)} visible={modalVisible} headerTitle='Select a Starting Date' width={300}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingBottom: Sizes.large, marginHorizontal: 'auto' }}>
              <Pressable onPress={() => onChangeYear(startYear - 1)}>
                <FontAwesome name="angle-double-left" size={20}></FontAwesome>
              </Pressable>
              <Text style={{ ...TextStyles.navLink, paddingHorizontal: Sizes.medium }}>{startYear}</Text>
              <Pressable onPress={() => onChangeYear(startYear + 1)}>
                <FontAwesome name="angle-double-right" size={20}></FontAwesome>
              </Pressable>
            </View>
            <Picker style={{ ...CommonStyles.input }} selectedValue={startMonth} onValueChange={(itemValue: string) => onChangeMonth(itemValue)}>
              {PickerDates.months.map((month: Month) => {
                return (<Picker.Item key={month.value} label={month.label} value={month.value} />)
              })}
            </Picker>
            {errorMsg.length > 0 &&
              <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingTop: Sizes.medium }}>
                <Text style={{ color: Colors.red }}>{errorMsg}</Text>
              </View>
            }
          </AppModal>
        </>
      }
    </>
  )
}