import { View, Text, TextInput, ScrollView, NativeSyntheticEvent, TextInputChangeEventData } from 'react-native';
import { RootStackScreenProps } from '../types';
import Sizes from '../constants/Sizes';
import Heights from '../constants/Heights';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import FontFamilys from '../constants/FontFamilys';
import TextStyles from '../constants/TextStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppButton } from '../components/AppButton';
import { AppColumn } from '../components/AppColumn';
import { AppFooter } from '../components/AppFooter';
import { AppLabel } from '../components/AppLabel';
import { AppGap } from '../components/AppGap';
import { MyInformation } from '../components/MyInformation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';
import { useEffect, useState } from 'react';
import { partnerService } from '../services/PartnerService';
import { setPartnerInfo } from '../store/user';
import useIsMobile from '../hooks/useIsMobile';
import PageLayoutStyles from '../constants/PageLayoutStyles';

export default function ChangePasswordScreen({navigation}: RootStackScreenProps<'ChangePassword'>) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const labelWidth = 140;

  useEffect(() => {
    const getProfile = async () => {
      var response = await partnerService.getProfile({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      })

      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setPartnerInfo(responseData));
      }
    }
    getProfile().catch(err => { console.error(err) })
  }, []);

  const clearAllPasswords = () => {
    setOldPassword('');
    setNewPassword('');
    setRepeatPassword('');
  }

  const onChangePassword = (event: NativeSyntheticEvent<TextInputChangeEventData>, identifier: string) => {
    switch(identifier) {
      case 'newPassword':
        setNewPassword(event.nativeEvent.text);
        break;
      case 'oldPassword':
        setOldPassword(event.nativeEvent.text);
        break;
      case 'repeatPassword': 
        setRepeatPassword(event.nativeEvent.text);
        break;
    }
  }

  const onClickNextButton = () => {
    setLoading(true)
    setIsNext(false)
    setErrorMsg('')
    setFeedbackMsg('')

    if(oldPassword === ''){
      setLoading(false)
      setErrorMsg('Please fill in all needed information.')
      return;
    } else {
      setLoading(false)
      setIsNext(true)
    }
  }

  const onClickChangePassword = async () => {
    setLoading(true);
    setErrorMsg('');
    setFeedbackMsg('');
    if (oldPassword === '' || newPassword === '' || repeatPassword === '') {
      setLoading(false);
      setErrorMsg('Please fill in all needed information.');
      clearAllPasswords();
      return;
    }
    if (newPassword !== repeatPassword) {
      setLoading(false);
      setErrorMsg('Passwords do not match.');
      clearAllPasswords();
      return;
    }
    if (newPassword.length < 8) {
      setLoading(false);
      setErrorMsg('New password must be at least 8 characters long.');
      clearAllPasswords();
      return;
    }
    if (newPassword.length > 50) {
      setLoading(false);
      setErrorMsg('New password cannot be over 50 characters long.');
      clearAllPasswords();
      return;
    }
    if (newPassword.search(/\d/) == -1) {
      setLoading(false);
      setErrorMsg('New password must contain one number.');
      clearAllPasswords();
      return;
    }
    if (newPassword.search(/[a-zA-Z]/) == -1) {
      setLoading(false);
      setErrorMsg('New password must contain one letter.');
      clearAllPasswords();
      return;
    }
    if (/[^a-zA-Z0-9]/.test(newPassword)) {
      setLoading(false);
      setErrorMsg('New password cannot contain a special character.');
      clearAllPasswords();
      return;
    }

    var response = await partnerService.changePassword({
      OldPassword: oldPassword,
      NewPassword: newPassword
    }, {
      headers: {
        aaid: user.userInfo.aaid,
        token: user.userInfo.token
      }
    });
    if (!response) {
      setLoading(false);
      setErrorMsg("Something went wrong.");
      clearAllPasswords();
      return;
    }
    if (response && response.data) {
      if (response.data.data.includes('incorrect')) {
        setLoading(false);
        setErrorMsg(response.data.data);
        clearAllPasswords();
        return;
      }
      if (response.data.data.includes('success')) {
        setLoading(false);
        setFeedbackMsg(response.data.data);
        clearAllPasswords();
        return;
      }
    }
  }
  
  return (
    <>
      {!isMobile &&
      <>
        <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop, backgroundColor: Colors.lightGrey }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
            <View style={{ flex: 1 }}>
              <View style={{ marginTop: Sizes.large }}>
                <MyInformation></MyInformation>
                <AppGap size={Sizes.large} direction={'vertical'}/>
                <AppLabel color={countryColor} text='Change Password'></AppLabel>
                <AppGap size={Sizes.large} direction={'vertical'}/>
                <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
                  <View>
                    <Text style={{ fontFamily: FontFamilys.semiBold, letterSpacing: 0.28, fontSize: 20 }}>Change Password</Text>
                  </View>
                  <View>
                    <AppColumn>
                      <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small }}>
                        <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Old Password</Text>
                        <TextInput value={oldPassword} placeholder='Type here' placeholderTextColor={Colors.grey} secureTextEntry onChange={e => onChangePassword(e, 'oldPassword')}></TextInput>
                      </View>
                      <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '50%', minWidth: 250 }}></View>
                      <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small }}>
                        <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>New Password</Text>
                        <TextInput value={newPassword} placeholder='Type here' placeholderTextColor={Colors.grey} secureTextEntry onChange={e => onChangePassword(e, 'newPassword')}></TextInput>
                      </View>
                      <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '50%', minWidth: 250 }}></View>
                      <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small }}>
                        <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Repeat Password</Text>
                        <TextInput value={repeatPassword} placeholder='Type here' placeholderTextColor={Colors.grey} secureTextEntry onChange={e => onChangePassword(e, 'repeatPassword')}></TextInput>
                      </View>
                      <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '50%', minWidth: 250 }}></View>
                      {errorMsg.length > 0 &&
                        <Text style={{ color: Colors.red, paddingTop: Sizes.medium }}>{errorMsg}</Text>
                      }
                      {feedbackMsg.length > 0 &&
                        <Text style={{ color: Colors.black, paddingTop: Sizes.medium }}>{feedbackMsg}</Text>
                      }
                    </AppColumn>
                  </View>
                  <AppGap direction='vertical' size={Sizes.medium}></AppGap>
                  <View style={{ width: 200 }}>
                    <AppButton text='Change Password' shape='rect' size='sm' textSize={14} onPress={onClickChangePassword} loading={loading}></AppButton>
                  </View>
                </View>
                <AppGap size={200} direction={'vertical'}></AppGap>
              </View>
            </View>
          </View>
        </ScrollView>
        <AppFooter/>
      </>
      }
      {isMobile &&
      <>
        <ScrollView style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingBottom: Sizes.large }}>Profile</Text>
          </View>
          <View>
            {!isNext &&
              <>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, marginBottom: Sizes.medium }}>Confirm Your Password</Text>
                <TextInput value={oldPassword} placeholder='Type here' placeholderTextColor={Colors.grey} secureTextEntry onChange={e => onChangePassword(e, 'oldPassword')} style={{ flex: 1, borderWidth: 1, borderRadius: 5, padding: 10, borderStyle: 'solid', borderColor: "#bbbbbb", backgroundColor: Colors.white }}></TextInput>
                <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginTop: Sizes.medium }}>For security reasons, please input your current password.</Text>
                {errorMsg.length > 0 &&
                  <Text style={{ color: Colors.red, paddingTop: Sizes.medium }}>{errorMsg}</Text>
                }
                {feedbackMsg.length > 0 &&
                  <Text style={{ color: Colors.black, paddingTop: Sizes.medium }}>{feedbackMsg}</Text>
                }
              </>
            }
            {isNext &&
              <>
                <View>
                  <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, marginBottom: Sizes.medium }}>New Password</Text>
                  <TextInput value={newPassword} placeholder='Type here' placeholderTextColor={Colors.grey} secureTextEntry onChange={e => onChangePassword(e, 'newPassword')} style={{ flex: 1, borderWidth: 1, borderRadius: 5, padding: 10, borderStyle: 'solid', borderColor: "#bbbbbb", backgroundColor: Colors.white }}></TextInput>
                  <Text style={{ ...TextStyles.paragraph, color: Colors.white, marginTop: Sizes.medium }}>* Must be at least 8 characters</Text>
                </View>
                <View style={{ marginTop: Sizes.large }}>
                  <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, marginBottom: Sizes.medium }}>Confirm New Password</Text>
                  <TextInput value={repeatPassword} placeholder='Type here' placeholderTextColor={Colors.grey} secureTextEntry onChange={e => onChangePassword(e, 'repeatPassword')} style={{ flex: 1, borderWidth: 1, borderRadius: 5, padding: 10, borderStyle: 'solid', borderColor: "#bbbbbb", backgroundColor: Colors.white }}></TextInput>
                </View>
                {errorMsg.length > 0 &&
                  <Text style={{ color: Colors.red, paddingTop: Sizes.medium }}>{errorMsg}</Text>
                }
                {feedbackMsg.length > 0 &&
                  <Text style={{ color: Colors.black, paddingTop: Sizes.medium }}>{feedbackMsg}</Text>
                }
              </>
            }
          </View>
          {!isNext && 
            <View style={{ paddingTop: Sizes.extraLarge }}>
              <AppButton text="Next" shape="rect" color={Colors.white} outline textSize={14} onPress={onClickNextButton}></AppButton>
            </View>
          }
          {isNext &&
            <View style={{ paddingTop: Sizes.extraLarge }}>
              <AppButton text='Confirm' shape='rect' color={Colors.white} outline textSize={14} onPress={onClickChangePassword} loading={loading}></AppButton>
            </View>
          }
          <AppGap size={100} direction={'vertical'}></AppGap>
        </ScrollView>
      </>
      }
    </>
  )
}