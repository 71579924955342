import { Platform } from "react-native"
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';

const TOKEN_KEY = 'PartnerAccessToken';

export const tokenService = {
  setAccessTokenAsync: async (value: string) => {
      if (Platform.OS !== 'web') {
          await SecureStore.setItemAsync(TOKEN_KEY, value);
          return;
      }
      await AsyncStorage.setItem(TOKEN_KEY, value);
  },
  getAccessTokenAsync: async () => {
    if (Platform.OS !== 'web') {
        return await SecureStore.getItemAsync(TOKEN_KEY);
    }
    return await AsyncStorage.getItem(TOKEN_KEY);
  },
  deleteAccessTokenAsync: async () => {
    if (Platform.OS !== 'web') {
        SecureStore.deleteItemAsync(TOKEN_KEY);
        return;
    }
    await AsyncStorage.removeItem(TOKEN_KEY);
  },
};