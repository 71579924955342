import { View, ScrollView, Text, SafeAreaView } from 'react-native';
import { RootStackScreenProps } from '../types';
import Sizes from '../constants/Sizes';
import Heights from '../constants/Heights';
import Colors from '../constants/Colors';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppFooter } from '../components/AppFooter';
import { AppLabel } from '../components/AppLabel';
import { AppGap } from '../components/AppGap';
import CommissionReport from '../components/CommissionReport';
import { MyInformation } from '../components/MyInformation';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';
import useIsMobile from '../hooks/useIsMobile';
import TextStyles from '../constants/TextStyles';
import PageLayoutStyles from '../constants/PageLayoutStyles';

export default function CommissionReportScreen({navigation}: RootStackScreenProps<'CommissionReport'>) {
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  
  return (
    <>
      {!isMobile &&
        <>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large }}>
                  <MyInformation />
                  <AppGap size={Sizes.large} direction={'vertical'}/>
                  <AppLabel color={countryColor} text='Commission Report'></AppLabel>
                  <AppGap size={Sizes.large} direction={'vertical'}/>
                  <CommissionReport /> 
                  <AppGap size={200} direction={'vertical'}></AppGap>
                </View>
              </View>
            </View>
          </ScrollView>
          <AppFooter/>
      </>
      }
      {isMobile &&
        <ScrollView style={{ ...PageLayoutStyles.reportContainer, flex: 1 }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
            <View style={{ flex: 1 }}>
              <View style={{ paddingHorizontal: marginHorizontal }}>
                <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingBottom: Sizes.large }}>Reports</Text>
                <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Commission Report</Text>
              </View>
              <View style={{ marginTop: Sizes.large }}>
                <CommissionReport /> 
                <AppGap size={100} direction={'vertical'}></AppGap>
              </View>
            </View>
          </View>
        </ScrollView>
      }

    </>
  )
}