import { View, ActivityIndicator, Text } from 'react-native';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';
import { AppGap } from './AppGap';
import { AppLogoSvgIcon } from './AppLogoSvgIcon';

export function Loading() {
  return (
    <View style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', backgroundColor: Colors.offwhite }}>
      <AppLogoSvgIcon></AppLogoSvgIcon>
      <AppGap direction="vertical" size={20}></AppGap>
      <ActivityIndicator size="large" color={Colors.blue}></ActivityIndicator>
      <Text style={{ ...TextStyles.paragraph, color: Colors.black, textAlign: 'center', marginTop: 20 }}>Hang tight.. We're getting everything ready for you.</Text>
    </View>
  );
}
