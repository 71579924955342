import { instance, proxyInstance } from "./axios";
import { EnhancedStore } from "@reduxjs/toolkit";
import { tokenService } from "../services/TokenService";
import { setCountry, setLoggedIn } from "../store/user";
import { CountryType } from "../types/enums/CountryType";

/**
 * Sets up API interceptors for authorization headers.
 * If token expired we're auto logging out customers.
 */
const setupAPIInterceptors = (store: EnhancedStore) => {
  let instances = [instance, proxyInstance];

  instances.forEach(inst => {
    // TODO: this type shouldn't be "any" but it's broken currently
    // https://github.com/axios/axios/issues/5494
    inst.interceptors.request.use(async (config: any) => {
      if (config.headers) {
        try {
          const token = await tokenService.getAccessTokenAsync();
          config.headers.Authorization = token ? `Bearer ${token}` : '';
          return config;
        } catch (e) {
          console.error(e);
          return config;
        }
      }
    })
  })

  const { dispatch } = store;

  instances.forEach(inst => {
    inst.interceptors.response.use((response) => {
      return response;
    }, async (error) => {
      if (error.response) {        
        if (error.response.status === 401) {
          await tokenService.deleteAccessTokenAsync();
          dispatch(setLoggedIn(false));
          dispatch(setCountry(CountryType.None));
        }
      }
      return Promise.reject(error.response);
    })
  })
}

export default setupAPIInterceptors;