import axios from "axios";

//@ts-ignore
// TODO: Figure out a way to type this from the dotenv library
import { BASE_API_URL, PROXY_API_URL } from '@env';

// let siteUrl = window.location.href;

// if (siteUrl.includes('partner.gophonebox.com') && !siteUrl.includes('testpartner')) {
//   BASE_API_URL = "https://phoneboxapi.azurewebsites.net/api/";
//   PROXY_API_URL = "https://phoneboxproxyprod.azurewebsites.net/";
// } else {
//   BASE_API_URL = "https://testmyaccount.azurewebsites.net/api/";
//   PROXY_API_URL = "https://phoneboxproxy.azurewebsites.net/";
// }

export const instance = axios.create({ baseURL: BASE_API_URL });
export const proxyInstance = axios.create({ baseURL: PROXY_API_URL });