import { View, Text, Image } from "react-native";
import CommonStyles from "../constants/CommonStyles";
import Colors from "../constants/Colors";
import { AppButton } from "./AppButton";
import TextStyles from "../constants/TextStyles";
import Sizes from "../constants/Sizes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setCountry } from "../store/user";
import { CountryType } from "../types/enums/CountryType";
import { AppGap } from "./AppGap";
import useIsMobile from "../hooks/useIsMobile";

type CountrySwitchCardProps = {
  canadaOnly: boolean;
  usaOnly: boolean;
  height?: number;
}

export function CountrySwitchCard(props: CountrySwitchCardProps) {
  const isMobile = useIsMobile()
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const switchCountry = user.country === CountryType.Canada ? CountryType.USA : CountryType.Canada;
  const dualCountries = (!props.canadaOnly && !props.usaOnly);
  const assetLocn = user.country === CountryType.Canada ? require("../assets/images/canada.png") : require("../assets/images/us.png")

  const onPressSwitch = () => {
    dispatch(setCountry(switchCountry));
  }

  return (
    <>
      {!isMobile &&
        <View style={{ ...CommonStyles.card, padding: 14, backgroundColor: Colors.darkBlue, height: props.height ? props.height : 'auto' }}>
          <View style={{ flex: 1, flexDirection: 'row', paddingBottom: Sizes.medium }}>
            <Image source={assetLocn} style={{ height: 20, width: 20 }}/>
            <AppGap direction="horizontal" size={Sizes.small}></AppGap>
            <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white }}>{user.country}</Text>
          </View>
          {!dualCountries &&
            <Text style={{ ...TextStyles.paragraph, color: Colors.white, paddingTop: Sizes.medium }}>Interested in the {switchCountry} market? Please contact your account manager!</Text>
          }
          {dualCountries &&
            <View style={{ width: '70%', minWidth: 100, paddingTop: Sizes.medium }}>
              <AppButton text={`Switch to ${switchCountry}`} textSize={12} size={'sm'} shape={'rect'} onPress={() => onPressSwitch()}></AppButton>
            </View>
          }
        </View>
      }
      {isMobile &&
        <View style={{ ...CommonStyles.card, backgroundColor: Colors.blueOverlay, padding: 14, flex: 1, justifyContent: "center" }}>
          <View style={{ maxWidth: 200, width: 200, alignSelf: "center" }}>
            {!dualCountries &&
              <View>
                <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Interested in the {switchCountry} market?</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Please contact your account manager!</Text>
              </View>
            }
            {dualCountries &&
              <AppButton text={`Switch to ${switchCountry}`} textSize={12} size={'sm'} shape={'rect'} onPress={() => onPressSwitch()}></AppButton>
            }
          </View>
        </View>
      }
    </>
  )
}