import { View, Text, Image, Pressable, ImageBackground, useWindowDimensions } from "react-native";
import { FontAwesome } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons'; 
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { useSelector } from "react-redux";
import { RootState } from "../store";
import CommonStyles from "../constants/CommonStyles";
import Colors from "../constants/Colors";
import TextStyles from "../constants/TextStyles";
import Sizes from "../constants/Sizes";
import FontFamilys from "../constants/FontFamilys";
import { useEffect, useState } from "react";
import { partnerService } from "../services/PartnerService";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { tokenService } from "../services/TokenService";
import { setCountry, setLoggedIn, setPartnerInfo } from "../store/user";
import { CountryType } from "../types/enums/CountryType";
import useIsMobile from "../hooks/useIsMobile";

export function MyInformation() {
  const user = useSelector((state: RootState) => state.user)
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const dimensions = useWindowDimensions();
  const [showAccountManagerInfo, setShowAccountManagerInfo] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const textWidth = 220;
  const labelWidth = 120;
  const profileIconURL = (user.partnerInfo.IconURL !== 'null' && user.partnerInfo.IconURL) ? { uri: user.partnerInfo.IconURL} : require('../assets/images/profile_icon.png');
  const languagesList = [user.partnerInfo.Language1, user.partnerInfo.Language2, user.partnerInfo.Language3, user.partnerInfo.Language4];
  const [languagesString, setLanguagesString] = useState('English');
  const veryLargePage = dimensions.width > 1200;

  const handleArrowPress = () => {
    setShowAccountManagerInfo(!showAccountManagerInfo);
  }

  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();
      dispatch(setLoggedIn(false));
      dispatch(setCountry(CountryType.None));
    }
    logout().catch(err => console.error(err));
  }

  useEffect(() => {
    const createLanguagesString = () => {
      if (languagesList.some(lang => lang !== null)) {
        // let langString = languagesList.map(lang => lang !== null ? `${lang}` : null).join(", ");
        let langString = '';
        languagesList.forEach((lang) => {
          if (lang !== null) {
            langString = langString + lang + ', '
          }
        });
        langString = langString.slice(0,-2);
        setLanguagesString(langString);
      }
    }
    createLanguagesString();
  }, [user.partnerInfo]);

  useEffect(() => {
    const getProfile = async () => {
      var response = await partnerService.getProfile({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      })

      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setPartnerInfo(responseData));
      }
    }
    getProfile().catch(err => { console.error(err) })
  }, []);

  return (
    <>
      {!isMobile &&
        <>
          <ImageBackground source={require(`../assets/images/info-background.png`)} imageStyle={{ height: 102, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
            <View style={{ ...CommonStyles.card }}>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Image source={profileIconURL} style={{ height: 40, width: 40, borderRadius: 50 }}/>
                  <View style={{ paddingLeft: Sizes.medium }}>
                    <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>{`Welcome ${user.partnerInfo.ContactPerson}`}</Text>
                    <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Manage your lines, revenue, and more.</Text>
                  </View>
                </View>
                <View style={{ flex: 1, flexDirection: 'row' }}> 
                  <View style={{ flexBasis: veryLargePage ? '35%' : '25%', paddingHorizontal: Sizes.small, height: 35 }}>
                    <Pressable style={{ height: 35, flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.darkBlue, padding: 10, borderRadius: 10, justifyContent: 'center' }} onPress={() => {navigation.navigate('Info')}}>
                      <FontAwesome5 name="user" size={12} color="white" />
                      <Text style={{ fontSize: 12, color: Colors.white, fontFamily: FontFamilys.bold, paddingLeft: 5 }} numberOfLines={1}>{veryLargePage ? "My Information" : "My Info"}</Text>
                    </Pressable>
                  </View>
                  <View style={{ flexBasis: '40%', paddingHorizontal: Sizes.small, height: 35 }}>
                    <Pressable style={{ height: 35, flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.darkBlue, padding: 10, borderRadius: 10, justifyContent: 'center' }} onPress={() => {navigation.navigate('ChangePassword')}}>
                      <MaterialCommunityIcons name="key" size={18} color="white" />
                      <Text style={{ fontSize: 12, color: Colors.white, fontFamily: FontFamilys.bold, paddingLeft: 5 }} numberOfLines={1}>Change Password</Text>
                    </Pressable>
                  </View>
                  <View style={{ flexBasis: veryLargePage ? '20%' : '25%', paddingHorizontal: Sizes.small, height: 35 }}>
                    <Pressable style={{ height: 35, flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.darkBlue, padding: 10, borderRadius: 10, justifyContent: 'center' }} onPress={() => {navigation.navigate('Root', { screen: 'Home' })}}>
                      <MaterialCommunityIcons name="home-outline" size={18} color="white" />
                      <Text style={{ fontSize: 12, color: Colors.white, fontFamily: FontFamilys.bold, paddingLeft: 5 }} numberOfLines={1}>Home</Text>
                    </Pressable>
                  </View>
                </View>
                <View style={{ paddingLeft: Sizes.medium }}>
                  <Pressable onPress={handleLogout} style={{ height: 12, flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <MaterialCommunityIcons name="logout" size={24} color="white" />
                    <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white }}>Logout</Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </ImageBackground>
          <Pressable onPress={handleArrowPress} onLayout={(event) => setContainerWidth(event.nativeEvent.layout.width)} style={{ marginTop: Sizes.medium, backgroundColor: Colors.darkBlue, padding: 10, borderBottomRightRadius: 10, borderBottomLeftRadius: 10 }}>
            <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>
              My Account Manager
            </Text>
            {showAccountManagerInfo &&
              <>
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', paddingTop: Sizes.medium, backgroundColor: Colors.darkBlue }}>
                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, paddingBottom: Sizes.small }}>
                      { user.partnerInfo.MarketerName }
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: (textWidth+labelWidth) }}>
                      <View style={{ width: labelWidth }}>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Phone Number</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Email</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Languages</Text>
                      </View>
                      <View style={{ width: textWidth }}>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>{user.partnerInfo.MarketerPhoneNumber}</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>{user.partnerInfo.MarketerEmail}</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>{languagesString}</Text>
                      </View>
                    </View>
                  </View>
                  <View style={{ flexDirection: 'column' }}>
                    <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, paddingBottom: Sizes.small }}>
                      Partner Support Channel
                    </Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: (textWidth+labelWidth) }}>
                      <View style={{ width: labelWidth }}>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Phone number</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Email</Text>
                        <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold, color: Colors.white }}>Operation time</Text>
                      </View>
                      <View style={{ width: textWidth }}>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>+1 844-906-0505</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>partnersupport@gophonebox.com</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Mon-Fri 6:00am-6:00pm PST</Text>
                      </View>
                    </View>
                  </View>
                </View>
                <View>
                  <View style={{ position: 'absolute', right: ((containerWidth / 2) - 25), top: -Sizes.medium}}>
                    <View style={{ borderRadius: 100, backgroundColor: Colors.darkBlue, paddingHorizontal: Sizes.medium, paddingTop: 10, paddingBottom: Sizes.small }}>
                      <FontAwesome name="angle-up" color={Colors.white} size={20}></FontAwesome>
                    </View>
                  </View>
                </View>
              </>
            }
            {!showAccountManagerInfo &&
              <View>
                <View style={{ position: 'absolute', right: ((containerWidth / 2) - 25), top: -Sizes.medium}}>
                  <View style={{ borderRadius: 100, backgroundColor: Colors.darkBlue, paddingHorizontal: Sizes.medium, paddingTop: 10, paddingBottom: Sizes.small }}>
                    <FontAwesome name="angle-down" color={Colors.white} size={20}></FontAwesome>
                  </View>
                </View>
              </View>
            }
          </Pressable> 
        </>
      }
      {isMobile &&
        <View>
          <ImageBackground source={require(`../assets/images/info-background.png`)} imageStyle={{ height: 170, borderRadius: 10 }}>
            <View style={{ ...CommonStyles.card }}>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'column' }}>
                  <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Image source={profileIconURL} style={{ height: 40, width: 40, borderRadius: 50 }}/>
                    <View style={{ paddingLeft: Sizes.medium }}>
                      <Text style={{ ...TextStyles.subtitle, color: Colors.white }}>{`Welcome ${user.partnerInfo.ContactPerson}`}</Text>
                      <Text style={{ ...TextStyles.paragraph, color: Colors.white }}>Manage your lines, revenue, and more.</Text>
                    </View>
                  </View>
                  <View style={{ flex: 1, flexDirection: 'row', marginTop: Sizes.medium }}> 
                    <View style={{ flexBasis: '40%', paddingHorizontal: Sizes.small, height: 35 }}>
                      <Pressable style={{ height: 35, flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.darkBlue, padding: 10, borderRadius: 10, justifyContent: 'center' }} onPress={() => {navigation.navigate('Info')}}>
                        <FontAwesome5 name="user" size={12} color="white" />
                        <Text style={{ fontSize: 12, color: Colors.white, fontFamily: FontFamilys.bold, paddingLeft: 5 }} numberOfLines={1}>{veryLargePage ? "My Information" : "My Info"}</Text>
                      </Pressable>
                    </View>
                    <View style={{ flexBasis: '40%', paddingHorizontal: Sizes.small, height: 35 }}>
                      <Pressable style={{ height: 35, flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.darkBlue, padding: 10, borderRadius: 10, justifyContent: 'center' }} onPress={() => {navigation.navigate('Root')}}>
                        <MaterialCommunityIcons name="home-outline" size={18} color="white" />
                        <Text style={{ fontSize: 12, color: Colors.white, fontFamily: FontFamilys.bold, paddingLeft: 5 }} numberOfLines={1}>Home</Text>
                      </Pressable>
                    </View>
                  </View>
                  <View style={{ paddingHorizontal: Sizes.small, height: 45, paddingTop: Sizes.medium }}>
                    <Pressable style={{ width: 170, flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: Colors.darkBlue, padding: 10, borderRadius: 10, justifyContent: 'center' }} onPress={() => {navigation.navigate('AccountManagerInfo')}}>
                      <Text style={{ fontSize: 12, color: Colors.white, fontFamily: FontFamilys.bold, paddingLeft: 5 }} numberOfLines={1}>My Account Manager</Text>
                    </Pressable>
                  </View>
                </View>
                <View style={{ paddingLeft: Sizes.medium }}>
                  <Pressable onPress={handleLogout} style={{ height: 12, flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <MaterialCommunityIcons name="logout" size={24} color="white" />
                    <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white }}>Logout</Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </ImageBackground> 
        </View>
      }
    </>
  )
}