import { RootStackScreenProps } from '../types';
import { Pressable, ScrollView, Text, View, ImageBackground, useWindowDimensions, NativeSyntheticEvent, TextInputKeyPressEventData } from 'react-native';
import { AppHeader } from '../components/AppHeader';
import { AppColumn } from '../components/AppColumn';
import { AppContainer } from '../components/AppContainer';
import { AppRow } from '../components/AppRow';
import { AppTextInput } from '../components/AppTextInput';
import { AppButton } from '../components/AppButton';
import { AppAlert } from '../components/AppAlert';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { MarginType } from '../types/enums/MarginType';
import { useEffect, useState } from 'react';
import Heights from '../constants/Heights';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import CommonStyles from '../constants/CommonStyles';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';
import FontFamilys from '../constants/FontFamilys';
import { AppGap } from '../components/AppGap';
import { authService } from '../services/AuthService';
import { tokenService } from '../services/TokenService';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { setLoggedIn, setUserInfo } from '../store/user';
import TextStyles from '../constants/TextStyles';
import useIsMobile from '../hooks/useIsMobile';
import { SignInError } from '../types/enums/SignInErrorMessage';
import { Platform } from 'react-native';
import * as WebBrowser from 'expo-web-browser';


export default function LandingScreen({ navigation }: RootStackScreenProps<'Landing'>) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);
  const dimensions = useWindowDimensions();
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const handleLogin = () => {
    if (username === '') {
      setLoginErrorMsg('Username is required.');
      return;
    }

    if (password === '') {
      setLoginErrorMsg('Password is required.');
      return;
    }

    const login = async () => {
      setLoggingIn(true);

      var response = await authService.signIn({ Username: username, Password: password })
      let responseData = response.data.PartnerInfoModel;

      if (response.data.ErrorMessage === SignInError.TooManyAttempts || response.data.ErrorMessage === SignInError.UserLocked) {
        dispatch(setLoggedIn(false));
        setLoggingIn(false);
        navigation.navigate('TooManyAttempts');
        return;
      }

      if (responseData.Token === null || response.data.ErrorMessage === SignInError.InvalidUserIdOrPassword) {
        dispatch(setLoggedIn(false));
        setLoggingIn(false);
        setLoginErrorMsg('Incorrect username or password.');
        return;
      }

      if (response && response.data) { 
        setLoggingIn(false);
  
        await tokenService.setAccessTokenAsync(responseData.Token)
        dispatch(setUserInfo({
            bid: responseData.bID,
            aaid: responseData.AaId,
            category: responseData.Category,
            token: responseData.Token,
            i_rep: responseData.i_rep,
            isTmpPwd: responseData.isTmpPwd,
            scAustralia: responseData.scAustralia,
            scCanada: responseData.scCanada,
            scUSALyca: responseData.scUSALyca,
            scUSAPlintron: responseData.scUSAPlintron,
            PartnerSimTypeDesc: responseData.PartnerSimTypeDesc,
            LangId: responseData.LangId
        }))
        dispatch(setLoggedIn(true));
        setLoginErrorMsg('');
      }
    }

    login().catch(err => {
      console.error(err);
      setLoggingIn(false);
      setLoginErrorMsg('Something went wrong.');
      return;
    });
  }

  const handleNewAccount = async () => {
    if(Platform.OS === 'web') {
      window.open("https://gophonebox.com/partners")
    } else {
      await WebBrowser.openBrowserAsync("https://gophonebox.com/partners")
    }
  }

  const handlePressEnter = (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key === "Enter") {
      handleLogin();
    }
  }

  return (
    <>
      {!isMobile && 
        <>
          <AppHeader onlyLogo={true}></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container, marginHorizontal: marginHorizontal }}>
              <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, padding: 0, borderColor: Colors.darkBlue, borderWidth: 2, width: dimensions.width > 1512 ? 840 : 'auto', marginHorizontal: dimensions.width > 1512 ? 'auto' : 0 }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'flex-start', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1, paddingLeft: 50 }}>
                        <Text style={{ ...TextStyles.semiSubtitle, color: Colors.black, fontSize: 24 }}>Sign In</Text>
                        <View>
                          <AppGap direction="vertical" size={Sizes.large}></AppGap>
                          <AppTextInput placeholder="Username" onChangeText={(text) => setUsername(text.trim())} onKeyPress={handlePressEnter}></AppTextInput>
                          <AppGap direction="vertical" size={Sizes.large}></AppGap>
                          <AppTextInput placeholder="Password" onChangeText={(text) => setPassword(text)} secureTextEntry onKeyPress={handlePressEnter}></AppTextInput>
                          <Pressable style={{ padding: Sizes.small }} onPress={() => navigation.navigate("ForgotPassword")}>
                            <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Forgot your login information?</Text>
                          </Pressable>
                          <AppGap direction="vertical" size={Sizes.large}></AppGap>
                        </View>
                        <AppButton text="Login" shape="rect" textSize={16} onPress={handleLogin} loading={loggingIn}></AppButton>
                        {loginErrorMsg.length > 0 &&
                          <View style={{ marginTop: Sizes.medium }}>
                            <AppAlert text={loginErrorMsg}></AppAlert>
                          </View>
                        }
                      </View>
                    </AppColumn>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, borderTopRightRadius: 7, flex: 1, borderBottomRightRadius: 7, backgroundColor: Colors.darkBlue, flexDirection: 'column-reverse' }}>
                        <ImageBackground source={require('../assets/images/landing.png')} style={{ height: 200, width: 'auto' }} imageStyle={{ borderBottomRightRadius: 10, width: 220, height: 220, marginLeft: 'auto', marginTop: 'auto', zIndex: 1 }}>
                          <View style={{ ...CommonStyles.card, borderWidth: 1, borderColor: Colors.white, flex: 1, flexDirection: 'row' }}>
                            <Pressable onPress={() => handleNewAccount()}>
                              <View>
                                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, width: '60%' }}>Don't have an Account?</Text>
                                <Text style={{ ...TextStyles.paragraph, color: Colors.white, width: '40%' }}>Talk to our Account Executives today.</Text>
                                <Text style={{ ...TextStyles.paragraph, color: Colors.white, width: '40%', textDecorationLine: 'underline', paddingTop: Sizes.medium }}>Click here &gt;</Text>
                              </View>
                            </Pressable>
                          </View>
                        </ImageBackground>
                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
          </ScrollView>
        </>
      }
      {isMobile && 
        <View style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', justifyContent: 'space-around' }}>
          <View>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>Partnership to Accelerate Your Business.</Text>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: Sizes.large, marginTop: Sizes.medium }}>Managing your Account Has Never Been Easier.</Text>
          </View>
          <View style={{ marginTop: Sizes.large }}>
            <View style={{ marginBottom: Sizes.medium }}>
              <AppButton text='Log In' shape="rect" onPress={() => navigation.navigate('LogIn')}></AppButton>
            </View>
            <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: Sizes.large }}>
              <Pressable style={{ alignItems: 'center' }} onPress={() => handleNewAccount()}>
                <Text style={{ color: Colors.white, fontFamily: FontFamilys.bold }}> Don't have an Account?</Text>
                <Text style={{ color: Colors.white, fontFamily: FontFamilys.regular, textDecorationLine: 'underline' }}> Talk to our Account Executives today &gt; </Text>
              </Pressable>
            </View>
          </View>
        </View>
      }
    </>
  )
}