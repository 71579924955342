import { useEffect, useState, useRef } from "react"
import { Animated, View, Text } from "react-native"
import FontSizes from "../constants/FontSizes";
import FontFamilys from "../constants/FontFamilys";
import Sizes from "../constants/Sizes";
import Colors from "../constants/Colors";

interface FadingCopiedTextProps {
  showCopied: boolean
}

export default function FadingCopiedText(props: FadingCopiedTextProps) {
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (props.showCopied) {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 120,
        useNativeDriver: true
      }).start();
    } else {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 120,
        useNativeDriver: true
      }).start();
    }
  }, [fadeAnim, props.showCopied]);

  return (
    <Animated.View style={{ opacity: fadeAnim, justifyContent: 'center', alignItems: 'center' }}>
      <View style={{ backgroundColor: Colors.red, borderRadius: Sizes.medium }}>
        <Text style={{ fontSize: FontSizes.medium, fontFamily: FontFamilys.bold, color: Colors.white, textAlign: "center", padding: 10 }}>Copied!</Text>
      </View>
    </Animated.View>
  )
}