import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../types/models/UserInfo';
import { CountryType } from '../types/enums/CountryType';
import { PartnerInfo } from '../types/models/PartnerInfo';
import { SIMCard } from '../types/models/SIMCard';
import { AvailableSimCards } from '../types/models/AvailableSimCards';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    loggedIn: false,
    userInfo: {} as UserInfo,
    country: CountryType.None,
    loading: false,
    partnerInfo: {} as PartnerInfo,
    error500: false,
    showCopiedAbsolute: false,
    showDesktopModal: true,
    availableSimCards: {
      monthly: {
        rogers: 0,
        telus: 0
      },
      prepaid: {
        rogers: 0,
        telus: 0
      }
    } as AvailableSimCards
  },
  reducers: {
    setLoggedIn(state, action: PayloadAction<boolean>) {
      state.loggedIn = action.payload;
    },
    setbId(state, action: PayloadAction<string>) {
      state.userInfo.bid = action.payload;
    },
    setAaId(state, action: PayloadAction<string>) {
      state.userInfo.aaid = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.userInfo.token = action.payload;
    },
    setError500(state, action: PayloadAction<boolean>) {
      state.error500 = action.payload;
    },
    setI_rep(state, action: PayloadAction<string>) {
      state.userInfo.i_rep = action.payload;
    },
    setUserInfo(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setCountry(state, action: PayloadAction<CountryType>) {
      state.country = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPartnerInfo(state, action: PayloadAction<PartnerInfo>) {
      state.partnerInfo = action.payload;
    },
    setIsTmpPwdFalse(state) {
      state.userInfo.isTmpPwd = false;
    },
    setShowCopiedAbsolute(state, action: PayloadAction<boolean>) {
      state.showCopiedAbsolute = action.payload;
    },
    setShowDesktopNotificationFalse(state) {
      state.showDesktopModal = false;
    },
    setAvailableSimCards(state, action: PayloadAction<string>) {
      let newAvailableSimCards = {
        monthly: {
          rogers: 0,
          telus: 0
        },
        prepaid: {
          rogers: 0,
          telus: 0
        }
      }
      let payload = JSON.parse(action.payload);
      payload.forEach((card: SIMCard) => {
        if (card.status.toLowerCase() === "available") {
          if (card.type.toLowerCase() === "postpaid") {
            if (card.carrier.toLowerCase() === "r" || card.carrier.toLowerCase() === "rogers") {
              newAvailableSimCards.monthly.rogers++
            }
            if (card.carrier.toLowerCase() === "t") {
              newAvailableSimCards.monthly.telus++
            }
          }
          if (card.type.toLowerCase() === "prepaid") {
            if (card.carrier === "r" || card.carrier.toLowerCase() === "rogers") {
              newAvailableSimCards.prepaid.rogers++
            }
            if (card.carrier.toLowerCase() === "t") {
              newAvailableSimCards.prepaid.telus++
            }
          }
        }
      });

      state.availableSimCards = newAvailableSimCards;
    }
  }
})

export const { 
  setLoggedIn, 
  setError500, 
  setUserInfo, 
  setShowCopiedAbsolute, 
  setCountry, 
  setLoading, 
  setPartnerInfo, 
  setAvailableSimCards, 
  setIsTmpPwdFalse,
  setShowDesktopNotificationFalse
} = userSlice.actions;

export default userSlice.reducer;