import { View, ScrollView } from 'react-native';
import { RootStackScreenProps } from '../types';
import Sizes from '../constants/Sizes';
import Heights from '../constants/Heights';
import Colors from '../constants/Colors';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppFooter } from '../components/AppFooter';
import { AppLabel } from '../components/AppLabel';
import { AppGap } from '../components/AppGap';
import InvoiceReport from '../components/InvoiceReport';
import { MyInformation } from '../components/MyInformation';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';

export default function InvoiceReportScreen({navigation}: RootStackScreenProps<'InvoiceReport'>) {
  const user = useSelector((state: RootState) => state.user);
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  
  return (
    <>
      <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
          <View style={{ flex: 1 }}>
            <View style={{ marginTop: Sizes.large }}>
              <MyInformation />
              <AppGap size={Sizes.large} direction={'vertical'}/>
              <AppLabel color={countryColor} text='Invoice Report'></AppLabel>
              <AppGap size={Sizes.large} direction={'vertical'}/>
              <InvoiceReport />
              <AppGap size={200} direction={'vertical'}></AppGap>
            </View>
          </View>
        </View>
      </ScrollView>
      <AppFooter/>
    </>
  )
}