import { useEffect, useState } from 'react';
import LinkingConfiguration from './LinkingConfiguration';
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { RootStackParamList, RootTabParamList } from '../types';
import LandingScreen from '../screens/LandingScreen';
import HomeScreen from '../screens/HomeScreen';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';
import { tokenService } from '../services/TokenService';
import { setLoggedIn } from '../store/user';
import { Loading } from '../components/Loading';
import CommissionReportScreen from '../screens/CommissionReportScreen';
import ClientReportScreen from '../screens/ClientReportScreen';
import InvoiceReportScreen from '../screens/InvoiceReportScreen';
import InfoScreen from '../screens/InfoScreen';
import ChangePasswordScreen from '../screens/ChangePasswordScreen';
import NewsScreen from '../screens/NewsScreen';
import InventoryScreen from '../screens/InventoryScreen';
import VideosScreen from '../screens/VideosScreen';
import PromotionsScreen from '../screens/PromotionsScreen';
import TrendingScreen from '../screens/TrendingScreen';
import AccountManagerInfoScreen from '../screens/AccountManagerInfoScreen';
import LogInScreen from '../screens/LogInScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import useIsMobile from '../hooks/useIsMobile';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
import ChangePhoneNumberScreen from '../screens/ChangePhoneNumberScreen';
import ChangeEmailScreen from '../screens/ChangeEmailScreen';
import { Ionicons, MaterialCommunityIcons, FontAwesome, Entypo, AntDesign } from '@expo/vector-icons';
import FontFamilys from '../constants/FontFamilys';
import OrderSimCardsScreen from '../screens/OrderSimCardsScreen';
import SupportTicketScreen from '../screens/SupportTicketScreen';
import * as WebBrowser from 'expo-web-browser';
import CountrySelectionScreen from '../screens/CountrySelectionScreen';
import { CountryType } from '../types/enums/CountryType';
import { View, Text, Pressable } from 'react-native';
import ScheduledActivationReportScreen from '../screens/ScheduledActivationReportScreen';
import TooManyAttemptsScreen from '../screens/TooManyAttemptsScreen';
import { SafeAreaView, Platform } from 'react-native';
import AffiliateLinksScreen from '../screens/AffiliateLinksScreen';
import FadingCopiedText from '../components/FadingCopiedText';
import ActivationScreen from '../screens/ActivationScreen';
 
export default function Navigation() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <RootNavigator />
    </NavigationContainer>
  );
}

const pageOptions = {headerTintColor: Colors.white, headerStyle: { backgroundColor: Colors.darkBlue }, headerShadowVisible: false, title: '' } as NativeStackNavigationOptions

const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const checkAccessToken = async () => {
      const token = await tokenService.getAccessTokenAsync();
      if (!token) {
        console.log('Token not found.');
        setLoading(false);
        dispatch(setLoggedIn(false));
        return;
      }

      setLoading(false);
      dispatch(setLoggedIn(true));
    }

    checkAccessToken().catch(err => console.log(err));
  }, []);

  const { loggedIn } = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile(); 

  if (loading) {
    return <Loading />
  }

  return (
    <Stack.Navigator screenOptions={{ title: 'PhoneBox', contentStyle: { backgroundColor: isMobile ? Colors.darkBlue : Colors.offwhite } }}>
      {!loggedIn && 
        <>
          <Stack.Screen name="Landing" component={LandingScreen} options={{ headerShown: false, title: 'PhoneBox - Welcome' }} />
          <Stack.Screen name="LogIn" component={LogInScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Login' }} />
          <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Forgot Password' }} />
          <Stack.Screen name="TooManyAttempts" component={TooManyAttemptsScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Too Many Attempts' }} />
        </>
      }
      {loggedIn &&
        <>
          <Stack.Screen name="Root" component={user.country === CountryType.None ? CountrySelectionScreen : BottomTabNavigator } options={{ headerShown: false, title: 'PhoneBox - Home' }} />
          <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false, title: 'PhoneBox - Home' }}/>
          <Stack.Screen name="ChangePassword" component={ChangePasswordScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Change Password' }} />
          <Stack.Screen name="ChangePhoneNumber" component={ChangePhoneNumberScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Change Phone Number' }} />
          <Stack.Screen name="ChangeEmail" component={ChangeEmailScreen} options={{ ...pageOptions, title: isMobile ? '' : 'PhoneBox - Change Email' }} />
          <Stack.Screen name="InvoiceReport" component={InvoiceReportScreen} options={{ headerShown: false, title: 'PhoneBox - Invoice Report' }} />
          <Stack.Screen name="Info" component={InfoScreen} options={{ headerShown: false, title: 'PhoneBox - My Information' }}/>
          <Stack.Screen name="OrderSimCards" component={OrderSimCardsScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Order Sim Cards' }}/>
          <Stack.Screen name="SupportTicket" component={SupportTicketScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Support Ticket' }}/>
          <Stack.Screen name="News" component={NewsScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - News & Notifications' }}/>
          <Stack.Screen name="Inventory" component={InventoryScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - SIM Card Inventory' }}/>
          <Stack.Screen name="Videos" component={VideosScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Videos & Materials' }}/>
          <Stack.Screen name="Promotions" component={PromotionsScreen} options={{ headerShown: false, title: 'PhoneBox - Promotions' }}/>
          <Stack.Screen name="Trending" component={TrendingScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - 6 Months Trending' }}/>
          <Stack.Screen name="AccountManagerInfo" component={AccountManagerInfoScreen} options={{ headerShown: false, title: 'PhoneBox - My Account Manager' }}/>
          <Stack.Screen name="CountrySelection" component={CountrySelectionScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: 'PhoneBox - Country Selection' }}/>
          <Stack.Screen name="AffiliateLinks" component={AffiliateLinksScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Affiliate Links' }}/>
          <Stack.Screen name="Activation" component={ActivationScreen} options={{ ...pageOptions, headerShown: isMobile ? true : false, title: isMobile ? '' : 'PhoneBox - Activation' }}/>
        </>
      }
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ headerShown: false, title: isMobile ? '' : 'PhoneBox - NotFound' }}/>
    </Stack.Navigator> 
  )
}

const BottomTab = createBottomTabNavigator<RootTabParamList>();
function BottomTabNavigator() {
  const navigation = useNavigation();
  const isMobile = useIsMobile();
  const isIos = Platform.OS === "ios";
  const user = useSelector((state: RootState) => state.user);

  const TabNavigatorBackButton = () => {
    return (
      <>
        <View style={{ backgroundColor: Colors.darkBlue, height: isIos ? 40 : 75, paddingTop: isIos ? 0 : 35 }}>
          <Pressable style={{ flex: 1, flexDirection: "row" }} onPress={() => { 
            navigation.navigate('Root', { screen: "Home", initial: true }) 
          }}>
            {!isIos &&
              <AntDesign name="arrowleft" style={{ color: Colors.white, fontWeight: "300", paddingLeft: Sizes.large }} size={25}/>          
            }
            {isIos &&
              <>
                <Entypo name="chevron-thin-left" style={{ color: Colors.white, fontWeight: "300" }} size={25}/>
                <Text style={{ color: Colors.white, fontSize: 18, paddingTop: 2 }}>Back</Text>
              </>
            }
          </Pressable>
        </View>
        <View style={{ position: "absolute", marginTop: 650, width: "100%", display: user.showCopiedAbsolute ? "flex" : "none" }}>
          <FadingCopiedText showCopied={user.showCopiedAbsolute}/>
        </View>
      </>
    )
  }

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: Colors.darkBlue, marginBottom: isIos ? -35 : 0 }}>
      <BottomTab.Navigator
        initialRouteName="Home"
        screenOptions={{ 
          tabBarActiveTintColor: Colors.darkBlue, 
          tabBarShowLabel: true, 
          headerShown: false, 
          tabBarLabelStyle: { fontFamily: FontFamilys.regular, fontSize: 9 },
          tabBarStyle: { backgroundColor: Colors.darkBlue },
          tabBarIconStyle: { marginTop: 3 },
        }}
      >
        <BottomTab.Screen name="Home" component={HomeScreen} options={{ tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, title: 'Home', tabBarIcon: ({ color, size }) => (<Ionicons name="md-home-outline" size={28} color={color} />) }} />
        <BottomTab.Screen name="CommissionReport" component={CommissionReportScreen} 
          options={{ 
            tabBarItemStyle: { display: 'none' }, 
            tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, 
            headerShown: isMobile ? true : false, 
            title: isMobile ? '' : 'PhoneBox - Commission Report Screen', 
            header: () => <TabNavigatorBackButton />, 
            headerStyle: { backgroundColor: Colors.darkBlue }
          }}
        />
        <BottomTab.Screen name="ClientReport" component={ClientReportScreen} 
          options={{ 
            tabBarItemStyle: { display: 'none' }, 
            tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, 
            headerShown: isMobile ? true : false, 
            title: isMobile ? '' : 'PhoneBox - Clients Report Screen', 
            header: () => <TabNavigatorBackButton />, 
            headerStyle: { backgroundColor: Colors.darkBlue }
          }} 
        />
        <BottomTab.Screen name='ScheduledActivationReport' component={ScheduledActivationReportScreen} 
          options={{ 
            tabBarItemStyle: { display: 'none' }, 
            tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' },  
            headerShown: isMobile ? true : false, 
            title: isMobile ? '' : 'PhoneBox - Clients Report Screen', 
            header: () => <TabNavigatorBackButton />, 
            headerStyle: { backgroundColor: Colors.darkBlue }
          }}
        />
        <BottomTab.Screen name="Info" component={InfoScreen} options={{ tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, title: 'Profile', tabBarIcon: ({ color, size }) => (<Ionicons name="md-person-circle-outline" size={28} color={color} />) }} />
        <BottomTab.Screen name="OrderSimCards" component={OrderSimCardsScreen} options={{ tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, title: 'Order SIM Cards', tabBarIcon: ({ color, size }) => (<MaterialCommunityIcons name="sim-outline" size={28} color={color} />) }}/>
        <BottomTab.Screen name="SupportTicket" component={SupportTicketScreen} options={{ tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, title: 'Support Ticket', tabBarIcon: ({ color, size }) => (<MaterialCommunityIcons name="ticket-outline" size={28} color={color} />) }}/>
        <BottomTab.Screen name="AccountManagerInfo" component={AccountManagerInfoScreen} options={{ tabBarStyle: !isMobile ? { display: 'none'} : { display: 'flex' }, title: 'Acc Manager', tabBarIcon: ({ color, size }) => (<FontAwesome name="handshake-o" size={28} color={color} />) }} />
      </BottomTab.Navigator>
    </SafeAreaView>
  );
}