import { ScrollView, View, Platform, Image, Text, SafeAreaView } from 'react-native';
import { RootStackScreenProps } from '../types';
import React, { useEffect, useState } from 'react';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import Sizes from '../constants/Sizes';
import Colors from '../constants/Colors';
import Heights from '../constants/Heights';
import { DashboardCard } from '../components/DashboardCard';
import { AppGap } from '../components/AppGap';
import { AppFooter } from '../components/AppFooter';
import { CountrySwitchCard } from '../components/CountrySwitchCard';
import { MyInformation } from '../components/MyInformation';
import CountrySelectComponent from '../components/CountrySelectComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';
import { partnerService } from '../services/PartnerService';
import { representativeService } from '../services/RepresentativeService';
import ResetPasswordComponent from '../components/ResetPasswordComponent';
import VendorPartners from '../constants/VendorPartners';
import { LabelledLink } from '../types/models/LabelledLink';
import { AppModal } from '../components/AppModal';
import useIsMobile from '../hooks/useIsMobile';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import FontFamilys from '../constants/FontFamilys';
import { OnlinePartnerType } from '../types/enums/OnlinePartnerType';
import TextStyles from '../constants/TextStyles';
import { PartnerSimType } from '../types/enums/PartnerSimType';
import Elevio from 'elevio/lib/react';
import { AppButton } from '../components/AppButton';
import { AppCheckbox } from '../components/AppCheckbox';
import * as WebBrowser from 'expo-web-browser';
import { getAffiliateLinkLanguageFromLangId } from '../utilities/utilities';

export default function HomeScreen({ navigation }: RootStackScreenProps<'Home'>) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const [activeReferralsValueCA, setActiveReferralsValueCA] = useState(0);
  const [activeReferralsVAlueUS, setActiveReferralsValueUS] = useState(0);
  const activeReferralsValue = user.country === CountryType.Canada ? activeReferralsValueCA : activeReferralsVAlueUS;
  const [scheduledReferralsValueCA, setScheduledReferralsValueCA] = useState(0);
  const [scheduledReferralsValueUS, setScheduledReferralsValueUS] = useState(0);
  const scheduledReferralsValue = user.country === CountryType.Canada ? scheduledReferralsValueCA : scheduledReferralsValueUS;
  const [promoVisible, setPromoVisible] = useState(false);
  const canadaOnly = ((user.userInfo.scCanada || user.userInfo.scUSALyca) && !user.userInfo.scUSAPlintron);
  const usaOnly = (!user.userInfo.scCanada && !user.userInfo.scUSALyca && user.userInfo.scUSAPlintron);
  const noCountry = !user.userInfo.scCanada && !user.userInfo.scUSALyca && !user.userInfo.scUSAPlintron;
  const isWholesale = user.userInfo.category?.toLowerCase() === 'wholesale';
  const isOnlinePartner = Object.values(OnlinePartnerType).includes(user.userInfo.bid);
  const assetLocn = user.country === CountryType.Canada ? require("../assets/images/canada.png") : require("../assets/images/us.png")
  const dualCountries = !canadaOnly && !usaOnly;

  const affiliateLinkLanguageParam = getAffiliateLinkLanguageFromLangId(user.userInfo.LangId);

  const [appLaunchMessage, setAppLaunchMessage] = useState<{ Scope: string, Title: string, Content: string, Link: string } | undefined>(undefined);
  const [muteAppLaunchMessage, setMuteAppLaunchMessage] = useState(true);
  const [muteBoxChecked, setMuteBoxChecked] = useState(false);
  // const desktopModalVisible = user.showDesktopModal;
  // show app launch message if the platform is mobile, the message is not muted, and the appLaunchMessage exists.
  const showAppLaunchMessage = Platform.OS !== "web" && !muteAppLaunchMessage;
  const isAndroid = Platform.OS === "android";
  const [desktopModalVisible, setDesktopModalVisible] = useState(true);

  // dynamic links
  const [activationLinks, setActivationLinks] = useState<Array<LabelledLink>>([]);

  const [reportLinks, setReportLinks] = useState<Array<LabelledLink>>([
    { label: 'Clients Report', dest: 'ClientReport' },
    { label: 'Commission Report', dest: 'CommissionReport' },
    { label: 'Scheduled Activation Report', dest: 'ScheduledActivationReport' }
  ]);

  const [learningCentreLinks, setLearningCentreLinks] = useState<Array<LabelledLink>>([
    { label: 'Videos & Materials', dest: 'Videos' },
  ]);

  // static links
  const newsLink = 'News';
  const orderLink = 'OrderSimCards';
  const supportLink = 'SupportTicket';
  const trendingLink = 'Trending';
  const learningCentreLink = 'Videos';
  const affiliateLinksLink = 'AffiliateLinks';

  // const onOpenPdf = () => {
  //   setPromoVisible(true);
  // }

  // onLoad useEffect
  useEffect(() => {

    const getSummaryNumbers = async () => {
      var response = await partnerService.summaryNumbers({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      });

      if (!response || !response.data) {
        console.error("Something went wrong in getSummaryNumbers().");
      }

      if (response && response.data) {
        let parsedData = JSON.parse(response.data as string);
        if (parsedData.CACurrentActiveCustomers >= 0) {
          setActiveReferralsValueCA(parsedData.CACurrentActiveCustomers);
        }
        if (parsedData.CAScheduledCustomers >= 0) {
          setScheduledReferralsValueCA(parsedData.CAScheduledCustomers);
        }
      }
    }

    const getScheduledActivationReportUS = async () => {
      var response = await representativeService.getPartnerScheduledActivationsReportUS({
        data: {
          i_rep: user.userInfo.i_rep
        }
      })

      if (!response || !response.data) {
        console.error("Something went wrong in getScheduledActivationReportUS().");
      }

      if (response && response.data && response.data.data) {
        setScheduledReferralsValueUS(response.data.data.total);
      }
    }

    const getUSAActiveReferrals = async () => {
      var response = await representativeService.countActiveReferrals({
        data: {
          i_rep: user.userInfo.i_rep
        }
      });

      if (!response || !response.data) {
        console.error("Something went wrong in getUSAActiveReferrals().");
      }

      if (response && response.data) {
        setActiveReferralsValueUS(response.data.data.amount);
      }
    }

    const getAppLaunchMessage = async () => {
      var response = await partnerService.getAppLaunchMessage({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      });

      if (!response || !response.data) {
        console.log("Something went wrong with getAppLaunchMessage().");
        return;
      }

      if (response && response.data) {
        let resData = JSON.parse(response.data);
        setAppLaunchMessage(resData);
      }
    }

    // retrieve the user's AppLaunchMessageMuteDate from their profile,
    // then determine if we should show it.
    const getAppLaunchMessageMuteDateAndSetIsMuted = async () => {
      partnerService.getProfile({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      }).then(response => {
        let responseData = JSON.parse(response.data.toString());
        let isMuted;

        // if there is no App Launch Message Mute Date to be found, the message is not muted.
        if (!responseData.AppLaunchMessageMuteDate) {
          isMuted = false;
        } else {
          // otherwise, use the mute date to determine if it is muted.
          // format backend response as UTC javascript object
          let formattedMuteDateArray = user.partnerInfo.AppLaunchMessageMuteDate.split("/");
          let year = formattedMuteDateArray[2];
          let month = formattedMuteDateArray[0];
          let day = formattedMuteDateArray[1];
          let muteDate = new Date(`${year}-${month}-${day}`);

          // add 2 days for debugging purposes
          // muteDate.setDate(muteDate.getDate() + 0);

          let now = new Date();

          // true if today is greater than muteDate
          isMuted = now >= muteDate;
        }

        setMuteAppLaunchMessage(isMuted);
        // get the app launch message if it's not muted
        if (!isMuted) {
          console.log("is not muted");
          getAppLaunchMessage().catch(err => console.error(err));
        }
      })
      .catch(err => console.error(err));
    }

    // get the App Launch Message Mute Date and determine if we should show it.
    // this only occurs in mobile.
    getAppLaunchMessageMuteDateAndSetIsMuted();

    // find USA data if they have an i_rep, otherwise ignore
    if (user.userInfo.i_rep) {
      getUSAActiveReferrals().catch(err => { console.error(err) });
      getScheduledActivationReportUS().catch(err => { console.error(err) });
    } else {
      console.log("no i_rep");
    }

    getSummaryNumbers().catch(err => console.error(err));
  }, []);

  const handleCloseAppLaunchMessage = () => {
    if (muteBoxChecked) {
      const muteAppLaunchMessageAsync = async () => {
        var muteAppLaunchMessageResponse = await partnerService.muteAppLaunchMessage({
          headers: {
            aaid: user.userInfo.aaid,
            token: user.userInfo.token
          }
        });

        if (!muteAppLaunchMessageResponse || !muteAppLaunchMessageResponse.data) {
          console.log("Something went wrong in muteAppLaunchMessage()");
          return;
        }

        if (muteAppLaunchMessageResponse && muteAppLaunchMessageResponse.data) {
          console.log("Muted: ", muteAppLaunchMessageResponse.data);
        }
      }

      muteAppLaunchMessageAsync().catch(err => console.error(err));
    }
    setMuteAppLaunchMessage(true);
  }

  const handleClickAppLaunchMessageLink = () => {
    if (appLaunchMessage) {
      if(Platform.OS === 'web'){
        window.open(appLaunchMessage.Link);
      } else {
        WebBrowser.openBrowserAsync(appLaunchMessage.Link);
      }
    } else {
      return;
    }
  }

  // country switch useEffect
  useEffect(() => {
    let possiblyEmptyIRep = user.userInfo.i_rep === null ? '' : user.userInfo.i_rep
    let physicalActivationLink;
    let eSIMLink = `https://esim.gophonebox.com/?bn=${user.userInfo.bid}&i_rep=${possiblyEmptyIRep}`

    // dynamic link setting
    let physicalActivationLinkOrigin = "https://gophonebox.com";

    // if the user has an affiliate link language param that is not an empty string
    // add this to the physical activation link origin.
    if (affiliateLinkLanguageParam !== "") {
      physicalActivationLinkOrigin += `/${affiliateLinkLanguageParam}`;
    }

    if (user.country === CountryType.USA) {
      physicalActivationLink = physicalActivationLinkOrigin + `/activation?bn=${user.userInfo.bid}&i_rep=${user.userInfo.i_rep}`;
    } else {
      physicalActivationLink = physicalActivationLinkOrigin + `/activation?bn=${user.userInfo.bid}&i_rep=${possiblyEmptyIRep}`;
    }

    // certain partners with these business ids require a different link
    if (VendorPartners.includes(+user.userInfo.bid) || +user.userInfo.bid === 3968) {
      physicalActivationLink = "https://prepaid.gophonebox.com/vendor";
    }

    if (user.userInfo.category?.toLowerCase() === 'wholesale') {
      // old way: 
      // physicalActivationLink = `https://prepaid.gophonebox.com/simcp?bn=${user.userInfo.bid}`;

      // one term:
      physicalActivationLink = "Activation"
    }

    // determine the number and type of the activation links
    if (user.userInfo.PartnerSimTypeDesc === PartnerSimType.eSIMAndPhysical) {
      setActivationLinks([
        { label: '- SIM cards & eSIM -', dest: physicalActivationLink },
        { label: '- eSIM only -', dest: eSIMLink }
      ]);
    } else if (user.userInfo.PartnerSimTypeDesc === PartnerSimType.eSIM) {
      setActivationLinks([
        { label: '- eSIM only -', dest: eSIMLink }
      ]);
    } else {
      setActivationLinks([
        { label: '- SIM cards & eSIM -', dest: physicalActivationLink }
      ])
    }

    if (user.userInfo.category && user.userInfo.category.toLowerCase() === 'wholesale') {
      setReportLinks([
        { label: 'Clients Report', dest: 'ClientReport' },
        { label: 'Scheduled Activation Report', dest: 'ScheduledActivationReport' },
        // TODO: unhide this when invoice report is launched
        // {label: 'Invoice Report', dest: 'InvoiceReport'}
      ])
    }

    if (user.country === CountryType.Canada && !isMobile && user.userInfo.category.toLowerCase() !== 'wholesale') {
      setLearningCentreLinks([
        { label: 'Videos & Materials', dest: 'Videos' }
      ])
    } else {
      setLearningCentreLinks([
        { label: 'Videos & Materials', dest: 'Videos' },
      ])
    }
  }, [user.country]);

  if (user.userInfo.isTmpPwd) {
    return <ResetPasswordComponent aaid={user.userInfo.aaid} token={user.userInfo.token} />
  }

  if (user.country === CountryType.None) {
    return <CountrySelectComponent canadaOnly={canadaOnly} usaOnly={usaOnly} noCountry={noCountry} />
  }

  return (
    <>
      {Platform.OS === "web" &&
        <View>
          <Elevio accountId="604012fe69c1d" />
        </View>
      }
      {!isMobile &&
        <>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large }}>
                  <MyInformation />
                </View>
                {!isWholesale &&
                  <View style={{ flex: 1, flexDirection: 'row', marginTop: Sizes.extraLarge }}>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <CountrySwitchCard canadaOnly={canadaOnly} usaOnly={usaOnly} />
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Activation: Affiliate Link' height={120} links={activationLinks} stayOpen></DashboardCard>
                      {isOnlinePartner &&
                        <>
                          <AppGap direction='vertical' size={Sizes.extraLarge} />
                          <DashboardCard titleText='6 Months Trending' backgroundColor={countryColor} height={100} primaryLink={trendingLink}></DashboardCard>
                        </>
                      }
                    </View>
                    <AppGap size={Sizes.extraLarge} />
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <DashboardCard titleText='Reports' backgroundColor={countryColor} height={200} links={reportLinks}></DashboardCard>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Order SIM Card' height={100} primaryLink={orderLink}></DashboardCard>
                    </View>
                    <AppGap size={Sizes.extraLarge} />
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <>
                        <DashboardCard titleText='Scheduled Referrals' height={100} countUpTo={scheduledReferralsValue} stayOpen primaryLink={'ScheduledActivationReport'}></DashboardCard>
                        <DashboardCard titleText='Current Active Referrals' height={100} countUpTo={activeReferralsValue} stayOpen primaryLink={'ClientReport'}></DashboardCard>
                      </>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Newsletters' backgroundColor={countryColor} height={100} primaryLink={newsLink}></DashboardCard>
                    </View>
                    <AppGap size={Sizes.extraLarge} />
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <DashboardCard titleText='Learning Centre' backgroundColor={countryColor} height={260} links={learningCentreLinks} background></DashboardCard>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Support' backgroundColor={countryColor} height={100} primaryLink={supportLink}></DashboardCard>
                    </View>
                  </View>
                }
                {isWholesale &&
                  <View style={{ flex: 1, flexDirection: 'row', marginTop: Sizes.extraLarge }}>
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <CountrySwitchCard canadaOnly={canadaOnly} usaOnly={usaOnly} />
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Activation' height={100} links={activationLinks} stayOpen></DashboardCard>
                    </View>
                    <AppGap size={Sizes.extraLarge} />
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <DashboardCard titleText='Reports' backgroundColor={countryColor} height={200} links={reportLinks}></DashboardCard>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='SIM Card Inventory' height={100} primaryLink={'Inventory'}></DashboardCard>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Order SIM Card' height={100} primaryLink={orderLink}></DashboardCard>
                    </View>
                    <AppGap size={Sizes.extraLarge} />
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <>
                        <DashboardCard titleText='Scheduled Referrals' height={100} countUpTo={scheduledReferralsValue} stayOpen primaryLink={'ScheduledActivationReport'}></DashboardCard>
                        <DashboardCard titleText='Current Active Referrals' height={100} countUpTo={activeReferralsValue} stayOpen primaryLink={'ClientReport'}></DashboardCard>
                      </>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='6 Months Trending' backgroundColor={countryColor} height={200} primaryLink={trendingLink}></DashboardCard>
                    </View>
                    <AppGap size={Sizes.extraLarge} />
                    <View style={{ flex: 1, flexDirection: 'column' }}>
                      <DashboardCard titleText='Newsletters' backgroundColor={countryColor} height={100} primaryLink={newsLink}></DashboardCard>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Learning Centre' backgroundColor={countryColor} height={260} links={learningCentreLinks} background></DashboardCard>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='Support' backgroundColor={countryColor} height={100} primaryLink={supportLink}></DashboardCard>
                    </View>
                  </View>
                }
                <AppGap size={100} direction={'vertical'}></AppGap>
              </View>
            </View>
            {/* <AppModal onClose={() => setPromoVisible(false)} visible={promoVisible} headerTitle='Current Promotion' width={800} height={600}>
              {Platform.OS === "web" &&
                <iframe src="https://phonebox.azurewebsites.net/images/currentPromo2.pdf#zoom=200&toolbar=0&navpanes=0&scrollbar=0" width="100%" height="500px" />
              }
            </AppModal> */}
            {/* {Platform.OS === "web" &&
              <AppModal onClose={() => setDesktopModalVisible(false)} visible={desktopModalVisible} headerTitle=' ' width={550} height={500}>
                <View>
                  <View style={{ flex: 1, flexDirection: "row", alignItems: "center", margin: "auto" }}>
                    <Image style={{ height: 32, width: 32 }} source={require("../assets/images/app_icon.png")} />
                    <Text style={{ ...TextStyles.subtitle, paddingLeft: Sizes.medium, textAlign: "center", color: Colors.darkBlue }}>New PhoneBox Partner App!</Text>
                  </View>
                  <Text style={{ ...TextStyles.semiSubtitle, textAlign: "center", marginTop: Sizes.medium }}>Enhance your PhoneBox Partner experience</Text>
                  <Text style={{ textAlign: "center", marginTop: Sizes.medium }}>Access convenience at your fingertips, anytime, anywhere.</Text>
                  <Text style={{ textAlign: "center", marginTop: Sizes.small }}>Start exploring now! Scan this QR code to download it:</Text>
                  <Image style={{ height: 250, width: 250, margin: "auto", marginTop: Sizes.large }} source={require("../assets/images/downloadQRcode.png")}/>
                </View>
              </AppModal>
            } */}
          </ScrollView>
          <AppFooter />
        </>
      }
      {isMobile &&
        <>
          <SafeAreaView style={{ flex: 1, backgroundColor: Colors.darkBlue }}>
            <ScrollView style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', position: 'relative', paddingTop: Sizes.extraLarge }}>
                <Text style={{ ...TextStyles.mobileTitle, color: Colors.white }}>{`Hello,${"\n"}${user.partnerInfo.ContactPerson}`}</Text>
                <View style={{ position: 'absolute', right: 0, top: 36 }}>
                  <Image style={{ width: 40, height: 40 }} source={assetLocn} />
                </View>
              </View>
              <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16, marginTop: Sizes.large }}>Welcome to the PhoneBox Partner App.</Text>
              <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16, marginTop: Sizes.medium }}>You can order new SIM cards, share your Activation link, find your Account Manager information and learn about our service!</Text>
              {!isWholesale &&
                <View style={{ flex: 1, flexDirection: 'column', marginTop: Sizes.extraLarge }}>
                  <CountrySwitchCard usaOnly={usaOnly} canadaOnly={canadaOnly} height={70} />
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <DashboardCard titleText='Activation: Affiliate Link' height={70} primaryLink={affiliateLinksLink}></DashboardCard>
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                      <View style={{ width: '48%' }}>
                        <DashboardCard titleText='Scheduled Referrals' height={70} countUpTo={scheduledReferralsValue} stayOpen primaryLink={'ScheduledActivationReport'}></DashboardCard>
                      </View>
                      <View style={{ width: '48%' }}>
                        <DashboardCard titleText='Current Active Referrals' height={70} countUpTo={activeReferralsValue} stayOpen primaryLink={'ClientReport'}></DashboardCard>
                      </View>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Clients Report' backgroundColor={countryColor} height={70} stayOpen primaryLink={reportLinks[0].dest}></DashboardCard>
                    </View>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Commission Report' backgroundColor={countryColor} height={70} stayOpen primaryLink={reportLinks[1].dest}></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.large} />
                  <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Scheduled Activation Report' backgroundColor={countryColor} height={70} stayOpen primaryLink={reportLinks[2].dest}></DashboardCard>
                    </View>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Newsletters' backgroundColor={countryColor} height={70} stayOpen primaryLink={newsLink}></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.large} />
                  <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Support' backgroundColor={countryColor} height={70} stayOpen primaryLink={supportLink}></DashboardCard>
                    </View>
                  </View>
                  {isOnlinePartner &&
                    <>
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                      <DashboardCard titleText='6 Months Trending' backgroundColor={countryColor} height={70} primaryLink={trendingLink}></DashboardCard>
                    </>
                  }
                  <AppGap direction='vertical' size={50} />
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <View style={{ width: '100%' }}>
                      <DashboardCard titleText='Learning Centre' backgroundColor={countryColor} height={100} stayOpen primaryLink={learningCentreLink} background></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={100} />
                </View>
              }
              {isWholesale &&
                <View style={{ flex: 1, flexDirection: 'column', marginTop: Sizes.extraLarge }}>
                  {!dualCountries &&
                    <>
                      <CountrySwitchCard canadaOnly={canadaOnly} usaOnly={usaOnly} height={70} />
                      <AppGap direction='vertical' size={Sizes.extraLarge} />
                    </>
                  }
                  <DashboardCard titleText='Activation: Affiliate Link' height={70} primaryLink={affiliateLinksLink}></DashboardCard>
                  <AppGap direction='vertical' size={Sizes.large} />
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                    <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                      <View style={{ width: '48%' }}>
                        <DashboardCard titleText='Scheduled Referrals' height={70} countUpTo={scheduledReferralsValue} stayOpen primaryLink={'ScheduledActivationReport'}></DashboardCard>
                      </View>
                      <View style={{ width: '48%' }}>
                        <DashboardCard titleText='Current Active Referrals' height={70} countUpTo={activeReferralsValue} stayOpen primaryLink={'ClientReport'}></DashboardCard>
                      </View>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Clients Report' backgroundColor={countryColor} height={70} stayOpen primaryLink={reportLinks[0].dest}></DashboardCard>
                    </View>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Commission Report' backgroundColor={countryColor} height={70} stayOpen primaryLink={reportLinks[1].dest}></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='SIM Card Inventory' height={70} primaryLink={'Inventory'}></DashboardCard>
                    </View>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='6 Months Trending' backgroundColor={countryColor} height={70} primaryLink={trendingLink}></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <View style={{ flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Support' backgroundColor={countryColor} height={70} stayOpen primaryLink={supportLink}></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={Sizes.extraLarge} />
                  <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Newsletters' backgroundColor={countryColor} height={70} primaryLink={newsLink}></DashboardCard>
                    </View>
                    <View style={{ width: '48%' }}>
                      <DashboardCard titleText='Learning Centre' backgroundColor={countryColor} height={70} primaryLink={learningCentreLink} background></DashboardCard>
                    </View>
                  </View>
                  <AppGap direction='vertical' size={100} />
                </View>
              }
              {/* <AppModal onClose={() => setPromoVisible(false)} visible={promoVisible} headerTitle='Current Promotion' width={'100%'} height={800}>
                {Platform.OS === "web" && 
                  <iframe src="https://phonebox.azurewebsites.net/images/currentPromo2.pdf#zoom=200&toolbar=0&navpanes=0&scrollbar=0" width="100%" height="500px"/>
                }
              </AppModal> */}
              {/* {appLaunchMessage && appLaunchMessage.Content &&
                <AppModal onClose={() => handleCloseAppLaunchMessage()} visible={showAppLaunchMessage} width={isAndroid ? "100%" : 300} height={isAndroid ? 420 : 380} headerTitle=" ">
                  <Text style={{ ...TextStyles.subtitle, textAlign: "center", paddingBottom: Sizes.medium }}>{appLaunchMessage.Title}</Text>
                  {appLaunchMessage.Content?.split("\\n").map(el => {
                    if (el.toLowerCase().includes("lucky raffle")) {
                      return (
                        <AppButton key={el} textSize={14} onPress={() => handleClickAppLaunchMessageLink()} text={el}/>
                      )
                    }
                    return <Text key={el} style={{ textAlign: "center", padding: Sizes.medium }}>{el}</Text>
                  })}
                  <View style={{ paddingTop: Sizes.medium, flex: 1, justifyContent: "flex-end" }}>
                    <AppCheckbox isChecked={() => setMuteBoxChecked(true)} text="Don't show this message again."/>
                  </View>
                </AppModal>
              } */}
            </ScrollView>
          </SafeAreaView>
        </>
      }
    </>
  )
}