import { View, Text, ScrollView, Pressable, useWindowDimensions } from "react-native";
import { AppButton } from "./AppButton";
import { useDispatch } from "react-redux";
import { setCountry, setLoggedIn } from "../store/user";
import { AppDispatch } from "../store";
import { CountryType } from "../types/enums/CountryType";
import { AppHeader } from "./AppHeader";
import PageLayoutStyles from "../constants/PageLayoutStyles";
import Heights from "../constants/Heights";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { AppContainer } from "./AppContainer";
import { AppRow } from "./AppRow";
import { AppColumn } from "./AppColumn";
import CommonStyles from "../constants/CommonStyles";
import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { tokenService } from "../services/TokenService";
import TextStyles from "../constants/TextStyles";
import { FontAwesome } from '@expo/vector-icons';
import { MarginType } from "../types/enums/MarginType";
import { useState } from "react";
import { partnerService } from "../services/PartnerService";
import { AppTextInput } from "./AppTextInput";
import { AppGap } from "./AppGap";
import { setIsTmpPwdFalse } from "../store/user";
import { AppAlert } from "./AppAlert";
import { StepType } from "../types/enums/StepType";

type PasswordResetComponentProps = {
  aaid: string,
  token: string
}

export default function PasswordResetComponent(props: PasswordResetComponentProps) {
  const dispatch = useDispatch<AppDispatch>();
  const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);
  const dimensions = useWindowDimensions();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [feedbackMsg, setFeedbackMsg] = useState('');
  const [step, setStep] = useState(StepType.First);

  const clearAllPasswords = () => {
    setOldPassword('');
    setNewPassword('');
    setRepeatPassword('');
  }

  const onClickChangePassword = async () => {
    setLoading(true);
    setErrorMsg('');
    setFeedbackMsg('');
    if (oldPassword === '' || newPassword === '' || repeatPassword === '') {
      setLoading(false);
      setErrorMsg('Please fill in all needed information.');
      clearAllPasswords();
      return;
    }
    if (newPassword !== repeatPassword) {
      setLoading(false);
      setErrorMsg('Passwords do not match.');
      clearAllPasswords();
      return;
    }
    if (newPassword.length < 8) {
      setLoading(false);
      setErrorMsg('New password must be at least 8 characters long.');
      clearAllPasswords();
      return;
    }
    if (newPassword.length > 50) {
      setLoading(false);
      setErrorMsg('New password cannot be over 50 characters long.');
      clearAllPasswords();
      return;
    }
    if (newPassword.search(/\d/) == -1) {
      setLoading(false);
      setErrorMsg('New password must contain one number.');
      clearAllPasswords();
      return;
    }
    if (newPassword.search(/[a-zA-Z]/) == -1) {
      setLoading(false);
      setErrorMsg('New password must contain one letter.');
      clearAllPasswords();
      return;
    }
    if (/[^a-zA-Z0-9]/.test(newPassword)) {
      setLoading(false);
      setErrorMsg('New password cannot contain a special character.');
      clearAllPasswords();
      return;
    }

    var response = await partnerService.changePassword({
      OldPassword: oldPassword,
      NewPassword: newPassword
    }, {
      headers: {
        aaid: props.aaid,
        token: props.token
      }
    });
    if (!response) {
      setLoading(false);
      setErrorMsg("Something went wrong.");
      clearAllPasswords();
      return;
    }
    if (response && response.data) {
      let responseData = response.data as unknown as string;
      if (responseData.includes('incorrect')) {
        setLoading(false);
        setErrorMsg(responseData);
        return;
      }
      if (responseData.includes('success')) {
        setLoading(false);
        setFeedbackMsg('You have successfully reset your password. Click Login to proceed.');
        clearAllPasswords();
        setStep(StepType.Success);
        return;
      }
    }
  }

  const onClickLogin = () => {
    dispatch(setIsTmpPwdFalse());
  }

  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();      
      dispatch(setLoggedIn(false));
      dispatch(setCountry(CountryType.None));
    }
    logout().catch(err => console.error(err));
  }
 
  return (
    <>
      <AppHeader onlyLogo={true}></AppHeader>
      <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
        <View style={{ ...PageLayoutStyles.container, marginHorizontal: marginHorizontal }}>
          <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, padding: 0, borderColor: Colors.darkBlue, borderWidth: 2, width: dimensions.width > 1512 ? 840 : 'auto', marginHorizontal: dimensions.width > 1512 ? 'auto' : 0 }}>
            <AppContainer>
              <AppRow>
                <AppColumn>
                  <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.medium, flex: 1 }}>
                    <Text style={{ ...TextStyles.semiSubtitle, color: Colors.black, fontSize: 24 }}>Reset Password</Text>
                    <View style={{ flex: 1, flexDirection: 'column', paddingVertical: Sizes.extraLarge }}>
                      <AppTextInput placeholder="Old Password" onChangeText={(text) => setOldPassword(text.trim())}></AppTextInput>
                      <AppGap direction="vertical" size={Sizes.large}></AppGap>
                      <AppTextInput placeholder="New Password" onChangeText={(text) => setNewPassword(text.trim())} secureTextEntry></AppTextInput>
                      <AppGap direction="vertical" size={Sizes.large}></AppGap>
                      <AppTextInput placeholder="Repeat Password" onChangeText={(text) => setRepeatPassword(text.trim())} secureTextEntry></AppTextInput>
                    </View>
                    {errorMsg.length > 0 &&
                      <View style={{ marginHorizontal: Sizes.small }}>
                        <AppAlert text={errorMsg}></AppAlert>
                      </View>
                    }
                    {feedbackMsg.length > 0 &&
                      <View style={{ marginHorizontal: Sizes.small }}>
                        <AppAlert text={feedbackMsg}></AppAlert>
                      </View>
                    }
                    {step === StepType.First &&
                      <View style={{ marginTop: Sizes.medium }}>
                        <AppButton loading={loading} text="Reset Password" shape="rect" size='sm' textSize={14} onPress={onClickChangePassword}></AppButton>
                      </View>
                    }
                    {step === StepType.Success &&
                      <View style={{ marginTop: Sizes.medium }}>
                        <AppButton text="Login" shape="rect" size='sm' textSize={14} onPress={onClickLogin}></AppButton>
                      </View>
                    }
                  </View>
                  <View style={{ marginHorizontal: 'auto', paddingBottom: Sizes.large, paddingTop: Sizes.large }}>
                    <Pressable style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }} onPress={() => handleLogout()}>
                      <FontAwesome name="angle-left" textSize={16}></FontAwesome>
                      <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: Sizes.small }}>Back</Text>
                    </Pressable>
                  </View>
                </AppColumn>
              </AppRow>
            </AppContainer>
          </View>
        </View>
      </ScrollView>
    </>
  )
}