import { ImageBackground, Pressable, Text, View, Platform } from 'react-native';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import { Feather } from '@expo/vector-icons'; 
import { useEffect, useState } from 'react';
import { LabelledLink } from '../types/models/LabelledLink';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';
import useIsMobile from '../hooks/useIsMobile';
import * as WebBrowser from 'expo-web-browser';

type DashboardCardProps = {
  titleText: string,
  backgroundColor?: string,
  hoverable?: boolean,
  height?: number,
  primaryLink?: string,
  links?: LabelledLink[],
  countUpTo? : number,
  notReadyYet?: string,
  background?: boolean,
  stayOpen?: boolean,
  onPress?: () => void,
  openPdf?: () => void,
}

export function DashboardCard(props: DashboardCardProps) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const [hover, setHover] = useState(false);
  const [titleMarginTop, setTitleMarginTop] = useState(0)
  const navigation = useNavigation();
  const isCanada = user.country === CountryType.Canada;
  const countryColor = isCanada ? Colors.blue : Colors.green;
  const countryOverlay = isCanada ? Colors.blueOverlay : Colors.greenOverlay;
  const cardImage = isMobile ? require(`../assets/images/card-image-mobile.png`) : require(`../assets/images/card-image.png`);

  const handleHoverIn = () => {
    if (props.primaryLink || props.stayOpen || isMobile) {
      return;
    }
    setHover(true);
  }

  const handleHoverOut = () => {
    if (props.stayOpen || isMobile) {
      return;
    }
    setHover(false);
  }

  const onPrimaryPress = () => {
    if (isMobile && !props.primaryLink) {
      return;
      // setHover(!hover);
    }
    if (props.primaryLink) {
      if (!props.primaryLink.includes('https')) {
        navigation.navigate(props.primaryLink as any);
      } else {
        if(Platform.OS === 'web'){
          window.open(props.primaryLink);
        } else {
          WebBrowser.openBrowserAsync(`${props.primaryLink}`);
        }
      }
    }
  }

  const onLinkPress = (link: any) => {
    if (link === 'open_pdf' && props.openPdf) {
      props.openPdf();
      return;
    }

    if (!link.includes('https')) {
      navigation.navigate(link);
      return;
    }

    if (Platform.OS === 'web') {
      window.open(link);
    } else {
      WebBrowser.openBrowserAsync(link);
    }
  }

  useEffect(() => {
    if (props.height) {
      setTitleMarginTop(props.height - 50)
    }
  }, []);

  const CardContent = () => {
    return (
      <>
        {!isMobile &&
          <>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginTop: (hover || props.stayOpen) ? 0 : titleMarginTop }}>
              <View style={{ flex: 1, flexDirection: 'column' }}>
                <Text style={{ ...TextStyles.semiSubtitle, color: props.backgroundColor ? Colors.white : Colors.black }}>{props.titleText}</Text>
              </View>
              {(!hover && !props.stayOpen) &&
                <Feather style={{ marginTop: props.titleText === 'Activation' && !isMobile ? 18 : 0 }} name="arrow-up-right" color={props.backgroundColor ? Colors.white : Colors.black} size={Sizes.large}></Feather>
              }
            </View>
            {(hover || props.stayOpen) &&
              <View style={{ position: 'absolute', top: (hover || props.stayOpen) ? 50 : 0, width: '90%' }}>
                {props.links ?
                  (
                    <View style={{ top: props.links.length > 1 ? 0 : 15 }}>
                      {props.links.map(link => (
                        <Pressable onHoverIn={handleHoverIn} key={link.label} onPress={() => onLinkPress(link.dest)} style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                          <Text style={{ color: props.backgroundColor ? Colors.white : Colors.black }}>{link.label}</Text>
                          <Feather name="arrow-up-right" color={props.backgroundColor ? Colors.white : Colors.black} size={ Sizes.large }></Feather>
                        </Pressable>
                      ))}
                    </View>
                  ) : (
                    null
                  )
                }
                {(!props.links && typeof props.countUpTo === 'number') &&
                  <View style={{ position: 'absolute', top: (hover || props.stayOpen) && !isMobile ? -10 : 35 }}>
                    <Text style={{ ...TextStyles.title, color: countryColor }}>{props.countUpTo}</Text>
                  </View>
                }
                {(!props.links && typeof props.notReadyYet === 'string') &&
                  <View style={{ position: 'absolute', top: (hover || props.stayOpen) ? 90 : 0 }}>
                    <Text style={{ ...TextStyles.semiSubtitle, color: countryColor === props.backgroundColor ? 'white' : countryColor }}>{props.notReadyYet}</Text>
                  </View>
                }
              </View>
            }
          </>
        }
        {isMobile &&
          <>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', marginTop: titleMarginTop }}>
              <View style={{ flex: 1, flexDirection: 'column' }}>
                {/* {props.titleText === 'Activation' && isMobile &&
                  <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue, position: 'absolute', top: -46, paddingRight: 5 }}>Make sure to share this affiliate link!</Text>
                } */}
              </View>
              <Feather style={{ marginTop: 0 }} name="arrow-up-right" color={props.backgroundColor ? Colors.white : Colors.darkBlue} size={Sizes.large}></Feather>
            </View>
            <View style={{ position: 'absolute', top: props.titleText === 'Current Active Referrals' || 'Scheduled Referrals' ? 50 : 15, left: 15 }}>
              {props.links ?
                (
                  <View style={{ width: 300, position: 'absolute', top: -36, paddingRight: 5 }}>
                    {props.links.map(link => (
                      <Pressable key={link.dest} onPress={() => onLinkPress(link.dest)} style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginBottom: 7 }}>
                        <Text style={{ color: props.backgroundColor ? Colors.white : Colors.darkBlue }}>{link.label}</Text>               
                      </Pressable>
                    ))}
                  </View>
                ) : (
                  null
                )
              }
              {(!props.links && typeof props.countUpTo === 'number') &&
                <View style={{ position: 'absolute', top: (hover || props.stayOpen) && isMobile ? -40 : 90 }}>
                  <Text style={{ ...TextStyles.title, color: countryColor }}>{props.countUpTo}</Text>
                </View>
              }
              {(!props.links && typeof props.notReadyYet === 'string') &&
                <View style={{ position: 'absolute', top: (hover || props.stayOpen) && isMobile ? -40 : 90 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, color: countryColor === props.backgroundColor ? 'white' : countryColor }}>{props.notReadyYet}</Text>
                </View>
              }
            </View>
            
          </>
        }
      </>
    )
  }

  return (
    <>
      {isMobile && <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>{props.titleText}</Text>}
      {props.background && 
        <ImageBackground 
          source={cardImage}
          imageStyle={{ 
            borderRadius: 10,
          }}
        >
          <Pressable 
            onPress={onPrimaryPress} 
            onHoverIn={handleHoverIn} 
            onHoverOut={handleHoverOut} 
            style={{ 
              ...CommonStyles.card, 
              padding: 14,
              backgroundColor: countryOverlay,
              height: (hover || props.stayOpen) ? props.height : undefined,
            }}
          >
            <CardContent />
          </Pressable>
        </ImageBackground>
      }
      {!props.background &&
        <Pressable 
          onPress={onPrimaryPress} 
          onHoverIn={handleHoverIn} 
          onHoverOut={handleHoverOut} 
          style={{ 
            ...CommonStyles.card, 
            borderBottomLeftRadius: props.titleText === 'Scheduled Referrals' && !isMobile ? 0 : 10,
            borderBottomRightRadius: props.titleText === 'Scheduled Referrals' && !isMobile ? 0 : 10,
            borderTopLeftRadius: props.titleText === 'Current Active Referrals' && !isMobile ? 0 : 10,
            borderTopRightRadius: props.titleText === 'Current Active Referrals' && !isMobile ? 0 : 10,
            padding: 14, 
            backgroundColor: props.backgroundColor ? props.backgroundColor : Colors.white, 
            height: (hover || props.stayOpen) ? props.height : undefined,
          }}
        >
          <CardContent />
        </Pressable>
      }
    </>

  )
}