import { Pressable, View, Text, Image, StyleSheet } from 'react-native';
import Colors from "../constants/Colors";
import TextStyles from '../constants/TextStyles';
import Heights from '../constants/Heights';
import { useNavigation } from '@react-navigation/native';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppButton } from './AppButton';
import { tokenService } from '../services/TokenService';
import { useDispatch, useSelector } from 'react-redux';
import { setCountry, setLoggedIn } from '../store/user';
import { AppDispatch, RootState } from '../store';
import { MarginType } from '../types/enums/MarginType';
import { CountryType } from '../types/enums/CountryType';

type AppHeaderProps = {
  onlyLogo?: boolean;
}

export function AppHeader(props: AppHeaderProps) {
  const user = useSelector((state: RootState) => state.user);
  const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);
  const navigation = useNavigation();
  const dispatch = useDispatch<AppDispatch>();

  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();      
      dispatch(setLoggedIn(false));
      dispatch(setCountry(CountryType.None));
    }
    logout().catch(err => console.error(err));
  }

  return (
      <View style={{ shadowColor: Colors.grey, shadowRadius: 10, backgroundColor: Colors.white, }}>
        <View style={{ height: Heights.header, flexDirection: 'row', justifyContent:  'center', marginHorizontal: marginHorizontal }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {(user && user.loggedIn) ? 
              <View>
                <Image source={require('../assets/images/logo.png')} style={{ width: 173, height: 37 }} />
              </View> 
              : 
              <Pressable onPress={() => window.open("https://gophonebox.com/", "_self")}>
                <Image source={require('../assets/images/logo.png')} style={{ width: 173, height: 37 }} />
              </Pressable>
            }
            {!props.onlyLogo && 
              <>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: 200 }}>
                  <Pressable onPress={() => navigation.navigate('Root', { screen: 'Home' })}>
                    <Text>Home</Text>
                  </Pressable>
                  <Pressable onPress={() => navigation.navigate('Landing')}>
                    <Text>Profile</Text>
                  </Pressable>
                </View>
                <AppButton text="Log Out" onPress={handleLogout} color={Colors.blue}></AppButton> 
              </>
            }
          </View>
        </View>
      </View>
  );
}

const styles = StyleSheet.create({
  active: {
      ...TextStyles.heading,
      color: Colors.blue,
      borderBottomColor: Colors.blue,
      borderBottomWidth: 3,
  }
});