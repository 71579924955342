import { View, Text, ScrollView, TextInput, NativeSyntheticEvent, TextInputChangeEventData } from "react-native"
import { useState, useEffect } from "react";
import { RootStackScreenProps } from "../types"
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Colors from '../constants/Colors';
import TextStyles from '../constants/TextStyles';
import Sizes from '../constants/Sizes';
import { PartnerInfo } from '../types/models/PartnerInfo';
import { partnerService } from '../services/PartnerService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setPartnerInfo } from '../store/user';
import useIsMobile from '../hooks/useIsMobile';
import { AppButton } from "../components/AppButton";
import { StackActions } from "@react-navigation/native";

export default function ChangePhoneNumberScreen({navigation}: RootStackScreenProps<'ChangePhoneNumber'>) {
  
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const popAction = StackActions.pop(1);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editPartnerInfo, setEditPartnerInfo] = useState({
    Bid: 0,
    BusinessName: "",
    City: "",
    ContactPerson: "",
    Country: "",
    Email: "",
    MarketerEmail: "",
    MarketerName: "",
    MarketerPhoneNumber: "",
    PhoneNumber: "",
    Postal: "",
    Province: "",
    Street: "",
    IconURL: "",
    Language1: null,
    Language2: null,
    Language3: null,
    Language4: null,
    AppLaunchMessageMuteDate: ""
  } as PartnerInfo);

  const resetInfo = () => {
    setEditPartnerInfo({
      Bid: parseInt(user.userInfo.bid),
      BusinessName: user.partnerInfo.BusinessName || "",
      City: user.partnerInfo.City || "",
      ContactPerson: user.partnerInfo.ContactPerson || "",
      Country: user.partnerInfo.Country || "",
      Email: user.partnerInfo.Email || "",
      MarketerEmail: user.partnerInfo.MarketerEmail || "",
      MarketerName: user.partnerInfo.MarketerName || "",
      MarketerPhoneNumber: user.partnerInfo.MarketerPhoneNumber || "",
      PhoneNumber: user.partnerInfo.PhoneNumber || "",
      Postal: user.partnerInfo.Postal || "",
      Province: user.partnerInfo.Province || "",
      Street: user.partnerInfo.Street || "",
      IconURL: user.partnerInfo.IconURL || "",
      Language1: user.partnerInfo.Language1 || null,
      Language2: user.partnerInfo.Language2 || null,
      Language3: user.partnerInfo.Language3 || null,
      Language4: user.partnerInfo.Language4 || null,
      CaAccountNumber: user.partnerInfo.CaAccountNumber || null,
      CaBankName: user.partnerInfo.CaBankName || null,
      CaEmail: user.partnerInfo.CaEmail || null, 
      CaPaymentMethod: user.partnerInfo.CaPaymentMethod || null,
      UsAccountNumber: user.partnerInfo.UsAccountNumber || null,
      UsBankName: user.partnerInfo.UsBankName || null,
      usEmail: user.partnerInfo.usEmail || null,
      UsPaymentMethod: user.partnerInfo.UsPaymentMethod || null,
      AppLaunchMessageMuteDate: user.partnerInfo.AppLaunchMessageMuteDate || ""
    });
  }

  useEffect(() => {
    const getProfile = async () => {
      var response = await partnerService.getProfile({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      })
      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setPartnerInfo(responseData));
      }
    }
    getProfile().catch(err => { console.error(err) })
  }, []);

  useEffect(() => {
    resetInfo();
  }, [user]);

  const onChangeInfo = (event: NativeSyntheticEvent<TextInputChangeEventData>, name: keyof PartnerInfo) => {
    setEditPartnerInfo({
      ...editPartnerInfo,
     [name]: event.nativeEvent.text
    })
  }

  const onClickSaveChanges = async () => {
    setLoading(true);
    let params = new URLSearchParams({
      contact: editPartnerInfo.ContactPerson,
      phoneNumber: editPartnerInfo.PhoneNumber,
      email: editPartnerInfo.Email
    }).toString();
    var response = await partnerService.updateProfile(
      { headers: 
        {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      },
      params
    );
    if (!response || !response.data) {
      console.log("error");
      setLoading(false);
    } else {
      setIsEdit(false);
      setLoading(false);
      const getProfile = async () => {
        var response = await partnerService.getProfile({
          headers: {
            aaid: user.userInfo.aaid,
            token: user.userInfo.token
          }
        })
  
        if (response.data) {
          let responseData = JSON.parse(response.data.toString());
          dispatch(setPartnerInfo(responseData));
        }
      }
      getProfile().catch(err => { console.error(err) })
    }

    navigation.dispatch(popAction)
  }

  return (
    <ScrollView style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue }}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingBottom: Sizes.large }}>Profile</Text>
      </View>
      <View>
        <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white, marginBottom: Sizes.medium }}>Phone Number</Text>
        <TextInput value={editPartnerInfo.PhoneNumber} placeholder='Type here' placeholderTextColor={Colors.grey} onChange={e => onChangeInfo(e, 'PhoneNumber')} style={{ flex: 1, borderWidth: 1, borderRadius: 5, padding: 10, borderStyle: 'solid', borderColor: "#bbbbbb", backgroundColor: Colors.white }}></TextInput>
      </View>
      <View style={{ paddingTop: Sizes.extraLarge }}>
        <AppButton text="Update Phone Number" shape="rect" color={Colors.white} outline textSize={14} onPress={onClickSaveChanges}></AppButton>
      </View>
    </ScrollView>
  )
}
