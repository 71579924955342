import { StyleSheet } from 'react-native';
import Colors from './Colors';

const styles = StyleSheet.create({
    row: {
        flexDirection: "row",
        alignItems: "center"
    },
    topRow: {
        flexDirection: "row"
    },
    justifyRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    container: {
        padding: 35,
    },
    card: {
        borderRadius: 10,
        padding: 20,
    },
    cardBody: {
        padding: 20
    },
    input: {
        padding: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: Colors.grey
    },
    autocompleteSelector: {
        padding: 5,
        borderRadius: 5,
        borderWidth: 0,
        borderColor: Colors.grey,
        height: 40,
    },
    autocompleteContainer: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: Colors.grey,
        // zIndex: 1,
      },
      autocompleteRoot: {
        zIndex: 1,
      },
});

export default styles;