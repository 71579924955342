import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { View, Text, Pressable, ScrollView, Platform } from "react-native";
import * as Clipboard from "expo-clipboard";
import { DataTable } from "react-native-paper";
import { Picker } from "@react-native-picker/picker";
import { FontAwesome } from '@expo/vector-icons';
import Colors from "../constants/Colors";
import CommonStyles from "../constants/CommonStyles";
import Sizes from "../constants/Sizes";
import FontFamilys from "../constants/FontFamilys";
import PickerDates from "../constants/PickerDates";
import TextStyles from "../constants/TextStyles";
import { CountryType } from "../types/enums/CountryType";
import { Month } from "../types/models/Month";
import { partnerService } from "../services/PartnerService";
import { setCanadaCommission, setCanadaCommissionReport, setUSACommissionReport } from "../store/clients";
import { AppModal } from "./AppModal";
import { AppGap } from "./AppGap";
import { formatPhoneNumber, parseMonth } from "../utilities/utilities";
import { representativeService } from "../services/RepresentativeService";
import { CommissionReportEntry } from "../types/models/CommissionReportEntry";
import useIsMobile from "../hooks/useIsMobile";
import { Table, TD, TBody, TR }from '@expo/html-elements';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { AppButton } from "./AppButton";
import DropDown from "./DropDown";
import { shareAsync } from 'expo-sharing';
import * as Print from 'expo-print';
import * as FileSystem from 'expo-file-system';
import * as Sharing from 'expo-sharing';
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { setShowCopiedAbsolute } from "../store/user";
import HasCnumInReportPartners from "../constants/HasCnumInReportPartners";


export default function CommissionReport() {
  const user = useSelector((state: RootState) => state.user);
  const clients = useSelector((state: RootState) => state.clients);
  const marginHorizontal = useHorizontalContainerMargin();
  const now = new Date();
  const isMobile = useIsMobile();
  const parsedEndMonth = parseMonth(now.getMonth());
  const parsedMonth = parseMonth(now.getMonth() - 1);
  const parsedYear = now.getFullYear();
  const dispatch = useDispatch();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const lightCountryColor = user.country === CountryType.Canada ? Colors.lightBlue : Colors.lightGreen;
  const [startMonth, setStartMonth] = useState(parsedMonth);
  const [startYear, setStartYear] = useState(parsedYear);
  const [endMonth, setEndMonth] = useState(parsedEndMonth);
  const [endYear, setEndYear] = useState(2023);
  const [dateModalVisible, setDateModalVisible] = useState(false);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [hiddenInfoVisible, setHiddenInfoVisible] = useState(false);
  const [ exportModalVisible, setExportModalVisible ] = useState(false);
  const isReportHasCnumClient = HasCnumInReportPartners.includes(parseInt(user.userInfo.bid));

  const hiddenInfoText = user.country === CountryType.Canada 
    ? "We offer a variety of payment options, including wire transfer, cheque and e-transfer."
    : "We offer a variety of payment options, including wire transfer, Zelle and Paypal.";
  const exportFileExtension = "";
  const curMonth = PickerDates.months.find(month => month.value === startMonth)?.label;
  const commissionReport = user.country === CountryType.Canada ? clients.canadaCommissionReport : clients.usaCommissionReport;
  const paginationLabel = `${(page * itemsPerPage) + 1} - ${(itemsPerPage * (page + 1))} of ${commissionReport.length}`;
  const numberOfPages = (Math.floor(commissionReport.length / itemsPerPage) + 1);
  const reportLength = commissionReport.length;
  const downloadFilename = `PartnerCommissionDetails-${user.country}-${startYear}-${startMonth}`
  const pageOptions = [ 5, 10, 20, reportLength ];

  const perPageItems = pageOptions.map((page) => ( { label: `${page}/page`, value: page } ))
  const yearItems = PickerDates.years.map((year: string) => ( { label: year, value: year } ))
  const monthItems = PickerDates.months.map((month: Month) => ( { label: month.label, value: month.value } ))
  const exportTypeItems = [
    { label: `${isMobile ? "Select type" : "Export As..."}`, value: "", disabled: 'disabled', containerStyle: { display: 'none' } },
    { label: "PDF", value: "PDF" },
    { label: "CSV", value: "CSV" }
  ]
  const [ exportType, setExportType ] = useState(exportTypeItems[0].value);

  const paymentMethod = user.country === CountryType.Canada ? user.partnerInfo.CaPaymentMethod : user.partnerInfo.UsPaymentMethod
  const emailForPaymentMethod = user.country === CountryType.Canada ? user.partnerInfo.CaEmail : user.partnerInfo.usEmail
  const bankAccountForPaymentMethod = user.country === CountryType.Canada ? user.partnerInfo.CaAccountNumber : user.partnerInfo.UsAccountNumber
  const shownInfo = (paymentMethod?.toLowerCase() === 'cheque' || paymentMethod?.toLowerCase() === 'wire transfer') ? bankAccountForPaymentMethod : emailForPaymentMethod;

  const commissionAmount = commissionReport.reduce((acc, entry) => +entry.CommissionAmount + acc, 0).toFixed(2);
  const totalActive = commissionReport.length;
  const totalSuspended = commissionReport.reduce((acc, entry) => entry.SuspensionDate !== "" ? acc++ : acc, 0)
  const totalTerminated = commissionReport.reduce((acc, entry) => entry.TerminationDate !== "" ? acc++ : acc, 0)

  const tableHead = isReportHasCnumClient ? [
    { title: 'Activation Date', width: 120 },
    { title: 'Name', width: 150 },
    { title: 'Phone Number', width: 150 },
    { title: 'SIM Number', width: 200 },
    { title: 'Plan Type', width: 100 },
    { title: 'Plan Price', width: 100 },
    { title: 'Amount', width: 100 },
    { title: 'Currency', width: 100 },
    { title: 'Client Number', width: 120 },
  ] : [
    { title: 'Activation Date', width: 120 },
    { title: 'Name', width: 150 },
    { title: 'Phone Number', width: 150 },
    { title: 'SIM Number', width: 200 },
    { title: 'Plan Type', width: 100 },
    { title: 'Plan Price', width: 100 },
    { title: 'Amount', width: 100 },
    { title: 'Currency', width: 100 },
  ];

  const tableHeadWidth = tableHead.reduce((acc, curr) => { return acc + curr.width }, 0);
  
  const getCommissionReportCanada = async () => {
    const getData = async () => {
      var startDate = new Date(`${startYear}-${startMonth}-01`);
      var response = await partnerService.getCommissionReportCanada({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        },
        params: {
          sDate: startDate
        }
      })

      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setCanadaCommission(responseData));
        dispatch(setCanadaCommissionReport());
      }
    }
    getData().catch(err => { console.error(err) });
  }

  const getPartnerCommissionUS = async () => {
    const getData = async () => {
      var response = await representativeService.getPartnerCommissionUS({
        data: {
          i_rep: user.userInfo.i_rep,
          calc_date_from: `${startYear}-${startMonth}-02T00:00:00.000Z`,
          calc_date_to: `${endYear}-${endMonth}-01T00:00:00.000Z`,
        }
      })
      if (response && response.data && response.data.data) {
        let newUSACommissionReport = [] as CommissionReportEntry[];
        response.data.data.forEach(obj => {
          newUSACommissionReport.push({
            ClientName: obj.clientName,
            PhoneNum: formatPhoneNumber(obj.phoneNumber, false, true),
            SimNum: obj.simNumber,
            PlanType: obj.planType,
            CommissionAmount: obj.commissionAmt.toFixed(2).toString(),
            CommissionCurrency: obj.commissionCurrency,
            PlanPrice: obj.planPrice.toFixed(2).toString(),
            ActivationDate: obj.activationDate.slice(0,10),
            SuspensionDate: "",
            SuspensionType: "",
            TerminationDate: ""
          } as CommissionReportEntry)
        })   
        dispatch(setUSACommissionReport(newUSACommissionReport));
      }
    }
    
    if (user.userInfo.i_rep) {
      getData().catch(err => { console.error(err); });
    } else {
      console.log("no i_rep");
    }
  }

  useEffect(() => {
    getCommissionReportCanada()
    getPartnerCommissionUS()
  }, [])

  const onClickGetCommissionReport = async () => {
    getCommissionReportCanada()
    getPartnerCommissionUS()
  }

  useEffect(() => {
    if(isMobile){
      getCommissionReportCanada()
      getPartnerCommissionUS()
    }
  }, [startMonth, startYear]);

  const onChangeYear = (year: number) => {
    setStartYear(year);
    if (endMonth === '01') {
      setEndYear(year + 1);
    } else {
      setEndYear(year);
    }
  }

  const onChangeMonth = (monthString: string) => {
    setEndMonth(parseMonth(+monthString));
    if (parseMonth(+monthString) === '01') {
      setEndYear(startYear + 1);
    } else {
      setEndYear(startYear);
    }
    setStartMonth(monthString);
  }

  const tableHeadTitles = tableHead.map(head => head.title);
  const rows = [ tableHeadTitles ];

  if (commissionReport.length > 0) {
    commissionReport.forEach(entry => {
      let entryRow = [entry.ActivationDate, entry.ClientName, entry.PhoneNum, entry.SimNum, entry.PlanType, entry.PlanPrice, entry.CommissionAmount, entry.CommissionCurrency];
      rows.push(entryRow);
    });
  }

  const generateCSV = () => {
    let csvContent = "data:text/csv;charset=utf-8,";
    
    rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${downloadFilename}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const csvDataMobile = () => {    
    let csvContent = "data:text/csv;charset=utf-8,";
    rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });
    return csvContent;
  }

  const generateCSVMobile = async (data: any) => {
    const saveFile = async (data: any) => {
      let directoryUri = FileSystem.documentDirectory;
      let fileUri = directoryUri + `${downloadFilename}.csv`;
      
      await FileSystem.writeAsStringAsync(fileUri, data, { encoding: FileSystem.EncodingType.UTF8 });
      return fileUri;
    };
      
    const shareFile = async (fileUri: any) => {
      const canShare = await Sharing.isAvailableAsync();
      if (canShare) {
        try{
          const res = await Sharing.shareAsync(fileUri);
          console.log('shareAsync', res);
          return true;
        } catch {
          return false;
        }
      } else {
        alert("Cannot share file.")
    }};

    const fileUri = await saveFile(data);
    const res = await shareFile(fileUri);
    return res;
  }

  const html = `
  <html>
    <head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no" />
    </head>
    <body style="font-family: Helvetica Neue; font-size: 12px; font-weight: normal; padding: 10px;">
      <h1 style="text-align: center; font-weight: bold; font-size: 20px;">PARTNER COMMISSION</h1>
      <div style="display: flex; justify-content: space-between; align-items: flex-start;">
        <h3 style="font-size: 15px; font-weight: bold;"> Partner Name: ${user.partnerInfo.BusinessName}</h3>
        <div>
          <p style="font-size: 15px; font-weight: bold;">Commission for ${curMonth} ${startYear}</p>
          <p style="font-size: 15px; font-weight: bold;">Date of Export: ${now.toISOString().slice(0,10)}</p>
        </div>
      </div>
      <div>
        <h4 style="font-size: 15px; font-weight: bold;">Summary</h4>
        <p>Commission Amount: ${commissionAmount}</p>
      </div>
      <div>
        <h4 style="font-size: 15px; font-weight: bold";>Summary Details</h4>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <p>Total New Clients: ${totalActive}</p>
          <p>Total Suspended Clients: ${totalSuspended}</p>
          <p>Total Terminated Clients: ${totalTerminated}</p>
        </div>
      </div>
      <div>
        <h4 style="font-size: 15px; font-weight: bold;">Partner Commission Details</h4>
        <table style="width: 100%;">
          <tr style="width: 100%;">
            ${tableHeadTitles.map(head => `<th style="font-size: 10px; text-align: center;">${head}</th>`).join("")}
          </tr>
          ${rows.slice(1).map(row => `<tr style="padding: 5px 0; width: 100%;">${row.map(cell => `<td style="font-size: 10px; text-align: center;">${cell}</td>`).join("")}</tr>`).join("")}
        </table>
      </div>
    </body>
  </html>
  `;

  const generatePDF = () => {
    const pW = window.open('', '', 'height=500, width=500');
    pW?.document.write(html)
    pW?.document.close()
    pW?.print()
  }

  const generatePDFMobile = async () => {
    const { uri } = await Print.printToFileAsync({ html });
    await shareAsync(uri, { UTI: '.pdf', mimeType: 'application/pdf', dialogTitle: downloadFilename });
    console.log('inside generatePDFMobile')
  };

  const onChangeExportFileExtension = (value: string) => {
    switch (value) {
      case "CSV": 
        if(Platform.OS === 'web'){
          generateCSV();
        } else {
          generateCSVMobile(csvDataMobile());
        }
        break;
      case "PDF":
        if(Platform.OS === 'web'){
          generatePDF();
        } else {
          generatePDFMobile();
        }
        break;
      case "":
        break;
    }

    setExportType('')
  }

  const copyToClipboard = async (textToCopy: string) => {
    await Clipboard.setStringAsync(textToCopy);

    dispatch(setShowCopiedAbsolute(true));
    setTimeout(() => {
      dispatch(setShowCopiedAbsolute(false));
    }, 1800);
  }

  const MobileCommissionReportEntryTD = (props: {width: number, data: string}) => {
    return (
      <TD style={{ minWidth: props.width, maxWidth: props.width }}>
        <Pressable style={{ paddingTop: 16, paddingBottom: 10 }} onPress={() => copyToClipboard(props.data)}>
          <Text numberOfLines={1} style={{ ...TextStyles.paragraph, minWidth: props.width, maxWidth: props.width, textAlign: 'center' }}>
            {props.data}
          </Text>
        </Pressable>
      </TD>
    )
  }

  return (
    <>
      {!isMobile &&
        <>
          <View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 30, marginBottom: Sizes.large }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <View>
                <Text style={{ ...TextStyles.semiSubtitle }}>Partner Commission</Text>
                <View style={{ paddingHorizontal: Sizes.large, paddingTop: Sizes.medium, margin: Sizes.large, borderRadius: 10, backgroundColor: lightCountryColor }}>
                  <Text style={{ ...TextStyles.paragraph }}>Commission Amount</Text>
                  <Text style={{ ...TextStyles.title, color: countryColor }}>${commissionAmount}</Text>
                </View>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', borderLeftWidth: 1, borderColor: Colors.lightGrey, paddingLeft: Sizes.extraLarge }}>
                <View style={{ flex: 1, flexDirection: 'row', position: "relative", width: "auto", zIndex: 100 }}>
                  <View style={{ flex: 1 }}>
                    <Text style={{ ...TextStyles.semiSubtitle }}>Commission Payment Method</Text>
                    <Text style={{ ...TextStyles.paragraph }}>{paymentMethod ? paymentMethod : "Once you've set up a payment method, it will be shown here."}</Text>
                    <Text style={{ ...TextStyles.paragraph }}>{shownInfo ? shownInfo : "Please contact your Account Manager if you have any questions."}</Text>  
                  </View>
                  <Pressable onPress={() => setHiddenInfoVisible(true)} style={{ paddingLeft: Sizes.large, paddingRight: Sizes.large }} >
                    <FontAwesome name="question-circle" size={24} color={countryColor}></FontAwesome>
                  </Pressable>
                </View>
                <View style={{ flex: 1, flexDirection: 'row', maxWidth: 280, position: "relative", zIndex: 0 }}>
                  <View style={{ marginBottom: Sizes.large, flex: 1, zIndex: 100 }}>
                    <DropDown webDesktop value={exportType} items={exportTypeItems} setValue={setExportType} onChangeValue={(value: string) => onChangeExportFileExtension(value)} />
                  </View>
                  <AppGap direction='horizontal' size={Sizes.medium}></AppGap>
                  <Pressable onPress={() => setDateModalVisible(true)} style={{ ...CommonStyles.input, flex: 1, flexDirection: 'row', alignItems: 'center', alignContent: 'center', maxHeight: 35 }}>
                    <FontAwesome name="calendar" size={16}></FontAwesome>
                    <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: Sizes.medium }}>{startYear}-{startMonth}</Text>
                  </Pressable>
                </View>
              </View>
            </View>
          </View>
          <View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 30 }}>
            <View>
              <Text style={{ ...TextStyles.semiSubtitle }}>Summary Details</Text>
              <View style={{ flex: 1, flexDirection: 'row', padding: 20, alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: lightCountryColor, maxWidth: 200, justifyContent: 'center', borderRadius: 10 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, padding: Sizes.medium }}>Total Active Clients:</Text>
                  <Text>{totalActive}</Text>
                </View>
                <AppGap direction='horizontal' size={20}/>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center',  backgroundColor: lightCountryColor, maxWidth: 240, justifyContent: 'center', borderRadius: 10 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, padding: Sizes.medium }}>Total Suspended Clients:</Text>
                  <Text>{totalSuspended}</Text>
                </View>
                <AppGap direction='horizontal' size={20}/>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', backgroundColor: lightCountryColor, maxWidth: 240, justifyContent: 'center', borderRadius: 10 }}>
                  <Text style={{ ...TextStyles.semiSubtitle, padding: Sizes.medium }}>Total Terminated Clients:</Text>
                  <Text >{totalTerminated}</Text>
                </View>
              </View>
            </View>
            <View style={{ minWidth: "100%", maxWidth: "100%", overflow: "scroll" }}>
              <View>
                <Text style={{ ...TextStyles.semiSubtitle }}>Partner Commission Details</Text>
              </View>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ paddingRight: Sizes.medium }}>Total: {commissionReport.length}</Text>
                  <Picker style={{ width: 90, height: 30 }} selectedValue={itemsPerPage} onValueChange={(itemValue: number) => setItemsPerPage(itemValue)}>
                    {pageOptions.map((page) => {
                      return (<Picker.Item key={page} label={`${page}/page`} value={page}/>)
                    })}
                  </Picker>
                </View>
                <DataTable.Pagination 
                  page={page}
                  numberOfPages={numberOfPages}
                  onPageChange={(page) => setPage(page)}
                  label={paginationLabel}
                />
              </View>
              <DataTable.Header style={{ backgroundColor: lightCountryColor , minWidth: "100%", width: tableHeadWidth + 36, padding: 0, flex: 1, flexDirection: "row", justifyContent: "space-between"}}>
                {tableHead.map(head => {
                  return (<DataTable.Title key={head.title} style={{ flex: head.title === "SIM Number" ? 2 : 1, minWidth: head.width, maxWidth: head.width }} textStyle={{ color: countryColor, fontFamily: FontFamilys.bold, fontSize: 12, textAlign: "center", minWidth: head.width, maxWidth: head.width }}>{head.title}</DataTable.Title>)
                })}
              </DataTable.Header>
              {commissionReport.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((entry, index) => {
                return (
                <Table style={{ borderBottomWidth: 1, borderColor: Colors.lightGrey }} key={index}>
                  <TBody>
                    <TR style={{ maxWidth: "100%", alignItems: 'center', justifyContent: 'flex-start' }}>
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 120, maxWidth: 120, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.ActivationDate}</Text></TD>
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 150, maxWidth: 150, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.ClientName}</Text></TD>                
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 150, maxWidth: 150, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.PhoneNum}</Text></TD>                
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 200, maxWidth: 200, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.SimNum}</Text></TD>                
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.PlanType}</Text></TD>                
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.PlanPrice}</Text></TD>                
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.CommissionAmount}</Text></TD>                
                      <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.CommissionCurrency}</Text></TD>
                      {isReportHasCnumClient &&
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 120, maxWidth: 120, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, textAlign: "center", width: "100%" }}>{entry.CNum}</Text></TD>                
                      }
                    </TR>
                  </TBody>
                </Table>
                )
              })}
            </View>
          </View>
          <AppModal onClose={() => setDateModalVisible(false)} visible={dateModalVisible} headerTitle='Select a Starting Date' width={300}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingBottom: Sizes.large, marginHorizontal: 'auto' }}>
              <Pressable onPress={() => onChangeYear(startYear - 1)}>
                <FontAwesome name="angle-double-left" size={20}></FontAwesome>
              </Pressable>
              <Text style={{ ...TextStyles.navLink, paddingHorizontal: Sizes.medium }}>{startYear}</Text>
              <Pressable onPress={() => onChangeYear(startYear + 1)}>
                <FontAwesome name="angle-double-right" size={20}></FontAwesome>
              </Pressable>
            </View>
            <Picker style={{ ...CommonStyles.input }} selectedValue={startMonth} onValueChange={(itemValue: string) => onChangeMonth(itemValue)}>
              {PickerDates.months.map((month: Month) => {
                return (<Picker.Item key={month.value} label={month.label} value={month.value}/>)
              })}
            </Picker>
            <AppGap size={Sizes.medium} direction={'vertical'}></AppGap>
            <AppButton text='Confirm' shape='rect' size='sm' textSize={12} onPress={() => {onClickGetCommissionReport(); setDateModalVisible(false);}}></AppButton>
          </AppModal>

          <AppModal onClose={() => setHiddenInfoVisible(false)} visible={hiddenInfoVisible} headerTitle='Commission Payment Method' width={400}>
            <View style={{ flex: 1, flexDirection: 'column', paddingBottom: Sizes.large, marginHorizontal: 'auto' }}>
              <Text style={{ ...TextStyles.paragraph }}>If you would like to update your payment method, please do not hesitate to reach out to your account manager. </Text>
              <Text style={{ ...TextStyles.paragraph, paddingVertical: Sizes.medium }}>{hiddenInfoText}</Text>
              <Text style={{ ...TextStyles.paragraph }}>Thank you!</Text>
            </View>        
          </AppModal>
        </>
      }
      {isMobile &&
        <>
          <View style={{ borderRadius: 10, backgroundColor: Colors.lightBlue, padding: 25, marginHorizontal: marginHorizontal, }}>
            <View style={{ flex: 1, flexDirection: 'column' }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View>
                  <Text style={{ ...TextStyles.semiSubtitle, color: Colors.blue }}>Partner Commission</Text>
                  <Text style={{ ...TextStyles.title, color: countryColor }}>${commissionAmount}</Text>
                </View>
                <Pressable onPress={() => setHiddenInfoVisible(true)}>
                  <FontAwesome name="question-circle" size={24} color={countryColor}></FontAwesome>
                </Pressable>
              </View>
              <View style={{ flex: 1, flexDirection: 'column', borderTopWidth: 1, borderColor: Colors.blue }}>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.blue, paddingTop: Sizes.medium }}>Commission Payment Method</Text>
                <Text style={{ ...TextStyles.paragraph }}>{paymentMethod ? paymentMethod : "Once you've set up a payment method, it will be shown here."} {shownInfo ? shownInfo : "Please contact your Account Manager if you have any questions."}</Text>
              </View>
            </View>
          </View>
          <View style={{ marginTop: Sizes.large, marginHorizontal: marginHorizontal, zIndex: 2000 }}>
            <View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', width: '60%', marginRight: '2%' }}>
                <View style={{ flex: 1 }}>
                  <DropDown value={startYear} items={yearItems} setValue={setStartYear} placeholder={startYear} />
                </View>
                <View style={{ flex: 1 }}>
                  <DropDown value={startMonth} items={monthItems} setValue={setStartMonth} placeholder={startMonth} />
                </View>
              </View>
              <Pressable onPress={() => setExportModalVisible(true)} style={{ ...CommonStyles.input, backgroundColor: Colors.blue, flex: 1, flexDirection: 'row', alignItems: 'center', alignContent: 'center', width: '40%', height: 35 }}>
                <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: Sizes.medium, color: Colors.white, marginRight: 5 }}>Export As.. </Text>
                <MaterialCommunityIcons name="export-variant" size={16} color={Colors.white} />
              </Pressable>
            </View>
          </View>
          <View style={{ marginHorizontal: 20, marginTop: Sizes.large }}>
            <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white }}>Summary Details</Text>
            <View style={{  marginVertical: Sizes.medium, borderRadius: 8, }}>
              <View style={{ alignSelf: 'flex-start', backgroundColor: Colors.white, borderRadius: 5, flexDirection: 'row', alignItems: 'center',  justifyContent: 'center', paddingHorizontal: Sizes.medium, paddingVertical: Sizes.small, marginBottom: Sizes.medium }}>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.darkBlue, paddingRight: Sizes.small }}>Total Active Clients:</Text>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.darkBlue }}>{totalActive}</Text>
              </View>
              <AppGap direction='horizontal' size={10}/>
              <View style={{ alignSelf: 'flex-start', backgroundColor: Colors.white, borderRadius: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingHorizontal: Sizes.medium, paddingVertical: Sizes.small, marginBottom: Sizes.medium }}>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.darkBlue, paddingRight: Sizes.small }}>Total Suspended Clients:</Text>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.darkBlue }}>{totalSuspended}</Text>
              </View>
              <AppGap direction='horizontal' size={10}/>
              <View style={{ alignSelf: 'flex-start', backgroundColor: Colors.white, borderRadius: 5, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingHorizontal: Sizes.medium, paddingVertical: Sizes.small, marginBottom: Sizes.medium }}>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.darkBlue, paddingRight: Sizes.small }}>Total Terminated Clients:</Text>
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.darkBlue }}>{totalTerminated}</Text>
              </View>
            </View>
          </View>
          <View style={{ backgroundColor: Colors.white, marginTop: Sizes.medium }}>
            <DataTable style={{ paddingVertical: Sizes.large }}>
              <View style={{ paddingLeft: 20, zIndex: 500, marginBottom: Sizes.medium }}>
                <View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={{ ...TextStyles.paragraph }}>Total: {commissionReport.length}</Text>
                </View>
                <View style={{ zIndex: 500, flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between', paddingTop: 5 }}>
                  <View style={{ width: '33%' }}>
                    <DropDown value={itemsPerPage} items={perPageItems} setValue={setItemsPerPage} placeholder={`${itemsPerPage}/page`} minHeight={30} />
                  </View>
                  <DataTable.Pagination 
                    page={page}
                    numberOfPages={numberOfPages}
                    onPageChange={(page) => setPage(page)}
                    label={paginationLabel}
                  />
                </View>
              </View>
              <ScrollView horizontal={commissionReport.length !== 0 ? true : false}>
                <View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 10 }}>
                  {commissionReport.length === 0 ? 
										<Text style={{ padding: 20, width: '100%', ...TextStyles.paragraph, textAlign: 'center', color: countryColor, backgroundColor: Colors.lightBlue }}>No results found</Text>
										:
                    <Table style={{ backgroundColor: lightCountryColor, width: tableHeadWidth }}>
                      <TBody style={{ width: tableHeadWidth }}>
                        <TR style={{ maxWidth: tableHeadWidth }}>
                          {tableHead.map(head => {
                            return (
                              <TD key={head.title} style={{ minWidth: head.width, maxWidth: head.width, paddingVertical: 12, color: countryColor, fontFamily: FontFamilys.bold, fontSize: 12, textAlign: "center", width: "100%" }}>
                                {head.title}
                              </TD>
                            )
                          })}
                        </TR>
                      </TBody>
                    </Table>
									}
                  {commissionReport.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((entry, index) => {
                    return (
                      <Table style={{ borderBottomWidth: 1, borderColor: Colors.lightGrey }} key={index}>
                        <TBody style={{ width: tableHeadWidth }}>
                          <TR style={{ maxWidth: tableHeadWidth, alignItems: 'center', justifyContent: 'flex-start' }}>
                            <MobileCommissionReportEntryTD width={120} data={entry.ActivationDate}/>
                            <MobileCommissionReportEntryTD width={150} data={entry.ClientName}/>
                            <MobileCommissionReportEntryTD width={150} data={entry.PhoneNum}/>
                            <MobileCommissionReportEntryTD width={200} data={entry.SimNum}/>
                            <MobileCommissionReportEntryTD width={100} data={entry.PlanType}/>
                            <MobileCommissionReportEntryTD width={100} data={entry.PlanPrice}/>
                            <MobileCommissionReportEntryTD width={100} data={entry.CommissionAmount}/>
                            <MobileCommissionReportEntryTD width={100} data={entry.CommissionCurrency}/>
                            {isReportHasCnumClient &&
                              <MobileCommissionReportEntryTD width={120} data={entry.CNum}/>  
                            }
                          </TR>
                        </TBody>
                      </Table>
                    )
                  })}
                </View>
              </ScrollView>
            </DataTable>
          </View>
          <AppGap size={100} direction={'vertical'}></AppGap>
          <AppModal onClose={() => setExportModalVisible(false)} visible={exportModalVisible} headerTitle='Export as..' width={300}>
            <View style={{ marginBottom: Sizes.large, zIndex: 100 }}>
              <DropDown value={exportType} items={exportTypeItems} setValue={setExportType} placeholder={"Select type"} />
            </View>
            <AppButton text="Export" size="md" shape="rect" color={user.country === CountryType.Canada ? Colors.blue : Colors.green } onPress={() => onChangeExportFileExtension(exportType)} />
          </AppModal>
          <AppModal onClose={() => setHiddenInfoVisible(false)} visible={hiddenInfoVisible} headerTitle='Commission Payment Method' width={300}>
            <View style={{ flexDirection: 'column', paddingBottom: Sizes.large, marginHorizontal: 'auto' }}>
              <Text style={{ ...TextStyles.paragraph }}>If you would like to update your payment method, please do not hesitate to reach out to your account manager.</Text>
              <Text style={{ ...TextStyles.paragraph, paddingVertical: Sizes.medium }}>{hiddenInfoText}</Text>
              <Text style={{ ...TextStyles.paragraph }}>Thank you!</Text>  
            </View>   
          </AppModal>
        </>
      }
    </>
  )
}