import { StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';
import FlexStyles from '../constants/FlexStyles';

type AppHorizontalBarProps = {
  size?: number;
  color?: string;
}

export function AppHorizontalBar(props: AppHorizontalBarProps) {
  return (
    <View style={FlexStyles.rowVerticalCenter}>
      <View style={{ width: props.size ? props.size : 30, borderTopColor: props.color ? props.color : Colors.green, borderTopWidth: 2 }}></View>
    </View>
  );
}
