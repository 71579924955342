import { View, Text, TextInput, Pressable, ScrollView, useWindowDimensions } from "react-native";
import { Picker } from "@react-native-picker/picker";
import * as Clipboard from 'expo-clipboard';
import { DataTable } from "react-native-paper";
import { useEffect, useState } from "react";
import { partnerService } from "../services/PartnerService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setCanadaClients, setCanadaClientsReport, setUSAClientsReport } from "../store/clients";
import { setShowCopiedAbsolute } from "../store/user";
import { ClientReportEntry } from "../types/models/ClientReportEntry";
import { Feather, Ionicons } from '@expo/vector-icons';
import { Table, TD, TBody, TR } from '@expo/html-elements';
import Sizes from "../constants/Sizes";
import Colors from "../constants/Colors";
import PickerDates from "../constants/PickerDates";
import { Month } from "../types/models/Month";
import CommonStyles from "../constants/CommonStyles";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import FontFamilys from "../constants/FontFamilys";
import TextStyles from "../constants/TextStyles";
import { parseMonth } from "../utilities/utilities";
import { ActivationFilterType } from "../types/enums/ActivationFilterType";
import { AppButton } from "./AppButton";
import { AppGap } from "./AppGap";
import { CountryType } from "../types/enums/CountryType";
import useIsMobile from "../hooks/useIsMobile";
import ResponsiveWidths from "../constants/ResponsiveWidths";
import DropDown from "./DropDown";
import { representativeService } from "../services/RepresentativeService";
import HasCnumInReportPartners from "../constants/HasCnumInReportPartners";

export default function ClientsReport() {
  const user = useSelector((state: RootState) => state.user);
  const clients = useSelector((state: RootState) => state.clients);
  const isMobile = useIsMobile();
  const isWholesale = user.userInfo.category?.toLowerCase() === 'wholesale';
  const now = new Date();
  const parsedMonth = parseMonth(now.getMonth());
  const parsedYear = now.getFullYear().toString();
  const [fromMonth, setFromMonth] = useState('01');
  const [fromYear, setFromYear] = useState('2020');
  const [toMonth, setToMonth] = useState(parsedMonth);
  const [toYear, setToYear] = useState(parsedYear);
  const dispatch = useDispatch();
  const dimensions = useWindowDimensions();
  const country = user.country;
  const isCanada = country === CountryType.Canada;
  const countryColor = isCanada ? Colors.blue : Colors.green;
  const lightCountryColor = isCanada ? Colors.lightBlue : Colors.lightGreen;
  const horizontalContainerMargin = useHorizontalContainerMargin();
  const [selectedSearchOption, setSelectedSearchOption] = useState<keyof ClientReportEntry>('Name');
  const [searchTerms, setSearchTerms] = useState('');
  const [activationFilter, setActivationFilter] = useState(ActivationFilterType.All);
  const clientsReport = isCanada ? clients.canadaClientsReport : clients.usaClientsReport;
  const isReportHasCnumClient = HasCnumInReportPartners.includes(parseInt(user.userInfo.bid));
  // TODO: remove condition once USA API is updated
  const tableHead = isReportHasCnumClient ? [
    { title: 'Activated', width: 120 },
    { title: 'Name', width: 150 },
    { title: 'Email', width: 300 },
    { title: 'Phone Number', width: 150 },
    { title: 'Plan Price', width: 100 },
    { title: 'Plan Cap', width: 100 },
    { title: 'Terminated', width: 120 },
    { title: 'SIM Number', width: 200 },
    { title: 'SIM Type', width: 100 },
    { title: 'Plan Type', width: 100 },
    { title: 'Client Number', width: 150 }
  ] : isCanada ? [
    { title: 'Activated', width: 120 },
    { title: 'Name', width: 150 },
    { title: 'Email', width: 300 },
    { title: 'Phone Number', width: 150 },
    { title: 'Plan Price', width: 100 },
    { title: 'Plan Cap', width: 100 },
    { title: 'Terminated', width: 120 },
    { title: 'SIM Number', width: 200 },
    { title: 'SIM Type', width: 100 },
    { title: 'Plan Type', width: 100 }
  ] : [
    { title: 'Activated', width: 120 },
    { title: 'Name', width: 150 },
    { title: 'Email', width: 300 },
    { title: 'Phone Number', width: 150 },
    { title: 'Plan Price', width: 100 },
    { title: 'Plan Cap', width: 100 },
    { title: 'Inactive', width: 150 },
    { title: 'Terminated', width: 120 },
    { title: 'SIM Number', width: 200 },
    { title: 'SIM Type', width: 100 },
    { title: 'Plan Type', width: 100 }
  ];

  const tableHeadWidth = tableHead.reduce((acc, curr) => { return acc + curr.width }, 0);

  const searchOptions: Array<keyof ClientReportEntry> = isReportHasCnumClient ? [
    'ActivationDate',
    'Name',
    'Email',
    'Phonenumber',
    'PlanPrice',
    'PlanCapacity',
    'TerminationDate',
    'simNum',
    'isESim',
    'PlanType',
    'CNum'
  ] : isCanada ? [
    'ActivationDate',
    'Name',
    'Email',
    'Phonenumber',
    'PlanPrice',
    'PlanCapacity',
    'TerminationDate',
    'simNum',
    'isESim',
    'PlanType'
  ] : [
    'ActivationDate',
    'Name',
    'Email',
    'Phonenumber',
    'PlanPrice',
    'PlanCapacity',
    'SuspensionDate',
    'TerminationDate',
    'simNum',
    'isESim',
    'PlanType'
  ]

  const searchOptionsItems = isReportHasCnumClient ? [
    { label: 'Activation Date', value: 'ActivationDate' },
    { label: 'Name', value: 'Name' },
    { label: 'Email', value: 'Email' },
    { label: 'Phone Number', value: 'Phonenumber' },
    { label: 'Plan Price', value: 'PlanPrice' },
    { label: 'Plan Capacity', value: 'PlanCapacity' },
    { label: 'Termination Date', value: 'TerminationDate' },
    { label: 'SIM Number', value: 'simNum' },
    { label: 'SIM Type', value: 'isESim' },
    { label: 'Plan Type', value: 'PlanType' },
    { label: 'CNum', value: 'CNum' }
  ] : isCanada ? [
    { label: 'Activation Date', value: 'ActivationDate' },
    { label: 'Name', value: 'Name' },
    { label: 'Email', value: 'Email' },
    { label: 'Phone Number', value: 'Phonenumber' },
    { label: 'Plan Price', value: 'PlanPrice' },
    { label: 'Plan Capacity', value: 'PlanCapacity' },
    { label: 'Termination Date', value: 'TerminationDate' },
    { label: 'SIM Number', value: 'simNum' },
    { label: 'SIM Type', value: 'isESim' },
    { label: 'Plan Type', value: 'PlanType' }
  ] : [
    { label: 'Activation Date', value: 'ActivationDate' },
    { label: 'Name', value: 'Name' },
    { label: 'Email', value: 'Email' },
    { label: 'Phone Number', value: 'Phonenumber' },
    { label: 'Plan Price', value: 'PlanPrice' },
    { label: 'Plan Capacity', value: 'PlanCapacity' },
    { label: 'Inactive Date', value: 'SuspensionDate' },
    { label: 'Termination Date', value: 'TerminationDate' },
    { label: 'SIM Number', value: 'simNum' },
    { label: 'SIM Type', value: 'isESim' },
    { label: 'Plan Type', value: 'PlanType' }
  ]

  const searchConditions = (entry: ClientReportEntry) => {
    if (entry[selectedSearchOption]?.toLowerCase().includes(searchTerms.toLowerCase())) {
      return entry
    }
  }

  const dateConditions = (entry: ClientReportEntry) => {
    // Term dates go from the 2nd of the selected fromMonth
    // to the 1st of the month AFTER the selected toMonth.
    // e.g. Feb - Feb selected: Feb 02 - March 01
    // Therefore, we must increment the toMonth in all cases,
    // with December being a special case where toMonth is set to
    // January (months[0]) and toYear is incremented by one.
    let position = PickerDates.months.map(month => month.value).indexOf(toMonth);
    let parsedToMonth;
    let parsedToYear;
    if (position === 11) {
      parsedToMonth = PickerDates.months[0].value;
      parsedToYear = +toYear + 1;
    } else {
      parsedToMonth = PickerDates.months[position + 1].value;
      parsedToYear = toYear;
    }
    let fromDate = new Date(`${fromYear}-${fromMonth}-02`);
    let toDate = new Date(`${parsedToYear}-${parsedToMonth}-01`);
    let activationDate = new Date(entry.ActivationDate);
    if ((fromDate <= activationDate && activationDate <= toDate) || entry.ActivationDate === '') {
      return entry
    }
  }

  const yearItems = PickerDates.years.map((year: string) => (
    { label: year, value: year }
  ))

  const monthItems = PickerDates.months.map((month: Month) => (
    { label: month.label, value: month.value }
  ))

  const activationTerminationConditions = (entry: ClientReportEntry) => {
    switch (activationFilter) {
      case ActivationFilterType.All:
        return entry;
      case ActivationFilterType.Active:
        // wholesale logic
        if (isWholesale && (!entry.TerminationDate || entry.TerminationDate.includes("S")) && !entry.SuspensionDate) {
          return entry;
        }

        if (!isWholesale && !entry.TerminationDate && !entry.SuspensionDate) {
          return entry;
        }
        break;

      case ActivationFilterType.Suspended:
        if (!entry.TerminationDate && entry.SuspensionDate) {
          return entry;
        }
        break;

      case ActivationFilterType.Terminated:
        // wholesale logic
        if (isWholesale && entry.TerminationDate && entry.TerminationDate.includes("T")) {
          return entry;
        }

        if (!isWholesale && entry.TerminationDate) {
          return entry;
        }
    }
  }

  const filteredClientsReport = clientsReport.filter(activationTerminationConditions).filter(searchConditions).filter(dateConditions)

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const paginationLabel = `${(page * itemsPerPage) + 1} - ${(itemsPerPage * (page + 1))} of ${filteredClientsReport.length}`;
  const numberOfPages = (Math.floor(clientsReport.length / itemsPerPage) + 1);
  const reportLength = filteredClientsReport.length;
  const pageOptions = [
    5,
    10,
    20,
    reportLength
  ];

  const perPageItems = pageOptions.map((page) => (
    { label: `${page}/page`, value: page }
  ))

  const onPressX = () => {
    setFromMonth('01');
    setFromYear('2020');
    setToMonth(parsedMonth);
    setToYear(parsedYear);
    setSearchTerms('');
  }

  useEffect(() => {
    const getClientsReportCanada = async () => {
      var response = await partnerService.getActivationsCanada({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        },
        params: {
          startDate: "2000-01-01",
          endDate: "3000-01-01"
        }
      })

      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setCanadaClients(responseData));
        dispatch(setCanadaClientsReport());
      }
    }

    const getClientsReportUSA = async () => {
      const response = await representativeService.getPartnerClientReportUS({
        data: {
          i_rep: user.userInfo.i_rep,
          act_data_from: "2000-01-01",
          act_data_to: "3000-01-01"
        }
      })

      if (response && response.data) {
        let responseData = JSON.stringify(response.data.data);
        let parsedData = JSON.parse(responseData);
        let newUSAClientsReport = [] as ClientReportEntry[];

        parsedData.forEach((entry: any) => {
          newUSAClientsReport.push({
            ActivationDate: entry.activationDate?.slice(0, 10),
            Name: entry.customerName,
            Email: entry.email,
            Phonenumber: entry.phoneNumber,
            PlanPrice: entry.planPrice,
            PlanCapacity: entry.planCapacity,
            TerminationDate: entry.terminationDate?.slice(0, 10),
            SuspensionDate: entry.suspensionDate?.slice(0, 10),
            simNum: entry.simNumber,
            isESim: entry.eSIM.toLowerCase() === "yes" ? "E" : "P",
            PlanType: entry.planType
          })
        })
        dispatch(setUSAClientsReport(newUSAClientsReport));
      }
    }

    if (country === CountryType.Canada) {
      getClientsReportCanada().catch(err => { console.error(err) });
    } else if (country === CountryType.USA && user.userInfo.i_rep) {
      getClientsReportUSA().catch(err => { console.error(err) });
    } else {
      console.log("no i_rep");
    }
  }, [country]);

  const wideViewMargin = dimensions.width > ResponsiveWidths.xl ? 80 : horizontalContainerMargin;

  const onChangeItemsPerPage = (itemValue: number) => {
    setItemsPerPage(+itemValue)
  }

  const onChangePage = (page: number) => {
    if (itemsPerPage === reportLength && page > 0) {
      return;
    }
    setPage(page);
  }

  const copyToClipboard = async (textToCopy: string) => {
    await Clipboard.setStringAsync(textToCopy);

    dispatch(setShowCopiedAbsolute(true));
    setTimeout(() => {
      dispatch(setShowCopiedAbsolute(false));
    }, 1800);
  }

  const MobileClientsReportEntryTD = (props: { width: number, data: string }) => {
    return (
      <TD style={{ minWidth: props.width, maxWidth: props.width }}>
        <Pressable style={{ paddingTop: 16, paddingBottom: 10 }} onPress={() => copyToClipboard(props.data)}>
          <Text numberOfLines={1} style={{ ...TextStyles.paragraph, minWidth: props.width, maxWidth: props.width, textAlign: 'center' }}>
            {props.data}
          </Text>
        </Pressable>
      </TD>
    )
  }

  return (
    <>
      {!isMobile &&
        <View style={{ backgroundColor: Colors.white, padding: 30, borderRadius: 10, marginHorizontal: wideViewMargin }}>
          <View style={{ flex: 1, flexDirection: 50 >= horizontalContainerMargin ? 'column' : 'row', justifyContent: 'space-between', marginHorizontal: 0 }}>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', paddingBottom: 50 >= horizontalContainerMargin ? Sizes.medium : 0 }}>
              <Text>Search by:</Text>
              <Picker style={{ ...CommonStyles.input, marginHorizontal: Sizes.small }} selectedValue={selectedSearchOption} onValueChange={(itemValue: keyof ClientReportEntry) => setSelectedSearchOption(itemValue)}>
                {searchOptions.map((key, index) => {
                  return (<Picker.Item key={key} label={tableHead[index].title} value={key} />)
                })}
              </Picker>
              <TextInput style={{ ...CommonStyles.input }} onChangeText={setSearchTerms} value={searchTerms}></TextInput>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Text style={{ paddingRight: Sizes.small }}>Dated from</Text>
              <Picker style={{ ...CommonStyles.input, marginHorizontal: Sizes.small, }} selectedValue={fromYear} onValueChange={(itemValue: string) => setFromYear(itemValue)}>
                {PickerDates.years.map((year: string) => {
                  return (<Picker.Item key={year} label={year} value={year} />)
                })}
              </Picker>
              <Picker style={{ ...CommonStyles.input }} selectedValue={fromMonth} onValueChange={(itemValue: string) => setFromMonth(itemValue)}>
                {PickerDates.months.map((month: Month) => {
                  return (<Picker.Item key={month.value} label={month.label} value={month.value} />)
                })}
              </Picker>
              <Text style={{ paddingHorizontal: Sizes.small }}>to</Text>
              <Picker style={{ ...CommonStyles.input, marginHorizontal: Sizes.small, }} placeholder={'Year'} selectedValue={toYear} onValueChange={(itemValue: string) => setToYear(itemValue)}>
                {PickerDates.years.map((year: string) => {
                  return (<Picker.Item key={year} label={year} value={year} />)
                })}
              </Picker>
              <Picker style={{ ...CommonStyles.input }} selectedValue={toMonth} onValueChange={(itemValue: string) => setToMonth(itemValue)}>
                {PickerDates.months.map((month: Month) => {
                  return (<Picker.Item key={month.value} label={month.label} value={month.value} />)
                })}
              </Picker>
              <Pressable onPress={onPressX}>
                <Feather name="x-circle" size={20} style={{ paddingLeft: Sizes.medium }}></Feather>
              </Pressable>
            </View>
          </View>
          <DataTable style={{ paddingTop: Sizes.small }}>
            <View style={{ marginHorizontal: 0 }}>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ paddingRight: Sizes.medium }}>Total: {filteredClientsReport.length}</Text>
                  <Picker style={{ width: 90, height: 30 }} selectedValue={itemsPerPage} onValueChange={(itemValue: number) => onChangeItemsPerPage(itemValue)}>
                    {pageOptions.map((page) => {
                      return (<Picker.Item key={page} label={`${page}/page`} value={page} />)
                    })}
                  </Picker>
                </View>
                <DataTable.Pagination
                  page={page}
                  numberOfPages={numberOfPages}
                  onPageChange={(page) => onChangePage(page)}
                  label={paginationLabel}
                />
                <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
                  <AppButton text='All' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.All ? countryColor : Colors.grey} outline onPress={() => setActivationFilter(ActivationFilterType.All)}></AppButton>
                  <AppGap size={Sizes.small}></AppGap>
                  <AppButton text='Active' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.Active ? countryColor : Colors.grey} outline onPress={() => setActivationFilter(ActivationFilterType.Active)}></AppButton>
                  {!isCanada &&
                    <>
                      <AppGap size={Sizes.small}></AppGap>
                      <AppButton text='Inactive' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.Suspended ? countryColor : Colors.grey} outline onPress={() => setActivationFilter(ActivationFilterType.Suspended)}></AppButton>
                    </>
                  }
                  <AppGap size={Sizes.small}></AppGap>
                  <AppButton text='Terminated' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.Terminated ? countryColor : Colors.grey} outline onPress={() => setActivationFilter(ActivationFilterType.Terminated)}></AppButton>
                </View>
              </View>
              <View style={{ marginBottom: Sizes.small, flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Text style={{ ...TextStyles.paragraph }}>P = Physical SIM</Text>
                <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>E = eSIM</Text>
                <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>CP = SimCP</Text>
                <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>OT = One Time</Text>
              </View>
              {!isCanada &&
                <>
                  <View style={{ marginBottom: Sizes.small, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ ...TextStyles.paragraph }}>Inactive:  The account can reactivate the service within 90 days from the inactive date.</Text>
                  </View>
                  <View style={{ marginBottom: Sizes.large, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ ...TextStyles.paragraph }}>Terminated: The account is formally closed.</Text>
                  </View>
                </>
              }
            </View>
            <View style={{ minWidth: "100%", maxWidth: "100%", overflow: "scroll" }}>
              <DataTable.Header style={{ backgroundColor: lightCountryColor, minWidth: "100%", width: tableHeadWidth + 42, padding: 0, flex: 1, flexDirection: "row", justifyContent: "space-between" }}>
                {tableHead.map(head => {
                  return (
                    <DataTable.Title
                      key={head.title}
                      style={{
                        minWidth: head.width,
                        maxWidth: head.width,
                      }}
                      textStyle={{ color: countryColor, fontFamily: FontFamilys.bold, fontSize: 12, textAlign: "center", minWidth: head.width, maxWidth: head.width }}
                    >
                      {head.title}
                    </DataTable.Title>
                  )
                })}
              </DataTable.Header>
              {filteredClientsReport.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((entry, index) => {
                return (
                  <Table style={{ borderBottomWidth: 1, borderColor: Colors.lightGrey }} key={index}>
                    <TBody style={{ width: tableHeadWidth }}>
                      <TR style={{ maxWidth: "100%", alignItems: 'center', justifyContent: 'flex-start' }}>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 120, maxWidth: 120, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 120, maxWidth: 120, textAlign: "center" }} >{entry.ActivationDate}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 150, maxWidth: 150, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 150, maxWidth: 150, textAlign: "center" }}>{entry.Name}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 300, maxWidth: 300, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 300, maxWidth: 300, textAlign: "center" }}>{entry.Email}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 150, maxWidth: 150, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 150, maxWidth: 150, textAlign: "center" }}>{entry.Phonenumber}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 100, maxWidth: 100, textAlign: "center" }}>{entry.PlanPrice}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 100, maxWidth: 100, textAlign: "center" }}>{entry.PlanCapacity}</Text></TD>
                        {!isCanada &&
                          <TD style={{ paddingVertical: 10, minWidth: 150, maxWidth: 150, textAlign: 'center' }}><Text numberOfLines={1} style={{ ...TextStyles.paragraph, minWidth: 120, maxWidth: 120, textAlign: 'center' }}>{entry.SuspensionDate}</Text></TD>
                        }
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 120, maxWidth: 120, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 120, maxWidth: 120, textAlign: "center" }}>{entry.TerminationDate}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 200, maxWidth: 200, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 200, maxWidth: 200, textAlign: "center" }}>{entry.simNum}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 100, maxWidth: 100, textAlign: "center" }}>{entry.isESim}</Text></TD>
                        <TD style={{ paddingTop: 10, paddingBottom: 10, minWidth: 100, maxWidth: 100, textAlign: 'center' }}><Text style={{ ...TextStyles.paragraph, minWidth: 100, maxWidth: 100, textAlign: "center" }}>{entry.PlanType}</Text></TD>
                        {isReportHasCnumClient &&
                          <TD style={{ paddingVertical: 10, minWidth: 150, maxWidth: 150, textAlign: 'center' }}><Text numberOfLines={1} style={{ ...TextStyles.paragraph, minWidth: 120, maxWidth: 120, textAlign: 'center' }}>{entry.CNum}</Text></TD>
                        }
                      </TR>
                    </TBody>
                  </Table>
                )
              })}
            </View>
          </DataTable>
        </View>
      }
      {isMobile &&
        <>
          <View style={{ paddingHorizontal: 20, zIndex: 2000 }}>
            <View style={{ flex: 1, alignItems: 'center', marginBottom: 15, zIndex: 2000 }}>
              <View style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
                <Text style={{ flex: 1, fontFamily: FontFamilys.regular, fontSize: 12, color: Colors.white }}>Dated from:</Text>
                <Text style={{ flex: 1, fontFamily: FontFamilys.regular, fontSize: 12, color: Colors.white, paddingLeft: '7%' }}>To:</Text>
                <Pressable onPress={onPressX}>
                  <Ionicons name="refresh-circle-outline" size={20} style={{ color: Colors.white }} />
                </Pressable>
              </View>
              <View style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
                <View style={{ flexDirection: 'row', width: '49%' }}>
                  <View style={{ flex: 1 }}>
                    <DropDown value={fromYear} items={yearItems} setValue={setFromYear} placeholder={fromYear} />
                  </View>
                  <View style={{ flex: 1 }}>
                    <DropDown value={fromMonth} items={monthItems} setValue={setFromMonth} placeholder={fromMonth} />
                  </View>
                </View>
                <View style={{ flexDirection: 'row', width: '49%', marginLeft: '2%' }}>
                  <View style={{ flex: 1 }}>
                    <DropDown value={toYear} items={yearItems} setValue={setToYear} placeholder={toYear} />
                  </View>
                  <View style={{ flex: 1 }}>
                    <DropDown value={toMonth} items={monthItems} setValue={setToMonth} placeholder={toMonth} />
                  </View>
                </View>
              </View>
            </View>
            <View style={{ flex: 1, marginBottom: Sizes.large, zIndex: 1000 }}>
              <Text style={{ fontFamily: FontFamilys.regular, fontSize: 12, color: Colors.white, marginVertical: 5, }}>Search By:</Text>
              <View style={{ flexDirection: 'row', flex: 1, width: '100%' }}>
                <View style={{ width: '49%', marginRight: '2%' }}>
                  <DropDown value={selectedSearchOption} items={searchOptionsItems} setValue={setSelectedSearchOption} />
                </View>
                <TextInput style={{ ...CommonStyles.input, flex: 1, borderColor: Colors.white, color: Colors.white, width: '49%' }} onChangeText={setSearchTerms} value={searchTerms}></TextInput>
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-start', marginTop: 5 }}>
              <AppButton text='All' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.All ? countryColor : Colors.grey} onPress={() => setActivationFilter(ActivationFilterType.All)}></AppButton>
              <AppGap size={Sizes.small}></AppGap>
              <AppButton text='Active' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.Active ? countryColor : Colors.grey} onPress={() => setActivationFilter(ActivationFilterType.Active)}></AppButton>
              <AppGap size={Sizes.small}></AppGap>
              <AppButton text='Terminated' shape='rect' size='sm' textSize={12} color={activationFilter === ActivationFilterType.Terminated ? countryColor : Colors.grey} onPress={() => setActivationFilter(ActivationFilterType.Terminated)}></AppButton>
            </View>
          </View>
          <View style={{ backgroundColor: Colors.white, marginTop: 40 }}>
            <DataTable style={{ paddingVertical: Sizes.large }}>
              <View style={{ paddingLeft: 20, zIndex: 500, marginBottom: Sizes.medium }}>
                <View style={{ flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Text style={{ ...TextStyles.paragraph }}>Total: {filteredClientsReport.length}</Text>
                  <View style={{ flexDirection: 'row', paddingRight: 20 }}>
                    <Text style={{ ...TextStyles.paragraph }}>P= Physical SIM</Text>
                    <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.medium }}>E= eSIM</Text>
                  </View>
                </View>
                <View style={{ zIndex: 500, flexDirection: 'row', flex: 1, width: '100%', alignItems: 'center', justifyContent: 'space-between', paddingTop: 5 }}>
                  <View style={{ width: '33%' }}>
                    <DropDown value={itemsPerPage} items={perPageItems} setValue={setItemsPerPage} placeholder={`${itemsPerPage}/page`} minHeight={30} />
                  </View>
                  <DataTable.Pagination
                    page={page}
                    numberOfPages={numberOfPages}
                    onPageChange={(page) => setPage(page)}
                    label={paginationLabel}
                    style={{ width: '66%' }}
                  />
                </View>
              </View>
              <ScrollView horizontal={filteredClientsReport.length !== 0 ? true : false} >
                <View style={{ borderRadius: 10, backgroundColor: Colors.white, padding: 10 }}>
                  {filteredClientsReport.length === 0 ?
                    <Text style={{ padding: 20, width: '100%', ...TextStyles.paragraph, textAlign: 'center', color: countryColor, backgroundColor: Colors.lightBlue }}>No results found</Text>
                    :
                    <Table style={{ backgroundColor: lightCountryColor, width: tableHeadWidth }}>
                      <TBody style={{ width: tableHeadWidth }}>
                        <TR style={{ maxWidth: tableHeadWidth }}>
                          {tableHead.map(head => {
                            return (
                              <TD key={head.title} style={{ minWidth: head.width, maxWidth: head.width, textAlign: "center", color: countryColor, paddingVertical: 12, fontSize: 12, fontFamily: FontFamilys.semiBold }}>
                                {head.title}
                              </TD>
                            )
                          })}
                        </TR>
                      </TBody>
                    </Table>
                  }
                  {filteredClientsReport.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map((entry, index) => {
                    return (
                      <Table style={{ borderBottomWidth: 1, borderColor: Colors.lightGrey }} key={index}>
                        <TBody style={{ width: tableHeadWidth }}>
                          <TR style={{ maxWidth: tableHeadWidth, alignItems: 'center', justifyContent: 'flex-start' }}>
                            <MobileClientsReportEntryTD width={120} data={entry.ActivationDate} />
                            <MobileClientsReportEntryTD width={150} data={entry.Name} />
                            <MobileClientsReportEntryTD width={300} data={entry.Email} />
                            <MobileClientsReportEntryTD width={150} data={entry.Phonenumber} />
                            <MobileClientsReportEntryTD width={100} data={entry.PlanPrice} />
                            <MobileClientsReportEntryTD width={100} data={entry.PlanCapacity} />
                            {!isCanada && entry.SuspensionDate &&
                              <MobileClientsReportEntryTD width={150} data={entry.SuspensionDate} />
                            }
                            <MobileClientsReportEntryTD width={120} data={entry.TerminationDate} />
                            <MobileClientsReportEntryTD width={200} data={entry.simNum} />
                            <MobileClientsReportEntryTD width={100} data={entry.isESim} />
                            <MobileClientsReportEntryTD width={100} data={entry.PlanType} />
                            {isReportHasCnumClient && entry.CNum &&
                              <MobileClientsReportEntryTD width={150} data={entry.CNum} />
                            }
                          </TR>
                        </TBody>
                      </Table>
                    )
                  })}
                </View>
              </ScrollView>
            </DataTable>
          </View>
          <AppGap size={100} direction={'vertical'} />
        </>
      }
    </>
  )
}