import { View } from 'react-native';
import { AppRow } from './AppRow';

type AppContainerProps = {
  children: React.ReactElement<typeof AppRow>[] | React.ReactElement<typeof AppRow>;
  width?: number | string;
}

export function AppContainer(props: AppContainerProps) {
  return (<View style={{ flex: 1, marginHorizontal: 'auto', width: !props.width ? '100%' : props.width }}>{props.children}</View>);
}
