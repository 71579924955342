import { Newsletter } from "../types/models/Newsletter"

export default [
  {
    title: 'May 2024',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_May2024.html',
    highlights: [
      'Assisting Clients in Troubleshooting',
      'Refund Process Updated',
      'Upgraded International Calling Service!'
    ]
  },
  {
    title: 'April 2024',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_April2024.html',
    highlights: [
      'Let’s Celebrate! PhoneBox Completes 13 Years!',
      'Customized Affiliate Links in Your Language!',
      'Join us at ICEF and CAPS-I this May!'
    ]
  },
  {
    title: 'March 2024',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_March_2024.html',
    highlights: [
      'New Unlimited Plan Available in Our Latest Plan Set!',
      'Partner Raffle Alert: Win a $20 Gift Card by March 29th!',
      'CAPS-I Trade Mission: See you in Hong Kong'
    ]
  },
  {
    title: 'February 2024',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_February2024.html',
    highlights: [
      'Revolutionize Your Partnership with PhoneBox: Introducing the PhoneBox Partner App!',
      'Join Us at CAPS-I Trade Mission Brazil',
      'Important Update: Refund Payment Method Policy'
    ]
  },
  {
    title: 'January 2024',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_January2024.html',
    highlights: [
      'Global Connectivity, Local Excellence: PhoneBox\'s New Unlimited Calling Plan',
      'Enhanced Partner Experience: SIM card order',
      'Trusted Transactions: PhoneBox is now PCI Compliance'
    ]
  },
  {
    title: 'December 2023',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_December2023.html',
    highlights: [
      'Here\'s the PhoneBox Christmas gift: New amazing plans are available now!',
      'Important Notice: Old Affiliate Link not available after January 3rd',
      'Our Latest Website Security Effort',
      'Expressing Gratitude: Celebrating a Year of Collaborative Achievements'
    ]
  },
  {
    title: 'November 2023',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_November2023.html',
    highlights: [
      'New Plan Set with $5 off for New Clients! For a Limited time only!',
      'Welcome to our 90-day Plan',
      'Prepaid eSIM only affiliate link available now!'
    ]
  },
  {
    title: 'October 2023',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Promotion/Partners_Newsletter_October2023.html',
    highlights: [
      'PhoneBox USA New Feature: International Calls',
      'One more country available for Canada International Calls: Spain!',
      'Confirm if your student’s Canada plan has International Calls available!'
    ]
  },
  {
    title: 'September 2023',
    link: 'https://phonebox.azurewebsites.net/Newsletters/Notification/Partners_Newsletter_September.html',
    highlights: [
      'LIMITED TIME OFFER: The best deal plans!',
      'Summer Orientations have ended!',
      'New Partner Portal Feedback'
    ]
  },
  {
    title: 'August 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partners_Newsletter_August2023.html',
    highlights: [
      'August - September Orientations',
      'Unsubscribe Option for Client’s Newsletters!',
      'New Weekly Plan for the United States!'
    ]
  },
  {
    title: 'July 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partners_Newsletter_July2023.html',
    highlights: [
      'A New Partner Portal for you!',
      'Download the eSIM Instructions!',
      'Follow PhoneBox USA on Instagram!'
    ]
  },
  {
    title: 'May 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partner_Newsletter_May2023.html',
    highlights: [
      'Fall Intake is comping up!',
      'Coverage Map Available for the USA!',
      'Meet us at NAFSA next week!'
    ]
  },
  {
    title: 'April 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partner_Newsletter_April2023.html',
    highlights: [
      'PhoneBox grows southward! Say Hello to PhoneBox USA!',
      'Tips for Partners on PhoneBox Blog!',
      'Meet us at ICEF in Vancouver!'
    ]
  },
  {
    title: 'March 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partner_Newsletter_March2023.html',
    highlights: [
      'New Partner Portal made especially for you!',
      'IMPORTANT REMINDER: No one-time manual payment allowed after March 31st',
      'Meet our team in person at CAPS-I in Korea!'
    ]
  },
  {
    title: 'February 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partner_Newsletter_February2023.html',
    highlights: [
      "New United States Services",
      "Google rates fast increase",
      "Welcome Japanese Students!"
    ]
  },
  {
    title: 'January 2023',
    link: 'https://phonebox.azurewebsites.net/NewsLetters/Notification/Promotion/Partner_Newsletter_January2023.html',
    highlights: [
      "Protect your clients, and Stop the scam",
      "Changes To Payment Processing Practices",
      "ICEF South Asia 2023"
    ]
  },
] as Array<Newsletter>