import { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import ResponsiveWidths from '../constants/ResponsiveWidths';
import { MarginType } from '../types/enums/MarginType';

export default function useHorizontalContainerMargin(prop?: MarginType) {
  const [width, setWidth] = useState(0);
  const dimensions = useWindowDimensions();

  useEffect(() => {
    if (prop === MarginType.Login) {
      if (dimensions.width < ResponsiveWidths.md) {
        setWidth(0)
        return;
      }
      if (dimensions.width < ResponsiveWidths.lg) {
        setWidth(80);
        return;
      }
      if (dimensions.width < ResponsiveWidths.xl) {
        setWidth(160);
        return;
      }
      if (dimensions.width < ResponsiveWidths.xxl) {
        setWidth(250);
        return
      }
      setWidth(300);
      return
    }
    if (dimensions.width < ResponsiveWidths.md) {
      setWidth(20)
      return;
    }
    if (dimensions.width < ResponsiveWidths.lg) {
      setWidth(30);
      return;
    }
    if (dimensions.width < ResponsiveWidths.xl) {
      setWidth(50);
      return;
    }
    if (dimensions.width < ResponsiveWidths.xxl) {
      setWidth(150);
      return;
    }
    setWidth(200);

  }, [dimensions.width]);

  return width;
}
