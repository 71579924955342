import { Platform, Image } from 'react-native';
import LogoMiniWhite from "../assets/images/logo_mini_white.svg";
import LogoMiniBlue from "../assets/images/logo_mini_blue.svg";
import useIsMobile from '../hooks/useIsMobile';

type AppLogoSvgIconProps = {}

export function AppLogoSvgIcon(props: AppLogoSvgIconProps) {
  const isMobile = useIsMobile();
  return (
    <>
      <Image source={require('../assets/images/logo_mini_blue.svg')} style={{ width: 100, height: 100 }} />
      {/* {Platform.OS !== 'web' && (isMobile ? <LogoMiniWhite width={100} height={100} /> : <LogoMiniBlue width={100} height={100} />)} */}
    </>
  );
}