import { ScrollView, View, Text, SafeAreaView, Platform } from "react-native"
import useIsMobile from "../hooks/useIsMobile"
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import Heights from "../constants/Heights";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { CountryType } from "../types/enums/CountryType";
import Colors from "../constants/Colors";
import Sizes from "../constants/Sizes";
import { MyInformation } from '../components/MyInformation';
import { AppGap } from "../components/AppGap";
import { AppLabel } from "../components/AppLabel";
import { AppFooter } from "../components/AppFooter";
import ScheduledActivationReport from "../components/ScheduledActivationReport";
import TextStyles from "../constants/TextStyles";
import PageLayoutStyles from "../constants/PageLayoutStyles";
import { FontAwesome } from '@expo/vector-icons';


export default function ScheduledActivationReportScreen() {
	const user = useSelector((state: RootState) => state.user);
	const isMobile = useIsMobile();
	const marginHorizontal = useHorizontalContainerMargin();
	const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;

	return (
		<>
			{!isMobile &&
				<>
					<ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
						<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
							<View style={{ flex: 1 }}>
								<View style={{ marginTop: Sizes.large }}>
									<MyInformation />
									<AppGap size={Sizes.large} direction={'vertical'} />
									<AppLabel color={countryColor} text='Scheduled Activation Report'></AppLabel>
									<AppGap size={Sizes.large} direction={'vertical'} />
									<ScheduledActivationReport />
									<AppGap size={200} direction={'vertical'}></AppGap>
								</View>
							</View>
						</View>
					</ScrollView>
					<AppFooter />
				</>
			}
			{isMobile &&
				<ScrollView style={{ ...PageLayoutStyles.reportContainer, flex: 1 }}>
					<View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', }}>
						<View style={{ flex: 1 }}>
							<View style={{ paddingHorizontal: marginHorizontal }}>
								<Text style={{ ...TextStyles.title, color: Colors.white, paddingBottom: Sizes.large }}>Reports</Text>
								<Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Scheduled Activation Report</Text>
							</View>
							<View style={{ marginTop: Sizes.large }}>
								<ScheduledActivationReport />
								<AppGap size={100} direction={'vertical'}></AppGap>
							</View>
						</View>
					</View>
				</ScrollView>
			}
		</>
	)
}
