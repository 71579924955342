import { proxyInstance } from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { GetXdrListRequest } from "../types/requests/GetXdrListRequest";
import { GetXdrListResponse } from "../types/responses/GetXdrListResponse";
import { GetPartnerCommissionUSRequest } from "../types/requests/GetPartnerCommissionUSRequest";
import { GetPartnerCommissionUSResponse } from "../types/responses/GetPartnerCommissionUSResponse";
import { CountActiveReferralsRequest } from "../types/requests/CountActiveReferralsRequest";
import { CountActiveReferralsResponse } from "../types/responses/CountActiveReferralsResponse";
import { GetPartnerClientReportUSRequest } from "../types/requests/GetPartnerClientReportUSRequest";
import { GetPartnerClientReportUSResponse } from "../types/responses/GetPartnerClientReportUSResponse";
import { GetPartnerScheduledActivationsReportUSRequest } from "../types/requests/GetPartnerScheduledActivationsReportUSRequest";
import { GetPartnerScheduledActivationsReportUSResponse } from "../types/responses/GetPartnerScheduledActivationsReportUSResponse";

export const representativeService = {
  getCardList: async (request: ApiRequest<GetXdrListRequest>): Promise<ApiResponse<GetXdrListResponse>> => await proxyInstance.post<ApiRequest<GetXdrListRequest>, ApiResponse<GetXdrListResponse>>('/p1/Representative/get_xdr_list', request),
  getPartnerCommissionUS: async (request: ApiRequest<GetPartnerCommissionUSRequest>): Promise<ApiResponse<GetPartnerCommissionUSResponse>> => await proxyInstance.post<ApiRequest<GetPartnerCommissionUSRequest>, ApiResponse<GetPartnerCommissionUSResponse>>('/p1/Representative/get_partner_commission_us', request),
  countActiveReferrals: async (request: ApiRequest<CountActiveReferralsRequest>): Promise<ApiResponse<CountActiveReferralsResponse>> => await proxyInstance.post<ApiRequest<CountActiveReferralsRequest>, ApiResponse<CountActiveReferralsResponse>>('/p1/Representative/count_active_referrals', request),
  getPartnerClientReportUS: async (request: ApiRequest<GetPartnerClientReportUSRequest>): Promise<ApiResponse<GetPartnerClientReportUSResponse>> => await proxyInstance.post<ApiRequest<GetPartnerClientReportUSRequest>, ApiResponse<GetPartnerClientReportUSResponse>>('/p1/Representative/get_partner_clients_report_us', request), 
  getPartnerScheduledActivationsReportUS: async (request: ApiRequest<GetPartnerScheduledActivationsReportUSRequest>): Promise<ApiResponse<GetPartnerScheduledActivationsReportUSResponse>> => await proxyInstance.post<ApiRequest<GetPartnerScheduledActivationsReportUSRequest>, ApiResponse<GetPartnerScheduledActivationsReportUSResponse>>('/p1/Representative/get_partner_scheduled_active_report_us', request), 
}