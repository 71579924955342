import { View, Text, ScrollView, Image, Pressable, ImageBackground, Platform } from "react-native";
import { RootStackScreenProps } from "../types";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import Heights from "../constants/Heights";
import Sizes from "../constants/Sizes";
import { MyInformation } from "../components/MyInformation";
import { AppGap } from "../components/AppGap";
import TextStyles from "../constants/TextStyles";
import Colors from "../constants/Colors";
import { CountryType } from "../types/enums/CountryType";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AppFooter } from "../components/AppFooter";
import CommonStyles from "../constants/CommonStyles";
import useIsMobile from "../hooks/useIsMobile";
import FontFamilys from "../constants/FontFamilys";
import * as WebBrowser from 'expo-web-browser';


export default function VideosScreen({navigation}: RootStackScreenProps<'Videos'>) {
  const isMobile = useIsMobile();
  const user = useSelector((state: RootState) => state.user);
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const backgroundColor = user.country === CountryType.Canada ? Colors.blueOverlay : Colors.greenOverlay;

  const videoLinks = user.country === CountryType.Canada ? 
    {
      howToActivate: 'https://www.youtube.com/watch?v=VB_Vqe2taGs&t=14s',
      guidelines: 'https://phonebox.oopy.io/',
      support: 'https://gophonebox.com/support'
    } : {
      howToActivate: 'https://www.youtube.com/watch?v=5UkHBvLdhvg&t=4s',
      onlineSelfServe: 'https://www.youtube.com/watch?v=laG8GxLWtOw&t=3s',
      guidelines: 'https://www.phonebox-us.com/',
      support: 'https://gophonebox.com/support?support=us'
    }

  const openLink = (link: string) => {
    if(Platform.OS === 'web') {
      window.open(link);
    } else {
      WebBrowser.openBrowserAsync(link);
    }
  }
  
  return (
    <>
      {!isMobile &&
        <>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large }}>
                  <MyInformation />
                  <AppGap direction='vertical' size={Sizes.large}></AppGap>
                  <View style={{ flex: 1, flexDirection: 'row' }}>
                    <View>
                      <View>
                        <Text style={{ ...TextStyles.subtitle, color: Colors.darkBlue }}>Learning Center</Text>
                        <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>Learn more about our process here and help your clients out.</Text>
                      </View>
                      <AppGap direction='vertical' size={Sizes.large}></AppGap>
                      <View style={{ flex: 1, flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                        <View style={{ width: '50%' }}>
                          <Text style={{ ...TextStyles.subtitle, color: countryColor, textAlign: 'center' }}>Videos</Text>
                          <ImageBackground source={require('../assets/images/how-to-activate.png')} style={{ margin: Sizes.medium }}>
                            <Pressable onPress={() => openLink(videoLinks.howToActivate)} style={{ ...CommonStyles.card, height: 130, width: 230, backgroundColor: backgroundColor }}>
                              <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>How to Activate</Text>
                            </Pressable>
                          </ImageBackground>
                          {videoLinks.onlineSelfServe &&
                            <ImageBackground source={require('../assets/images/self-serve.png')} style={{ margin: Sizes.medium }}>
                              <Pressable onPress={() => openLink(videoLinks.onlineSelfServe)} style={{ ...CommonStyles.card, height: 130, width: 230, backgroundColor: backgroundColor }}>
                                <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>Online Self-Serve</Text>
                              </Pressable>
                            </ImageBackground>
                          }
                        </View>
                        <View style={{ width: '50%' }}>
                          <Text style={{ ...TextStyles.subtitle, color: countryColor, textAlign: 'center' }}>Written Materials</Text>
                          <ImageBackground source={require('../assets/images/basic-info.png')} style={{ margin: Sizes.medium }}>
                            <Pressable onPress={() => openLink(videoLinks.guidelines)} style={{ ...CommonStyles.card, height: 130, width: 230, backgroundColor: backgroundColor }}>
                              <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>Guidelines</Text>
                            </Pressable>
                          </ImageBackground>
                          <ImageBackground source={require('../assets/images/support.png')} style={{ margin: Sizes.medium }}>
                            <Pressable onPress={() => openLink(videoLinks.support)} style={{ ...CommonStyles.card, height: 130, width: 230, backgroundColor: backgroundColor }}>
                              <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>Support</Text>
                            </Pressable>
                          </ImageBackground>
                        </View>
                      </View>
                    </View>
                    <View style={{ marginLeft: 'auto' }}>
                      <Image source={require('../assets/images/news.png')} style={{ width: 244, height: 367 }}></Image>
                    </View>
                  </View>
                </View>
                <AppGap size={100} direction={'vertical'}></AppGap>
              </View>
            </View>
          </ScrollView>
          <AppFooter/>
        </>
      }
      {isMobile &&
        <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
          <View style={{ flex: 1 }}>
            <Text style={{ ...TextStyles.title, color: Colors.white }}>Learning Center</Text>
            <AppGap direction='vertical' size={Sizes.medium}></AppGap>
            <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16 }}>Learn more about our process here and help your clients out.</Text>
            <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
            <View>
              <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Videos</Text>
              <ImageBackground source={require('../assets/images/how-to-activate.png')} style={{ margin: Sizes.medium }} imageStyle={{ borderRadius: 10 }}>
                <Pressable onPress={() => openLink(videoLinks.howToActivate)} style={{ ...CommonStyles.card, height: 130, backgroundColor: backgroundColor }}>
                  <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>How to Activate</Text>
                </Pressable>
              </ImageBackground>
              {videoLinks.onlineSelfServe &&
                <ImageBackground source={require('../assets/images/self-serve.png')} style={{ margin: Sizes.medium }} imageStyle={{ borderRadius: 10 }}>
                  <Pressable onPress={() => openLink(videoLinks.onlineSelfServe)} style={{ ...CommonStyles.card, height: 130, backgroundColor: backgroundColor }}>
                    <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>Online Self-Serve</Text>
                  </Pressable>
                </ImageBackground>
              }
            </View>
            <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
            <View>
              <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Written Materials</Text>
              <ImageBackground source={require('../assets/images/basic-info.png')} style={{ margin: Sizes.medium }} imageStyle={{ borderRadius: 10 }}>
                <Pressable onPress={() => openLink(videoLinks.guidelines)} style={{ ...CommonStyles.card, height: 130, backgroundColor: backgroundColor }}>
                  <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>Guidelines</Text>
                </Pressable>
              </ImageBackground>
              <ImageBackground source={require('../assets/images/support.png')} style={{ margin: Sizes.medium }} imageStyle={{ borderRadius: 10 }}>
                <Pressable onPress={() => openLink(videoLinks.support)} style={{ ...CommonStyles.card, height: 130, backgroundColor: backgroundColor }}>
                  <Text style={{ margin: 'auto', color: Colors.white, ...TextStyles.semiSubtitle }}>Support</Text>
                </Pressable>
              </ImageBackground>
            </View>
            </View>
          </View>
          <AppGap size={100} direction={'vertical'}></AppGap>
      </ScrollView>
      
      }
    </>
  )
}