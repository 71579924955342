import { View, Text, ScrollView, Pressable, Image, useWindowDimensions } from "react-native";
import { AppButton } from "./AppButton";
import { useNavigation } from "@react-navigation/native";
import { useDispatch } from "react-redux";
import { setCountry, setLoggedIn } from "../store/user";
import { AppDispatch } from "../store";
import { CountryType } from "../types/enums/CountryType";
import { AppHeader } from "./AppHeader";
import PageLayoutStyles from "../constants/PageLayoutStyles";
import Heights from "../constants/Heights";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { AppContainer } from "./AppContainer";
import { AppRow } from "./AppRow";
import { AppColumn } from "./AppColumn";
import CommonStyles from "../constants/CommonStyles";
import Colors from "../constants/Colors";
import { RadioButton } from "react-native-paper";
import Sizes from "../constants/Sizes";
import { tokenService } from "../services/TokenService";
import TextStyles from "../constants/TextStyles";
import { FontAwesome } from '@expo/vector-icons';
import { MarginType } from "../types/enums/MarginType";
import { useState } from "react";
import { AppGap } from "./AppGap";
import useIsMobile from "../hooks/useIsMobile";
import { AppAlert } from "./AppAlert";
import FontFamilys from "../constants/FontFamilys";

type CountrySelectComponentProps = {
  canadaOnly: boolean;
  usaOnly: boolean;
  noCountry: boolean;
}

export default function CountrySelectComponent(props: CountrySelectComponentProps) {
  
  const isMobile = useIsMobile();
  const dispatch = useDispatch<AppDispatch>();
  const navigation = useNavigation();
  const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);
  const dimensions = useWindowDimensions();
  const [checked, setChecked] = useState<CountryType>(CountryType.None);
  const [selectErrorMsg, setSelectErrorMsg] = useState('');

  const handlePressHome = () => {
    dispatch(setCountry(checked));
    navigation.navigate('Root');

    if(checked === CountryType.None) {
      setSelectErrorMsg('Please select a country.');
    }
  }

  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();       
      dispatch(setLoggedIn(false));
      dispatch(setCountry(CountryType.None));
    }
    logout().catch(err => console.error(err));
  }

  return (
    <>
      {!isMobile && 
        <>
          <AppHeader onlyLogo={true}></AppHeader>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
            <View style={{ ...PageLayoutStyles.container, marginHorizontal: marginHorizontal }}>
              <View style={{ ...CommonStyles.card, backgroundColor: Colors.white, padding: 0, borderColor: Colors.darkBlue, borderWidth: 2, width: dimensions.width > 1512 ? 840 : 'auto', marginHorizontal: dimensions.width > 1512 ? 'auto' : 0 }}>
                <AppContainer>
                  <AppRow>
                    <AppColumn>
                      <View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.medium, flex: 1 }}>
                        <Text style={{ ...TextStyles.semiSubtitle, color: Colors.black, fontSize: 24 }}>Country Selection</Text>
                        <View style={{ flex: 1, flexDirection: 'row', paddingVertical: Sizes.extraLarge }}>
                          <View style={{ paddingVertical: Sizes.small, paddingHorizontal: Sizes.medium, alignItems: 'center', borderWidth: 1, borderColor: Colors.lightGrey, borderRadius: 10 }}>
                            <Image 
                              style={{ width: 40, height: 40 }}
                              source={require('../assets/images/canada.png')}
                            />
                            <RadioButton
                              value={CountryType.Canada}
                              status={checked === CountryType.Canada ? 'checked' : 'unchecked'}
                              disabled={props.usaOnly || props.noCountry}
                              onPress={() => setChecked(CountryType.Canada)}
                            />
                          </View>
                          <AppGap direction='horizontal' size={Sizes.large}></AppGap>
                          <View style={{ paddingVertical: Sizes.small, paddingHorizontal: Sizes.medium, alignItems: 'center', borderWidth: 1, borderColor: Colors.lightGrey, borderRadius: 10 }}>
                            <Image 
                              style={{ width: 40, height: 40 }}
                              source={require('../assets/images/us.png')}
                            />
                            <RadioButton
                              value={CountryType.USA}
                              status={checked === CountryType.USA ? 'checked' : 'unchecked'}
                              disabled={props.canadaOnly || props.noCountry}
                              onPress={() => setChecked(CountryType.USA)}
                            />
                          </View>
                        </View>
                        <AppButton text="Next" shape="rect" textSize={16} onPress={handlePressHome}></AppButton>
                      </View>
                      {props.canadaOnly &&  
                        <View>
                          <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>Interested in the US?</Text>
                          <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>Contact your Account Manager!</Text>
                        </View>
                      }
                      {props.usaOnly &&  
                        <View>
                          <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>Interested in Canada?</Text>
                          <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>Contact your Account Manager!</Text>
                        </View>
                      }
                      {props.noCountry &&  
                        <View>
                          <Text style={{ ...TextStyles.paragraph, textAlign: 'center' }}>Please contact your Account Manager.</Text>
                        </View>
                      }
                      <View style={{ marginHorizontal: 'auto', paddingBottom: Sizes.large, paddingTop: Sizes.large }}>
                        <Pressable style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }} onPress={() => handleLogout()}>
                          <FontAwesome name="angle-left" textSize={16}></FontAwesome>
                          <Text style={{ ...TextStyles.semiSubtitle, paddingLeft: Sizes.small }}>Back</Text>
                        </Pressable>
                      </View>
                    </AppColumn>
                  </AppRow>
                </AppContainer>
              </View>
            </View>
        </ScrollView>
        </>
      }
      {isMobile && 
        <>
          <View style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', justifyContent: 'space-between', backgroundColor: Colors.darkBlue }}>
            <View style={{ paddingTop: 65 }}>
              <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, textAlign: "center" }}>Country Selection</Text>
              <View style={{ flex: 1, flexDirection: 'row', paddingVertical: Sizes.extraLarge, justifyContent: 'center' }}>
                <Pressable onPress={() => props.usaOnly || props.noCountry ? '' : setChecked(CountryType.Canada)} style={{ height: 110, width: '25%', paddingVertical: Sizes.large, paddingHorizontal: Sizes.large, alignItems: 'center', borderWidth: 1, borderColor: checked === CountryType.Canada ? Colors.white :  Colors.lightGrey, borderRadius: 10, backgroundColor: Colors.blueOverlay }}>
                  <Image 
                    style={{ width: 45, height: 45, marginBottom: Sizes.small, opacity: props.usaOnly || props.noCountry ? 0.3 : 1}}
                    source={require('../assets/images/canada.png')}
                  />
                  <RadioButton.Android
                    value={CountryType.Canada}
                    status={checked === CountryType.Canada ? 'checked' : 'unchecked'}
                    color="#fff"
                    disabled={props.usaOnly || props.noCountry}
                    onPress={() => setChecked(CountryType.Canada)}
                  />
                </Pressable>
                <AppGap direction='horizontal' size={Sizes.large}></AppGap>
                <Pressable onPress={() => props.canadaOnly || props.noCountry ? '' : setChecked(CountryType.USA)} style={{ height: 110, width: '25%', paddingVertical: Sizes.large, paddingHorizontal: Sizes.large, alignItems: 'center', borderWidth: 1, borderColor: checked === CountryType.USA ? Colors.white : Colors.lightGrey, borderRadius: 10, backgroundColor: Colors.blueOverlay }}>
                  <Image 
                    style={{ width: 45, height: 45, marginBottom: Sizes.small, opacity: props.canadaOnly || props.noCountry ? 0.3 : 1 }}
                    source={require('../assets/images/us.png')}
                  />
                  <RadioButton.Android
                    value={CountryType.USA}
                    status={checked === CountryType.USA ? 'checked' : 'unchecked'}
                    color="#fff"
                    disabled={props.canadaOnly || props.noCountry}
                    onPress={() => setChecked(CountryType.USA)}
                  />
                </Pressable>
              </View>
            </View>
            <View style={{ marginBottom: Sizes.extraLarge }}>
              {props.canadaOnly &&  
                <View style={{ marginBottom: Sizes.large }}>
                  <Text style={{ ...TextStyles.paragraph, textAlign: 'center', color: Colors.white, fontFamily: FontFamilys.bold }}>Interested in the US?</Text>
                  <Text style={{ ...TextStyles.paragraph, textAlign: 'center', color: Colors.white }}>Contact your Account Manager!</Text>
                </View>
              }
              {props.usaOnly &&  
                <View>
                  <Text style={{ ...TextStyles.paragraph, textAlign: 'center', color: Colors.white, fontFamily: FontFamilys.bold }}>Interested in Canada?</Text>
                  <Text style={{ ...TextStyles.paragraph, textAlign: 'center', color: Colors.white }}>Contact your Account Manager!</Text>
                </View>
              }
              {props.noCountry &&  
                <View>
                  <Text style={{ ...TextStyles.paragraph, textAlign: 'center', color: Colors.white, fontFamily: FontFamilys.bold }}>Please contact your Account Manager.</Text>
                </View>
              }
              {props.noCountry ? 
                <View style={{ marginTop: Sizes.medium }}>
                  <AppButton text="Back to Logout" shape="rect" textSize={16} onPress={() => handleLogout()}></AppButton>
                </View>
              :
                <View>
                  <AppButton text="Next" shape="rect" textSize={16} onPress={handlePressHome}></AppButton>
                  {selectErrorMsg.length > 0 &&
                    <View style={{ marginTop: Sizes.medium }}>
                      <AppAlert text={selectErrorMsg}></AppAlert>
                    </View>
                  }
                </View>
              }
            </View>
          </View>
        </>
      }
    </>
  )
}