import React from 'react'
import { useState } from 'react';
import { Text, ScrollView, View, Pressable, useWindowDimensions } from 'react-native';
import { RootStackScreenProps } from '../types';
import useIsMobile from '../hooks/useIsMobile';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import TextStyles from '../constants/TextStyles';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';
import { AppGap } from '../components/AppGap';
import { AppTextInput } from '../components/AppTextInput';
import { AppButton } from '../components/AppButton';
import { partnerService } from '../services/PartnerService';
import { AppContainer } from '../components/AppContainer';
import { AppRow } from '../components/AppRow';
import { AppColumn } from '../components/AppColumn';
import { FontAwesome } from '@expo/vector-icons';
import FontFamilys from '../constants/FontFamilys';
import { AppAlert } from '../components/AppAlert';
import { AppHeader } from '../components/AppHeader';
import CommonStyles from '../constants/CommonStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { MarginType } from '../types/enums/MarginType';
import Heights from '../constants/Heights';

export default function TooManyAttemptsScreen({ navigation }: RootStackScreenProps<'TooManyAttempts'>) {
    const isMobile = useIsMobile()
		const dimensions = useWindowDimensions();
		const marginHorizontal = useHorizontalContainerMargin(MarginType.Login);

  return (
    <>
			{!isMobile && 
			<>
				<AppHeader onlyLogo={true}></AppHeader>
				<ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.scrollViewPaddingTop }}>
					<View style={{ ...PageLayoutStyles.container, marginHorizontal: marginHorizontal }}>
						<View style={{ ...CommonStyles.card, backgroundColor: Colors.white, padding: 0, borderColor: Colors.darkBlue, borderWidth: 2, width: dimensions.width > 1512 ? 840 : 'auto', marginHorizontal: dimensions.width > 1512 ? 'auto' : 0 }}>
							<AppContainer>
								<AppRow>
									<AppColumn>
										<View style={{ alignItems: 'center', justifyContent: 'space-between', padding: Sizes.extraLarge, flex: 1 }}>
											<Text style={{ ...TextStyles.semiSubtitle, color: Colors.black, fontSize: 24 }}>Too Many Attempts</Text>
											<Text style={{ ...TextStyles.paragraph, color: Colors.black, fontSize: 16, marginVertical: Sizes.large }}>This account has been temporarily locked as there have been too many sign-in attempts. Please try again in 30 minutes.</Text>
                      <AppButton text={"Close"} outline color={Colors.black} onPress={() => navigation.navigate('Landing')}/>
										</View>
									</AppColumn>
								</AppRow>
							</AppContainer>
						</View>
					</View>
				</ScrollView>
			</>
			}
			{isMobile &&
				<View style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
					<View>
						<Text style={{ ...TextStyles.title, color: Colors.white }}>Too Many Attempts</Text>
						<View>
							<AppGap direction="vertical" size={Sizes.large}></AppGap>
              <Text style={{ ...TextStyles.paragraph, color: Colors.white, fontSize: 16 }}>This account has been temporarily locked as there have been too many sign-in attempts. Please try again in 30 minutes.</Text>
							<AppGap direction="vertical" size={Sizes.large}></AppGap>
						</View>
					</View>
				</View>
			}
    </>
  )
}
