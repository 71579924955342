import { View, Text, TextInput, ScrollView, NativeSyntheticEvent, TextInputChangeEventData, Pressable, Image, SafeAreaView } from 'react-native';
import { RootStackScreenProps } from '../types';
import { MyInformation } from '../components/MyInformation';
import Sizes from '../constants/Sizes';
import Heights from '../constants/Heights';
import Colors from '../constants/Colors';
import CommonStyles from '../constants/CommonStyles';
import TextStyles from '../constants/TextStyles';
import FontFamilys from '../constants/FontFamilys';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import { AppButton } from '../components/AppButton';
import { AppFooter } from '../components/AppFooter';
import { AppLabel } from '../components/AppLabel';
import { AppGap } from '../components/AppGap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { CountryType } from '../types/enums/CountryType';
import { useEffect, useState } from 'react';
import { PartnerInfo } from '../types/models/PartnerInfo';
import { partnerService } from '../services/PartnerService';
import { setPartnerInfo, setLoggedIn, setCountry } from '../store/user';
import useIsMobile from '../hooks/useIsMobile';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import { tokenService } from "../services/TokenService";
import { MaterialCommunityIcons } from '@expo/vector-icons'; 
import { Feather } from '@expo/vector-icons';


export default function InfoScreen({navigation}: RootStackScreenProps<'Info'>) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const labelWidth = 140;
  const isCanada = user.country === CountryType.Canada
  const countryColor = isCanada ? Colors.blue : Colors.green;
  const profileIconURL = (user.partnerInfo.IconURL !== 'null' && user.partnerInfo.IconURL) ? { uri: user.partnerInfo.IconURL} : require('../assets/images/profile_icon.png');
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editPartnerInfo, setEditPartnerInfo] = useState({
    Bid: 0,
    BusinessName: "",
    City: "",
    ContactPerson: "",
    Country: "",
    Email: "",
    MarketerEmail: "",
    MarketerName: "",
    MarketerPhoneNumber: "",
    PhoneNumber: "",
    Postal: "",
    Province: "",
    Street: "",
    IconURL: "",
    Language1: null,
    Language2: null,
    Language3: null,
    Language4: null,
    AppLaunchMessageMuteDate: "",
  } as PartnerInfo);

  const resetInfo = () => {
    setEditPartnerInfo({
      Bid: parseInt(user.userInfo.bid),
      BusinessName: user.partnerInfo.BusinessName || "",
      City: user.partnerInfo.City || "",
      ContactPerson: user.partnerInfo.ContactPerson || "",
      Country: user.partnerInfo.Country || "",
      Email: user.partnerInfo.Email || "",
      MarketerEmail: user.partnerInfo.MarketerEmail || "",
      MarketerName: user.partnerInfo.MarketerName || "",
      MarketerPhoneNumber: user.partnerInfo.MarketerPhoneNumber || "",
      PhoneNumber: user.partnerInfo.PhoneNumber || "",
      Postal: user.partnerInfo.Postal || "",
      Province: user.partnerInfo.Province || "",
      Street: user.partnerInfo.Street || "",
      IconURL: user.partnerInfo.IconURL || "",
      Language1: user.partnerInfo.Language1 || null,
      Language2: user.partnerInfo.Language2 || null,
      Language3: user.partnerInfo.Language3 || null,
      Language4: user.partnerInfo.Language4 || null,
      CaAccountNumber: user.partnerInfo.CaAccountNumber || null,
      CaBankName: user.partnerInfo.CaBankName || null,
      CaEmail: user.partnerInfo.CaEmail || null, 
      CaPaymentMethod: user.partnerInfo.CaPaymentMethod || null,
      UsAccountNumber: user.partnerInfo.UsAccountNumber || null,
      UsBankName: user.partnerInfo.UsBankName || null,
      usEmail: user.partnerInfo.usEmail || null,
      UsPaymentMethod: user.partnerInfo.UsPaymentMethod || null,
      AppLaunchMessageMuteDate: user.partnerInfo.AppLaunchMessageMuteDate || ""
    });
  }

  useEffect(() => {
    const getProfile = async () => {
      var response = await partnerService.getProfile({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      })
      if (response.data) {
        let responseData = JSON.parse(response.data.toString());
        dispatch(setPartnerInfo(responseData));
      }
    }
    getProfile().catch(err => { console.error(err) })
  }, []);

  useEffect(() => {
    resetInfo();
  }, [user]);

  const onChangeInfo = (event: NativeSyntheticEvent<TextInputChangeEventData>, name: keyof PartnerInfo) => {
    setEditPartnerInfo({
      ...editPartnerInfo,
     [name]: event.nativeEvent.text
    })
  }

  const onClickCancelChanges = () => {
    resetInfo();
    setIsEdit(!isEdit);
  }

  const onClickSaveChanges = async () => {
    setLoading(true);
    let params = new URLSearchParams({
      contact: editPartnerInfo.ContactPerson,
      phoneNumber: editPartnerInfo.PhoneNumber,
      email: editPartnerInfo.Email
    }).toString();
    var response = await partnerService.updateProfile(
      { headers: 
        {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      },
      params
    );
    if (!response || !response.data) {
      console.log("error");
      setLoading(false);
    } else {
      setIsEdit(false);
      setLoading(false);
      const getProfile = async () => {
        var response = await partnerService.getProfile({
          headers: {
            aaid: user.userInfo.aaid,
            token: user.userInfo.token
          }
        })
  
        if (response.data) {
          let responseData = JSON.parse(response.data.toString());
          dispatch(setPartnerInfo(responseData));
        }
      }
      getProfile().catch(err => { console.error(err) })
    }
  }

  const handleLogout = () => {
    const logout = async () => {
      await tokenService.deleteAccessTokenAsync();
      dispatch(setLoggedIn(false));
      dispatch(setCountry(CountryType.None));
    }
    logout().catch(err => console.error(err));
  }
  
  return (
    <>
    {!isMobile && 
      <>
        <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop, backgroundColor: Colors.lightGrey }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
            <View style={{ flex: 1 }}>
              <View style={{ marginTop: Sizes.large }}>
                <MyInformation />
                <AppGap size={Sizes.large} direction={'vertical'}/>
                <AppLabel color={countryColor} text='My Information'></AppLabel>
                <AppGap size={Sizes.large} direction={'vertical'}/>
                <View style={{ ...CommonStyles.card, backgroundColor: Colors.white }}>
                  <View>
                    <Text style={{ fontFamily: FontFamilys.semiBold, letterSpacing: 0.28, fontSize: 20 }}>Partner Information</Text>
                  </View>
                  <AppGap direction="vertical" size={Sizes.large} />
                    <>
                      <View style={{ flex: 1, flexDirection: isMobile ? 'column' : 'row' }}>
                        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start' }}>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Business ID</Text>
                            <Text>{user.partnerInfo.Bid}</Text>
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Business Name</Text>
                            <Text>{user.partnerInfo.BusinessName}</Text>
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Contact Person</Text>
                            <Text>{user.partnerInfo.ContactPerson}</Text>
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Phone Number</Text>
                            {isEdit ? (
                              <TextInput value={editPartnerInfo.PhoneNumber} placeholder='Type here' placeholderTextColor={Colors.grey} onChange={e => onChangeInfo(e, 'PhoneNumber')} style={{ width: 250, borderWidth: 1, borderRadius: 5, padding: 4, borderStyle: 'solid', borderColor: "#bbbbbb" }}></TextInput>
                              ) : (
                              <Text>{user.partnerInfo.PhoneNumber}</Text>
                            )}
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                        </View>
                        <AppGap size={100} />
                        <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start' }}>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Email</Text>
                            {isEdit ? (
                              <TextInput value={editPartnerInfo.Email} placeholder='Type here' placeholderTextColor={Colors.grey} onChange={e => onChangeInfo(e, 'Email')} style={{ width: 250, borderWidth: 1, borderRadius: 5, padding: 4, borderStyle: 'solid', borderColor: "#bbbbbb"  }}></TextInput>
                              ) : (
                              <Text>{user.partnerInfo.Email}</Text>
                            )}
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Street</Text>
                            <Text>{user.partnerInfo.Street}</Text>
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Province/State</Text>
                            <Text>{user.partnerInfo.Province}</Text>
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                          <View style={{ flex: 1 , flexDirection: 'row', paddingVertical: Sizes.small, maxHeight: 30, alignItems: 'center' }}>
                            <Text style={{ ...TextStyles.semiSubtitle, width: labelWidth }}>Country</Text>
                            <Text>{user.partnerInfo.Country}</Text>
                          </View>
                          <View style={{ backgroundColor: Colors.lightGrey, height: 1, width: '100%', minWidth: 250 }}></View>
                        </View>
                      </View>
                      <AppGap direction="vertical" size={20} />
                      {isEdit ? (
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <View style={{ width: isMobile ? '100%' : 300 }}>
                            <AppButton text="Cancel Changes" size="sm" shape="rect" textSize={14} onPress={onClickCancelChanges} disabled={loading}></AppButton>
                          </View>
                          <View style={{ width: isMobile ? '100%' : 300 }}>
                            <AppButton text="Save Changes" size="sm" shape="rect" textSize={14} onPress={onClickSaveChanges} loading={loading}></AppButton>
                          </View>
                        </View>
                      ) : (
                        <View style={{ width: isMobile ? '100%' : 300 }}>
                          <AppButton text="Update Information" size="sm" shape="rect" textSize={14} onPress={() => setIsEdit(!isEdit)}></AppButton>
                        </View>
                      )}
                    </>
                </View>
                <AppGap size={isMobile ? 100 : 200} direction={'vertical'} />
              </View>
            </View>
          </View>
        </ScrollView>
        <AppFooter/>
      </>
    }
    {isMobile &&
      <SafeAreaView style={{ flex: 1, backgroundColor: Colors.darkBlue }}>
        <ScrollView style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingBottom: Sizes.large, paddingTop: Sizes.extraLarge }}>Profile</Text>
            <View style={{ paddingLeft: Sizes.medium }}>
              <Pressable onPress={handleLogout} style={{ height: 12, flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                <MaterialCommunityIcons name="logout" size={24} color="white" />
                <Text style={{ ...TextStyles.semiSubtitle, color: Colors.white }}>Logout</Text>
              </Pressable>
            </View>
          </View>

          <View style={{ marginBottom: Sizes.extraLarge }}>
            <Image source={profileIconURL} style={{ height: 70, width: 70, borderRadius: 50 }}/>
          </View>

          <View style={{ borderRadius: 10, backgroundColor: Colors.white }}>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Business ID</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.Bid}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Business Name</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.BusinessName}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Contact Person</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.ContactPerson}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Phone Number</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.PhoneNumber}</Text>
              </View>
              <Pressable onPress={() => {navigation.navigate('ChangePhoneNumber')}} style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Feather name="edit-3" size={16} color={Colors.darkBlue} />
              </Pressable>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Email</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.Email}</Text>
              </View>
              <Pressable onPress={() => {navigation.navigate('ChangeEmail')}} style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Feather name="edit-3" size={16} color={Colors.darkBlue} />
              </Pressable>
            </View>

            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Street</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.Street}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Province/State</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.Province}</Text>
              </View>
            </View>
            <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
              <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
                <Text style={{ ...TextStyles.paragraph, color: Colors.grey }}>Country</Text>
                <Text style={{ ...TextStyles.paragraph, color: Colors.darkBlue }}>{user.partnerInfo.Country}</Text>
              </View>
            </View>
          </View>
          <AppGap direction="vertical" size={30} />
          <View style={{ width: isMobile ? '100%' : 300 }}>
            <AppButton text="Change Password" shape="rect" color={Colors.white} outline textSize={14} onPress={() => {navigation.navigate('ChangePassword')}}></AppButton>
          </View>
          <AppGap size={isMobile ? 100 : 200} direction={'vertical'} />
        </ScrollView>
      </SafeAreaView>
    }
    </>
  )
}