import { instance } from "../api/axios";
import { ApiRequest } from "../types/requests/ApiRequest";
import { ApiResponse } from "../types/responses/ApiResponse";
import { BoxOneHeader } from "../types/headers/BoxOneHeader";
import { GetCountriesRequest } from "../types/requests/GetCountriesRequest";
import { GetCountriesResponse } from "../types/responses/GetCountriesResponse";
import { GetPrepaidPlansRequest } from "../types/requests/GetPrepaidPlansRequest";
import { GetPrepaidPlansResponse } from "../types/responses/GetPrepaidPlansResponse";
import { VerifySimCardRequest } from "../types/requests/VerifySimCardRequest";
import { VerifySimCardResponse } from "../types/responses/VerifySimCardResponse";
import { PostActivationInfoPrepaidRequest } from "../types/requests/PostActivationInfoPrepaidRequest";
import { PostActivationInfoPrepaidResponse } from "../types/responses/PostActivationInfoPrepaidResponse";
import { GetTotalPrepaidRequest } from "../types/requests/GetTotalPrepaidRequest";
import { GetTotalPrepaidResponse } from "../types/responses/GetTotalPrepaidResponse";
import { GetPartnerPromocodeRequest } from "../types/requests/GetPartnerPromocodeRequest";
import { GetPartnerPromocodeResponse } from "../types/responses/GetPartnerPromocodeResponse";

const PARTNER_PORTAL_SYSTAG = "PartnerPortal2023";
const PARTNER_PORTAL_API_TOKEN = "J2pgiRZu3EiGZPQv4/+hSJh2L8kjOwFx";

export const activationService = {
  getCountries: async (): Promise<ApiResponse<GetCountriesResponse>> => await instance.get<GetCountriesRequest, ApiResponse<GetCountriesResponse>>("/Activation/GetCountries", 
  { 
    headers: {
      client: "alpha",
      Apitoken: "75e142e9fd9decba5da64d86874ce5b6"
    }
  }),
  getPrepaidPlans: async (request: ApiRequest<GetPrepaidPlansRequest>, headers: BoxOneHeader): Promise<ApiResponse<GetPrepaidPlansResponse>> => await instance.post<ApiRequest<GetPrepaidPlansRequest>, ApiResponse<GetPrepaidPlansResponse>>("/Activation/PrepaidPlans", {}, 
  { 
    params: request.data, 
    headers: {
      client: PARTNER_PORTAL_SYSTAG,
      Apitoken: PARTNER_PORTAL_API_TOKEN,
      aaid: headers.headers.aaid,
      token: headers.headers.token
    }
  }),
  verifySimCard: async (request: ApiRequest<VerifySimCardRequest>, headers: BoxOneHeader): Promise<ApiResponse<VerifySimCardResponse>> => await instance.post<ApiRequest<VerifySimCardRequest>, ApiResponse<VerifySimCardResponse>>(`/Activation/VerifySimcard?simnum=${request.data.simNum}`, {}, 
  { 
    headers: {
      client: PARTNER_PORTAL_SYSTAG,
      Apitoken: PARTNER_PORTAL_API_TOKEN,
      aaid: headers.headers.aaid,
      token: headers.headers.token
    }
  }),
  postActivationInfoPrepaid: async (request: ApiRequest<PostActivationInfoPrepaidRequest>, headers: BoxOneHeader): Promise<ApiResponse<PostActivationInfoPrepaidResponse>> => await instance.post<ApiRequest<PostActivationInfoPrepaidRequest>, ApiResponse<PostActivationInfoPrepaidResponse>>(`/Activation/PostActivationInfoPrepaid?term=${request.data.queryString.term}`, {
    ...request.data.payload
  }, 
  {
    headers: {
      client: PARTNER_PORTAL_SYSTAG,
      Apitoken: PARTNER_PORTAL_API_TOKEN,
      aaid: headers.headers.aaid,
      token: headers.headers.token
    }
  }),
  getTotalPrepaid: async (request: ApiRequest<GetTotalPrepaidRequest>, headers: BoxOneHeader): Promise<ApiResponse<GetTotalPrepaidResponse>> => await instance.get<ApiRequest<GetTotalPrepaidRequest>, ApiResponse<GetTotalPrepaidResponse>>("/Activation/GetTotalPrepaid", 
  { 
    params: request.data,
    headers: {
      client: PARTNER_PORTAL_SYSTAG,
      Apitoken: PARTNER_PORTAL_API_TOKEN,
      aaid: headers.headers.aaid,
      token: headers.headers.token
    }
  }),
  getPartnerPromocode: async (request: ApiRequest<GetPartnerPromocodeRequest>): Promise<ApiResponse<GetPartnerPromocodeResponse>> => await instance.get<ApiRequest<GetPartnerPromocodeRequest>, ApiResponse<GetPartnerPromocodeResponse>>(`/Activation/GetPartnerPromocode?bizid=${request.data.bizid}&countryId=${request.data.countryId}`, 
  {
    headers: {
      client: "alpha",
      Apitoken: "75e142e9fd9decba5da64d86874ce5b6"
    }
  }) 
}