import { View } from 'react-native';
import { AppColumn } from './AppColumn';
import { AppGap } from './AppGap';

type AppRowProps = {
  children: React.ReactElement<typeof AppColumn | typeof AppGap>[] | React.ReactElement<typeof AppColumn | typeof AppGap>;
}

export function AppRow(props: AppRowProps) {
  return (<View style={{ flexDirection: 'row' }}>{props.children}</View>);
}
