export default {
  footer: 130,
  verticalFooter: 190,
  header: 100,
  scrollViewPaddingBottom: 140, // based on footerHeight + 40
  scrollViewPaddingTop: 40,
  mainPagePaddingTop: 10,
  mobileScrollViewPaddingTop: 20,
  signUpViewPaddingBottom: 200,
  mobileHeaderHeight: 100,
}