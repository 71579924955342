import { useState } from "react";
import DropDownPicker from "react-native-dropdown-picker";
import FontFamilys from "../constants/FontFamilys";
import { Feather } from '@expo/vector-icons';
import Colors from "../constants/Colors";

type DropDownProps = {
  value?: any;
  items: any;
  setValue?: any;
  placeholder?: any;
  minHeight?: number;
  webDesktop?: boolean;
  onChangeValue?: (value: string) => void;
}

export default function DropDown(props: DropDownProps) {
  const [ open, setOpen ] = useState(false);
  const [ items, setItems ] = useState(props.items);

  return (
    <DropDownPicker 
      open={open}
      value={props.value}
      items={props.items}
      setOpen={setOpen}
      setValue={props.setValue}
      setItems={setItems}
      placeholder={props.placeholder}
      onChangeValue={(value) => { props.onChangeValue && props.onChangeValue(value) }}
      // originally SCROLLVIEW
      // however, this does not work on Android.
      listMode="MODAL"
      closeAfterSelecting={true}
      TickIconComponent={() => ( <Feather name="check" size={16} style={{ color: props.webDesktop ? Colors.red : Colors.darkBlue }}/> )}
      ArrowUpIconComponent={() => ( <Feather name="chevron-up" size={16} style={{ color: props.webDesktop ? Colors.white : Colors.darkBlue }}/> )}
      ArrowDownIconComponent={() => ( <Feather name="chevron-down" size={16} style={{ color: props.webDesktop ? Colors.white : Colors.darkBlue }}/> )}
      style={{ minHeight: props.minHeight ? props.minHeight : 35, borderRadius: 4, borderColor: props.webDesktop ? Colors.red : Colors.darkBlue, backgroundColor: props.webDesktop ? Colors.red : Colors.white }}
      textStyle={{ fontFamily: props.webDesktop ? FontFamilys.bold : FontFamilys.regular, fontSize: props.webDesktop ? 14 : 12, color: props.webDesktop ? Colors.white : Colors.darkBlue }}
      listItemContainerStyle={{ borderColor: Colors.darkBlue }}
      listItemLabelStyle={{ fontFamily: FontFamilys.regular, fontSize: 12, color: props.webDesktop ? Colors.red : Colors.darkBlue }}
      dropDownContainerStyle={{ borderColor: props.webDesktop ? Colors.red : Colors.darkBlue, backgroundColor: Colors.white }}
      selectedItemContainerStyle={{ backgroundColor: props.webDesktop ? Colors.dimRed : Colors.lightBlue }}
      selectedItemLabelStyle={{ fontFamily: FontFamilys.regular, fontSize: 12 }}
    />
  )
}
