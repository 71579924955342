import { View } from 'react-native';

type AppGapProps = {
  size: number;
  direction?: 'horizontal' | 'vertical';
}

export function AppGap(props: AppGapProps) {
  return (<View style={{ [props.direction === 'horizontal' || !props.direction ? 'width' : 'height']: props.size }} />);
}
