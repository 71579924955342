import { ScrollView, Text, View, Image, TextInput, useWindowDimensions, Pressable, TouchableOpacity, SafeAreaView } from "react-native"
import { MyInformation } from "../components/MyInformation"
import Sizes from "../constants/Sizes"
import Heights from "../constants/Heights"
import useIsMobile from "../hooks/useIsMobile";
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import { AppFooter } from "../components/AppFooter";
import { useState, useEffect } from "react";
import { partnerService } from "../services/PartnerService";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { AppGap } from "../components/AppGap";
import { AppButton } from "../components/AppButton";
import TextStyles from "../constants/TextStyles";
import Colors from "../constants/Colors";
import { CountryType } from "../types/enums/CountryType";
import CommonStyles from "../constants/CommonStyles";
import { validateEmail } from "../utilities/utilities";
import FontFamilys from "../constants/FontFamilys";
import ResponsiveWidths from "../constants/ResponsiveWidths";
import DropDownPicker from "react-native-dropdown-picker";
import DropdownStyles from "../constants/DropdownStyles";
import Autocomplete from "react-native-autocomplete-input";
import { useNavigation } from "@react-navigation/native";
import { AppModal } from "../components/AppModal";
import { useFocusEffect } from "@react-navigation/native";
import React from "react";
import { ticketService } from "../services/TicketService";

interface SupportFormInfo {
    email: string,
    mainCategory: string,
    subCategory: string,
    subject: string,
    description: string,
}
interface SubCategoryOptions {
    [key: string]: { value: string; label: string }[];
}

const helpTopics = [
    {articleId: 3, label: "How does the online activation work?"},
    {articleId: 4, label: "How can a traveler who has just arrived in Canada purchase a SIM card?"},
    {articleId: 6, label: "Is it possible to schedule the activation date of the SIM card?"},
    {articleId: 5, label: "Is it possible to purchase a SIM card outside of Canada?"},
    {articleId: 7, label: "What plans are available on PhoneBox?"},
    {articleId: 8, label: "How does a Monthly account work?"},
    {articleId: 9, label: "How does a prepaid account work?"},
    {articleId: 10, label: "How do I start using my PhoneBox SIM card?"},
    {articleId: 18, label: "Does PhoneBox charge a fee to change my plan?"},
    {articleId: 27, label: "Is it possible to change the plan anytime?"},
    {articleId: 28, label: "Can I upgrade or downgrade my plan?"},
    {articleId: 29, label: "How can I change my plan from Prepaid to Monthly?"},
    {articleId: 30, label: "How can I change my plan from Monthly to Prepaid?"},
    {articleId: 31, label: "How do I terminate my account?"},
    {articleId: 32, label: "Can I schedule the termination date of my account?"},
    {articleId: 33, label: "Do I get a refund for unused services when I terminate my account?"},
    {articleId: 34, label: "When will I get my refund after terminating my account?"},
    {articleId: 35, label: "How do I transfer my phone number to a different service provider?"},
    {articleId: 36, label: "When will PhoneBox deliver a SIM card?"},
    {articleId: 37, label: "Which plans are available at PhoneBox?"},
    {articleId: 38, label: "How good is the PhoneBox network coverage in Canada?"},
    {articleId: 39, label: "Why should I choose PhoneBox over other service providers?"},
    {articleId: 40, label: "Do monthly plans have a lock-in contract?"},
    {articleId: 41, label: "Why have I not received my SIM card yet?"},
    {articleId: 42, label: "How can I track the delivery of my SIM card?"},
    {articleId: 43, label: "The online activation didn't work for my US plan. What should I do?"},
    {articleId: 44, label: "Why is the delivery of my SIM card delayed?"},
    {articleId: 45, label: "Why is no one responding to my emails?"},
    {articleId: 78, label: "What are Multiline Accounts?"},
    {articleId: 79, label: "What are the benefits of adding multiple accounts into one using the multi-lining account functionality?"},
    {articleId: 80, label: "How do I add a line on my online account using the multi-lining option?"},
    {articleId: 81, label: "How do I remove a line on my online account using the multi-lining option?"},
    {articleId: 70, label: "Does PhoneBox offer shipping of SIM cards?"},
    {articleId: 16, label: "What do I do when my phone is showing “No Service”?"},
    {articleId: 46, label: "Why can't I make outgoing calls?"},
    {articleId: 47, label: "Why can't I receive inbound calls?"},
    {articleId: 48, label: "Why can't I send text messages?"},
    {articleId: 49, label: "Why can't I receive text messages?"},
    {articleId: 50, label: "Is it possible to set-up an auto-extension for my prepaid plan?"},
    {articleId: 51, label: "What are the payment methods for prepaid plans?"},
    {articleId: 52, label: "What are the payment methods for monthly plans?"},
    {articleId: 53, label: "What is the billing cycle for prepaid plans?"},
    {articleId: 54, label: "What is the billing cycle for monthly plans?"},
    {articleId: 12, label: "How does a vacation plan work?"},
    {articleId: 13, label: "What is the difference between prepaid and monthly accounts?"},
    {articleId: 14, label: "Can I extend my vacation plan?"},
    {articleId: 15, label: "I forgot to extend my prepaid plan after the 30-day service. How can I make a payment?"},
    {articleId: 55, label: "Do I get a notification when my prepaid plan is about to expire?"},
    {articleId: 56, label: "Do I get a notification when I have exceeded my data allowance?"},
    {articleId: 57, label: "Can I still receive calls and SMS when my prepaid plan has expired?"},
    {articleId: 58, label: "How do I add more data to my prepaid plan?"},
    {articleId: 59, label: "Does a prepaid line expire?"},
    {articleId: 60, label: "What is eSIM?"},
    {articleId: 62, label: "How does eSIM activation work?"},
    {articleId: 61, label: "What plans are available with eSIM at PhoneBox?"},
    {articleId: 63, label: "Are all devices compatible with eSIM?"},
    {articleId: 76, label: "Can I port in my phone number from my previous mobile carrier when I already have my PhoneBox's account activated?"},
    {articleId: 77, label: "Can I port-in a landline phone number to PhoneBox?"},
    {articleId: 64, label: "How will I know my Phone number?"},
    {articleId: 65, label: "Can I change the start date of the service with PhoneBox?"},
    {articleId: 66, label: "Is my device compatible with PhoneBox network?"},
    {articleId: 67, label: "What is data overage fee and how does it work?"},
    {articleId: 68, label: "Can I sign-up for a PhoneBox plan if I am under 18 years old?"},
    {articleId: 69, label: "Can I choose my phone number when I sign up?"},
    {articleId: 71, label: "Can I use an international credit card to pay for a US phone number?"},
    {articleId: 72, label: "Can I pay for the US phone number in cash?"},
    {articleId: 73, label: "Do I have to contact Phonebox to terminate a US plan?"},
    {articleId: 74, label: "How good is the Phonebox network coverage in the US?"},
    {articleId: 75, label: "What is PhoneBox network speed in the US?"},
    {articleId: 82, label: "Do PhoneBox USA plans include international calling services?"}
  ]

export default function SupportTicketScreen() {
    const isMobile = useIsMobile();
    const marginHorizontal = useHorizontalContainerMargin();
    const dimensions = useWindowDimensions();
    const navigation = useNavigation();
    const [modalVisible, setModalVisible] = useState(false);
    const user = useSelector((state: RootState) => state.user);
    const isCanada = user.country === CountryType.Canada;
    const countryColor = isCanada ? Colors.blue : Colors.green;
    const notesWidth = 375;
    const formWidth = 600;
    const [unfilledInputs, setUnfilledInputs] = useState<any[]>([]);
    const [unfilledInputError, setUnfilledInputError] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [showPhoneError, setShowPhoneError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const inputBorderColor = isMobile ? Colors.white : Colors.grey;
    const inputLabelColor = isMobile ? Colors.white : Colors.black;
    const extraWideScreen = !(dimensions.width < ResponsiveWidths.xxl);

    const [matchHelpTopics, setMatchHelpTopics] = useState<{ articleId: number; label: string; }[]>([]);

    const [formInfo, setFormInfo] = useState({
        // date: "",
        email: user.partnerInfo.Email,
        mainCategory: "",
        subCategory: "",
        subject: "",
        description: "",
    } as SupportFormInfo);

    const subCategoryOptions: SubCategoryOptions = {
        "Activations": [
            { value: "Schedule Activations", label: "Schedule Activations" },
            { value: "Service Starting Date Changes", label: "Service Starting Date Changes" },
            { value: "Cancel Activation", label: "Cancel Activation" },
            { value: "Refund Activation", label: "Refund Activation" },
            { value: "Port in", label: "Port in" },
            { value: "Phone number", label: "Phone number" },
            { value: "Others", label: "Others" }
        ],
        "eSIM": [
            { value: "QR code", label: "QR code" },
            { value: "Installation", label: "Installation" },
            { value: "Device compatibility", label: "Device compatibility" }
        ],
        "Plan Change": [
            { value: "Prepaid Extension", label: "Prepaid Extension" },
            { value: "Monthly Plan", label: "Monthly Plan" },
            { value: "Others", label: "Others" }
        ],
        "Billing": [
            { value: "Payment failed", label: "Payment failed" },
            { value: "Method of payment (MOP)", label: "Method of payment (MOP)" },
            { value: "Other types of payment issue", label: "Other types of payment issue" },
            { value: "Data overage", label: "Data overage" },
            { value: "Invoice", label: "Invoice" }
        ],
        "Suspension": [
            { value: "Unpaid Suspension", label: "Unpaid Suspension" },
            { value: "Lost & Stolen Suspension", label: "Lost & Stolen Suspension" },
            { value: "Vacation Plan Suspension", label: "Vacation Plan Suspension" },
            { value: "Others", label: "Others" }
        ],
        "Termination": [
            { value: "Schedule Termination", label: "Schedule Termination" },
            { value: "Termination Changes", label: "Termination Changes" },
            { value: "Cancel Termination Request", label: "Cancel Termination Request" },
            { value: "Port out", label: "Port out" },
            { value: "Others", label: "Others" }
        ],
        "Service issues": [
            { value: "Data (Internet) Issue", label: "Data (Internet) Issue" },
            { value: "Call or Text Issue", label: "Call or Text Issue" },
            { value: "Entire Network Issue (SOS - no service)", label: "Entire Network Issue (SOS - no service)" },
            { value: "Service Disruption", label: "Service Disruption" },
            { value: "Service Delay", label: "Service Delay" },
            { value: "Others", label: "Others" }
        ],
        "Shipping": [
            { value: "Address update/modification", label: "Address update/modification" },
            { value: "Shipping cancellation", label: "Shipping cancellation" },
            { value: "Shipping status", label: "Shipping status" },
            { value: "Others", label: "Others" }
        ],
        "Commission": [
            { value: "Payout timeline", label: "Payout timeline" },
            { value: "Payment method", label: "Payment method" },
            { value: "Others", label: "Others" }
        ]
    };

    // dropdown-related strings, only for use of dropdown control, not for form submission/validation.
    const [supportInfoMainCategory, setSupportInfoMainCategory] = useState("");
    const [supportInfoSubCategory, setSupportInfoSubCategory] = useState("");

    // dropdown-related booleans
    const [openMainCategory, setOpenMainCategory] = useState(false);
    const [openSubCategory, setOpeSubCategory] = useState(false);

    const [mainCategoryList, setMainCategoryList] = useState([
      { value: "Activations", label: "Activations" },
      { value: "eSIM", label: "eSIM" },
      { value: "Plan Change", label: "Plan Change" },
      { value: "Billing", label: "Billing" },
      { value: "Suspension", label: "Suspension" },
      { value: "Termination", label: "Termination" },
      { value: "Service issues", label: "Service issues" },
      { value: "Shipping", label: "Shipping" },
      { value: "Commission", label: "Commission" },
      { value: "Others", label: "Others" }
    ]);

    useFocusEffect(
      React.useCallback(() => {
        setFormInfo({
          email: user.partnerInfo.Email,
          mainCategory: "",
          subCategory: "",
          subject: "",
          description: "",
        })
        setErrorMessage("");
      }, [])
    );

    const [subCategoryList, setSubCategoryList] = useState<{ value: string; label: string; }[]>([]);

    const onChangeFormInfo = (input: string, field: keyof SupportFormInfo) => {
        setFormInfo({
            ...formInfo,
            [field]: input
        });
    }
    const onSelectMainCategory = (category: string) => {
        setFormInfo({
            ...formInfo,
            mainCategory: category
        });
        setSubCategoryList(subCategoryOptions[category]);
    }
    const onSelectSubCategory = (category: string) => {
        setFormInfo({
            ...formInfo,
            subCategory: category
        });
    }

    const createSupportTicket = async () => {
      var createSupportTicketResponse = await ticketService.createNewSupportTicket({
        MainCategory: formInfo.mainCategory,
        SubCategory: formInfo.subCategory,
        Notes: formInfo.subject + " - " + formInfo.description,
      }, {
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      })

      if (!createSupportTicketResponse || createSupportTicketResponse.status !== 204) {
        console.log("Something went wrong in createSupportTicket.");
        setErrorMessage("Something went wrong with submitting your ticket. Please try again later");
        return;
      }

      if (createSupportTicketResponse && createSupportTicketResponse.status === 204) {
        console.log("Clear fields.");
        setFormInfo({
          ...formInfo,
          mainCategory: "",
          subCategory: "",
          subject: "",
          description: "",
        } as SupportFormInfo);
        setModalVisible(true);
      }
    }

    const handleCloseModal = () => {
      setFormInfo({
        email: user.partnerInfo.Email,
        mainCategory: "",
        subCategory: "",
        subject: "",
        description: "",
      });
      setModalVisible(false);
      navigation.navigate("Home");
    }

    const onClickSubmitOrder = () => {
        let emailError = false;
        if (formInfo.email && !validateEmail(formInfo.email)) {
            emailError = true;
        }
        setShowEmailError(emailError);

        let inputs = [] as any[];
        Object.keys(formInfo).forEach(prop => {
          const isPopulated = formInfo[prop as keyof SupportFormInfo];

          if (prop === "subCategory" && formInfo.mainCategory === "Others") {
              return;
          }
          else if (prop === "subCategory" && !isPopulated) {
              inputs.push(prop);
          }
          // check if prop is empty, check if prop is needed or not
          if (!isPopulated && (!["description"].includes(prop))) {
            inputs.push(prop);
          }
          if (!isPopulated && (["description"].includes(prop)) && (formInfo.mainCategory === "Others")) {
            inputs.push(prop);
          }
        });

        setUnfilledInputs(inputs);

        if (inputs.length > 0) {
          setUnfilledInputError(true);
        } else {
          setUnfilledInputError(false);
          createSupportTicket().catch(err => { 
            console.error(err);
            if (err.data.ExceptionMessage) {
              setErrorMessage(err.data.ExceptionMessage + ".");
            } else {
              setErrorMessage("Something went wrong with submitting your ticket. Please try again later."); 
            }
          });
        }
    }

    const unfilledInput = (input: string) => {
      return unfilledInputs.includes(input);
    }

    const SupportFormInput = (props: { valueName: keyof SupportFormInfo, labelText: string }) => {
      const isDescription = props.valueName === "description" as keyof SupportFormInfo
      return (
        <View style={{ marginBottom: Sizes.medium }}>
          <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput(props.valueName) ? Colors.red : inputLabelColor }}>{props.labelText}</Text>
          <TextInput
            style={{ ...CommonStyles.input, padding: Sizes.medium, backgroundColor: Colors.white, borderColor: unfilledInput(props.valueName) ? Colors.red : inputBorderColor }}
            onChangeText={(e) => onChangeFormInfo(e, props.valueName)}
            value={formInfo[props.valueName]}
            multiline={isDescription}
            numberOfLines={isDescription ? 4 : 1}
            editable={props.valueName !== "email" ? true : false}
            selectTextOnFocus={props.valueName !== "email" ? true : false}
          />
        </View>
      )
    }

    const SupportFormArticleLauncher = (props: { valueName: keyof SupportFormInfo, labelText: string }) => {
      const isDescription = props.valueName === "description" as keyof SupportFormInfo
      const handleOnBlur = () => {
        setTimeout(() => {
          setMatchHelpTopics([]);
        }, 100); // Delay of 100ms to allow article on press to fire first
      };
      const handleOnFocus = () => {
        setMatchHelpTopics(helpTopics.filter((topic) => topic.label.toLowerCase().includes(formInfo[props.valueName].toLowerCase())).slice(0, 5));
      }

      return (
        <View style={{...CommonStyles.autocompleteRoot, marginBottom: Sizes.medium }}>
          <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput(props.valueName) ? Colors.red : inputLabelColor }}>{props.labelText}</Text>
          <Autocomplete
            inputContainerStyle={{ ...CommonStyles.autocompleteContainer, backgroundColor: Colors.white, borderColor: unfilledInput(props.valueName) ? Colors.red : inputBorderColor }}
            style={{paddingHorizontal: 10, borderRadius: 5}}
            value={formInfo[props.valueName]}
            onChangeText={(newValue: string) => {
              setFormInfo({
                ...formInfo,
                [props.valueName]: newValue
              });
              setMatchHelpTopics(helpTopics.filter((topic) => topic.label.toLowerCase().includes(newValue.toLowerCase())).slice(0, 5));
            }}
            data={(formInfo[props.valueName] && !isMobile) ? matchHelpTopics.map((option) => option.label) : []}
            flatListProps={isMobile ? {} : {
              renderItem: ({ item }) => (
                <TouchableOpacity onPress={() => {
                  const selectedHelpTopic = helpTopics.find((topic) => topic.label === item);
                  const selectedHelpTopicId = selectedHelpTopic ? selectedHelpTopic.articleId : null;
                  window._elev.openArticle(String(selectedHelpTopicId));
                }}>
                  <Text style={CommonStyles.autocompleteSelector}>{item}</Text>
                </TouchableOpacity>
              ),
            }}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
        </View>
      )
    }

    const FormBody = (
        <>
          {SupportFormInput({ valueName: "email", labelText: "Email *" })}
          <View style={{ marginBottom: Sizes.medium, zIndex: openMainCategory ? 2000 : 1 }}>
            <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("mainCategory") ? Colors.red : inputLabelColor }}>Please choose your issue below *</Text>
            <DropDownPicker
              style={{ ...DropdownStyles.desktop, borderColor: unfilledInput("mainCategory") ? Colors.red : inputBorderColor }}
              searchable={true}
              listMode={isMobile ? "MODAL" : "FLATLIST"}
              open={openMainCategory}
              value={supportInfoMainCategory}
              setOpen={setOpenMainCategory}
              setValue={setSupportInfoMainCategory}
              onChangeValue={(item: any) =>
                onSelectMainCategory(item)
              }
              placeholder="Select a topic"
              // @ts-ignore
              items={mainCategoryList}
            />
          </View>
          {formInfo.mainCategory !== "Others" &&
            <View style={{ marginBottom: Sizes.medium, zIndex: openSubCategory ? 1000 : 1 }}>
              <Text style={{ fontFamily: FontFamilys.semiBold, marginBottom: Sizes.small, color: unfilledInput("subCategory") ? Colors.red : inputLabelColor }}>Please tell us more about your issue *</Text>
              <DropDownPicker
                style={{ ...DropdownStyles.desktop, borderColor: unfilledInput("subCategory") ? Colors.red : inputBorderColor }}
                searchable={true}
                listMode={isMobile ? "MODAL" : "FLATLIST"}
                open={openSubCategory}
                value={supportInfoSubCategory}
                setOpen={setOpeSubCategory}
                setValue={setSupportInfoSubCategory}
                onChangeValue={(item: any) =>
                  onSelectSubCategory(item)
                }
                placeholder="Select a topic"
                // @ts-ignore
                items={subCategoryList}
              />
            </View>
          }
          {SupportFormArticleLauncher({ valueName: "subject", labelText: "Subject *" })}
          {SupportFormInput({ valueName: "description", labelText: formInfo.mainCategory === "Others" ? "Description *" : "Description" })}
          {unfilledInputError &&
            <Text style={{ color: Colors.red }}>Please fill in all of the required fields.</Text>
          }
          {showEmailError &&
            <Text style={{ color: Colors.red }}>Please enter a correctly formatted email.</Text>
          }
          {showPhoneError &&
            <Text style={{ color: Colors.red }}>Please enter a valid phone number.</Text>
          }
          {errorMessage.length > 0 &&
            <Text style={{ color: Colors.red }}>{errorMessage}</Text>
          }
          <View style={{ marginTop: Sizes.large }}>
            <AppButton text='Submit' onPress={() => onClickSubmitOrder()} />
          </View>
        </>
    )

    return (
      <>
        {!isMobile &&
          <>
            <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
              <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
                <View style={{ flex: 1 }}>
                  <View style={{ marginTop: Sizes.large, marginBottom: Sizes.extraLarge }}>
                    <MyInformation />
                    <AppGap direction='vertical' size={Sizes.large}></AppGap>
                    <View style={{ width: extraWideScreen ? (notesWidth + formWidth + 50) : "100%", margin: "auto" }}>
                      <AppGap direction='vertical' size={Sizes.large}></AppGap>
                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: "space-between" }}>
                        <View style={{ paddingVertical: Sizes.large }}>
                          <Text style={{ ...TextStyles.paragraph, color: Colors.white, zIndex: 1, fontStyle: 'italic' }}>Submit Your</Text>
                          <Text style={{ ...TextStyles.subtitle, color: Colors.white, zIndex: 1 }}>Support Request</Text>
                          <View style={{ backgroundColor: countryColor, height: '100%', width: '2000%', marginLeft: (-marginHorizontal - 500), position: 'absolute', top: 0 }}></View>
                        </View>
                        {/* <View style={{ position: "relative" }}>
                            <Image source={require('../assets/images/order-sim-card.png')} style={{ width: 195, height: 140, borderRadius: Sizes.medium, position: "absolute", right: 0, top: -20 }}></Image>
                        </View> */}
                      </View>
                      <AppGap direction='vertical' size={Sizes.large}></AppGap>
                      <View>
                        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-around' }}>
                          <View style={{ flex: 1, flexDirection: "column", marginTop: Sizes.medium, maxWidth: formWidth }}>
                            {FormBody}
                          </View>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
              <AppGap direction='vertical' size={150} />
              <AppModal visible={modalVisible} onClose={handleCloseModal}>
                <View>
                <Text style={{ ...TextStyles.heading, textAlign: "center" }}>Thank you!</Text>
                <View style={{ padding: Sizes.large }}>
                  <Text style={{ textAlign: "center" }}>Your request was successfully submitted.</Text>
                  <Text style={{ textAlign: "center" }}>Close this modal to be redirected to the dashboard.</Text>
                </View>
                <AppButton onPress={handleCloseModal} text="Close"></AppButton>
                </View>
              </AppModal>
            </ScrollView>
            <AppFooter />
          </>
        }
        {isMobile &&
          <ScrollView style={{ flex: 1, flexDirection: 'column', backgroundColor: Colors.darkBlue, paddingTop: Heights.mainPagePaddingTop }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1, paddingTop: Sizes.extraLarge }}>
                <Text style={{ ...TextStyles.mobileTitle, color: Colors.white, paddingTop: Sizes.extraLarge }}>Support</Text>
                <AppGap direction='vertical' size={Sizes.medium}></AppGap>
                <Text style={{ fontFamily: FontFamilys.regular, color: Colors.white, fontSize: 16 }}>Submit Your Support Request</Text>
                <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
                <View style={{ flex: 1, flexDirection: "column", marginTop: Sizes.medium, maxWidth: formWidth }}>
                  {FormBody}
                </View>
              </View>
            </View>
            <AppGap size={100} direction={'vertical'}></AppGap>
            <AppModal visible={modalVisible} onClose={handleCloseModal}>
              <View>
              <Text style={{ ...TextStyles.heading, textAlign: "center" }}>Thank you!</Text>
              <View style={{ padding: Sizes.large }}>
                <Text style={{ textAlign: "center" }}>Your request was successfully submitted.</Text>
                <Text style={{ textAlign: "center" }}>Close this modal to be redirected to the dashboard.</Text>
              </View>
              <AppButton onPress={handleCloseModal} text="Close"></AppButton>
              </View>
            </AppModal>
          </ScrollView>
        }
      </>
    )
}

