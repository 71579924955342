import { StyleSheet } from 'react-native';
import Colors from './Colors';

const styles = StyleSheet.create({
    container: {
        paddingVertical: 35,
        paddingHorizontal: 20,
    },
    card: {
        backgroundColor: Colors.white,
        borderRadius: 10,
        padding: 20,
    },
    reportContainer: {
        backgroundColor: Colors.darkBlue,
        paddingTop: 10,
        paddingBottom: 140
    }
});

export default styles;