import { View, Text, ScrollView, Image } from "react-native"
import { RootStackScreenProps } from "../types"
import useHorizontalContainerMargin from "../hooks/useHorizontalContainerMargin";
import Heights from "../constants/Heights";
import Sizes from "../constants/Sizes";
import { MyInformation } from "../components/MyInformation";
import { AppGap } from "../components/AppGap";
import { AppLabel } from "../components/AppLabel";
import CommonStyles from "../constants/CommonStyles";
import Colors from "../constants/Colors";
import TextStyles from "../constants/TextStyles";
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { CountryType } from "../types/enums/CountryType";
import { AppButton } from "../components/AppButton";
import FontFamilys from "../constants/FontFamilys";
import { useEffect, useState } from "react";
import { partnerService } from "../services/PartnerService";
import { setAvailableSimCards } from "../store/user";
import { AppFooter } from "../components/AppFooter";
import useIsMobile from "../hooks/useIsMobile";
import { Platform } from "react-native";
import * as WebBrowser from 'expo-web-browser';

export default function InventoryScreen({navigation}: RootStackScreenProps<'Inventory'>) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const countryColor = user.country === CountryType.Canada ? Colors.blue : Colors.green;
  const [monthlyTotal, setMonthlyTotal] = useState(0);
  const [prepaidTotal, setPrepaidTotal] = useState(0);
  const [prepaidRogers, setPrepaidRogers] = useState(0);
  const [prepaidTelus, setPrepaidTelus] = useState(0);
  const [postpaidRogers, setPostpaidRogers] = useState(0);
  const [postpaidTelus, setPostpaidTelus] = useState(0);

  useEffect(() => {
    const getSimCards = async () => {
      var response = await partnerService.getSimCards({
        headers: {
          aaid: user.userInfo.aaid,
          token: user.userInfo.token
        }
      })
  
      if (!response || !response.data) {
        console.log("Something went wrong")
      }
  
      if (response && response.data) {
        dispatch(setAvailableSimCards(response.data));
      }
    }
    getSimCards().catch(err => console.error(err));
  }, []);

  useEffect(() => {
    if (typeof user.availableSimCards.prepaid.rogers === 'number' && typeof user.availableSimCards.monthly.rogers === 'number' && typeof user.availableSimCards.prepaid.telus === 'number' && typeof user.availableSimCards.monthly.telus === 'number') {
      setPrepaidTotal(user.availableSimCards.prepaid.rogers + user.availableSimCards.prepaid.telus);
      setMonthlyTotal(user.availableSimCards.monthly.rogers + user.availableSimCards.monthly.telus);
      setPrepaidTelus(user.availableSimCards.prepaid.telus);
      setPrepaidRogers(user.availableSimCards.prepaid.rogers);
      setPostpaidTelus(user.availableSimCards.monthly.telus);
      setPostpaidRogers(user.availableSimCards.monthly.rogers);
    }
  }, [user.availableSimCards])

  const handlePressOrderSimCards = () => {
    navigation.navigate('OrderSimCards');
  }

  return (
    <>
      {!isMobile &&
        <>
          <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop, backgroundColor: Colors.lightGrey }}>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
              <View style={{ flex: 1 }}>
                <View style={{ marginTop: Sizes.large }}>
                  <MyInformation />
                  <AppGap direction='vertical' size={Sizes.large}></AppGap>
                  <AppLabel text='SIM Cards Inventory'></AppLabel>
                  <AppGap direction='vertical' size={Sizes.large}></AppGap>
                  <View style={{ ...CommonStyles.card, flex: 1, flexDirection: 'row', backgroundColor: Colors.white }}>
                    <View style={{ padding: Sizes.large, paddingHorizontal: Sizes.extraLarge, width: '20%', minWidth: 120 }}>
                      <Text style={{ ...TextStyles.semiSubtitle, textAlign: 'center' }}>Monthly Plan</Text>
                      <View style={{ backgroundColor: Colors.lightGrey, padding: Sizes.small, borderRadius: Sizes.medium, marginTop: Sizes.large, height: '80%' }}>
                        <View style={{ backgroundColor: Colors.white, padding: Sizes.small, borderRadius: Sizes.medium, alignItems: 'center', height: '100%' }}>
                          <Text style={{ ...TextStyles.title, color: countryColor }}>{monthlyTotal}</Text>
                          <View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                              <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Rogers:</Text>
                              <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{postpaidRogers}</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                              <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Telus:</Text>
                              <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{postpaidTelus}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ backgroundColor: Colors.lightGrey, height: '100%', width: 1 }}></View>
                    <View style={{ padding: Sizes.large, paddingHorizontal: Sizes.extraLarge, width: '20%', minWidth: 120 }}>
                      <Text style={{ ...TextStyles.semiSubtitle, textAlign: 'center' }}>Prepaid Plan</Text>
                      <View style={{ backgroundColor: Colors.lightGrey, padding: Sizes.small, borderRadius: Sizes.medium, marginTop: Sizes.large, height: '80%' }}>
                        <View style={{ backgroundColor: Colors.white, padding: Sizes.small, borderRadius: Sizes.medium, alignItems: 'center', height: '100%' }}>
                          <Text style={{ ...TextStyles.title, color: countryColor }}>{prepaidTotal}</Text>
                          <View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                              <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Rogers:</Text>
                              <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{prepaidRogers}</Text>
                            </View>
                            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                              <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Telus:</Text>
                              <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{prepaidTelus}</Text>
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ backgroundColor: Colors.lightGrey, height: '100%', width: 1 }}></View>
                    <View style={{ padding: Sizes.large, paddingHorizontal: Sizes.extraLarge, width: '60%' }}>
                      <Text style={{ ...TextStyles.semiSubtitle }}>Need More?</Text>
                      <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={{ paddingTop: Sizes.extraLarge }}>
                          <AppButton size='sm' textSize={14} shape='rect' text='Order SIM Cards' onPress={handlePressOrderSimCards}></AppButton>
                        </View>
                        <Image source={require('../assets/images/sim-card.png')} style={{ width: 352, height: 242 }} />
                      </View>
                    </View>
                  </View>
                </View>
                <AppGap size={100} direction={'vertical'}></AppGap>
              </View>
            </View>
          </ScrollView>
          <AppFooter/>
        </>
      }
      {isMobile &&
        <ScrollView style={{ paddingBottom: Heights.scrollViewPaddingBottom, paddingTop: Heights.mainPagePaddingTop }}>
          <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', marginHorizontal: marginHorizontal }}>
            <View style={{ flex: 1 }}>
              <Text style={{ ...TextStyles.title, color: Colors.white }}>SIM Cards Inventory</Text>
              <AppGap direction='vertical' size={Sizes.extraLarge}></AppGap>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                <View style={{ width: "48%" }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Monthly Plan</Text>
                  <View style={{ backgroundColor: Colors.lightGrey, borderRadius: Sizes.medium }}>
                    <View style={{ backgroundColor: Colors.white, padding: Sizes.small, borderRadius: Sizes.medium, alignItems: 'center' }}>
                      <Text style={{ ...TextStyles.title, color: countryColor }}>{monthlyTotal}</Text>
                      <View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                          <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Rogers:</Text>
                          <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{postpaidRogers}</Text>
                        </View>
                        <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                          <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Telus:</Text>
                          <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{postpaidTelus}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: "48%" }}>
                  <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Prepaid Plan</Text>
                    <View style={{ backgroundColor: Colors.lightGrey, borderRadius: Sizes.medium,}}>
                      <View style={{ backgroundColor: Colors.white, padding: Sizes.small, borderRadius: Sizes.medium, alignItems: 'center' }}>
                        <Text style={{ ...TextStyles.title, color: countryColor }}>{prepaidTotal}</Text>
                        <View>
                          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Rogers:</Text>
                            <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{prepaidRogers}</Text>
                          </View>
                          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                            <Text style={{ ...TextStyles.paragraph, fontFamily: FontFamilys.bold }}>Telus:</Text>
                            <Text style={{ ...TextStyles.paragraph, paddingLeft: Sizes.small }}>{prepaidTelus}</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                </View>
              </View>
              <AppGap direction='vertical' size={Sizes.large}></AppGap>
              <Text style={{ ...TextStyles.subtitle, color: Colors.white, marginBottom: Sizes.medium }}>Need More?</Text>
              <View style={{ ...CommonStyles.card, flex: 1, flexDirection: 'row', backgroundColor: Colors.white }}>
                <View>
                  <Image source={require('../assets/images/sim-card.png')} style={{ width: 300, height: 200, alignSelf: 'center' }} />
                  <AppButton textSize={14} shape='rect' text='Order SIM Cards' onPress={handlePressOrderSimCards}></AppButton>
                </View>
              </View>
            </View>
          </View>
          <AppGap size={100} direction={'vertical'}></AppGap>
        </ScrollView>
      }
    </>
  )
}