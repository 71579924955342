import React, { useEffect } from 'react';
import { useState } from "react"
import { Text, View, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';
import { AppAlert } from '../components/AppAlert';
import { AppButton } from '../components/AppButton';
import { AppTextInput } from '../components/AppTextInput';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import PageLayoutStyles from '../constants/PageLayoutStyles';
import Colors from '../constants/Colors';
import { RootStackScreenProps } from "../types"
import { AppDispatch } from '../store';
import { setLoggedIn, setUserInfo } from '../store/user';
import { authService } from '../services/AuthService';
import { tokenService } from '../services/TokenService';
import { SignInError } from '../types/enums/SignInErrorMessage';
import FontFamilys from '../constants/FontFamilys';

export default function LogInScreen({ navigation }: RootStackScreenProps<'LogIn'>) {
    const dispatch = useDispatch<AppDispatch>();

    const [ username, setUsername ] = useState('')
    const [ password, setPassword ] = useState('')
    const [loggingIn, setLoggingIn] = useState(false);
    const [loginErrorMsg, setLoginErrorMsg] = useState('');

    const handleLogin = () => {
      if (username === '') {
        setLoginErrorMsg('Username is required.');
        return;
      }
  
      if (password === '') {
        setLoginErrorMsg('Password is required.');
        return;
      }

  
      const login = async () => {
        setLoggingIn(true);
    
        var response = await authService.signIn({ Username: username, Password: password })
        let responseData = response.data.PartnerInfoModel;
        
        if (response.data.ErrorMessage === SignInError.TooManyAttempts || response.data.ErrorMessage === SignInError.UserLocked) {
          dispatch(setLoggedIn(false));
          setLoggingIn(false);
          navigation.navigate('TooManyAttempts');
          return;
        }
  
        if (responseData.Token === null || response.data.ErrorMessage === SignInError.InvalidUserIdOrPassword) {
          dispatch(setLoggedIn(false));
          setLoggingIn(false);
          setLoginErrorMsg('Incorrect username or password.');
          return;
        }
        
        if (response && response.data) { 
          setLoggingIn(false);
    
          await tokenService.setAccessTokenAsync(responseData.Token);
          dispatch(setUserInfo({
            bid: responseData.bID,
            aaid: responseData.AaId,
            category: responseData.Category,
            token: responseData.Token,
            i_rep: responseData.i_rep,
            isTmpPwd: responseData.isTmpPwd,
            scAustralia: responseData.scAustralia,
            scCanada: responseData.scCanada,
            scUSALyca: responseData.scUSALyca,
            scUSAPlintron: responseData.scUSAPlintron,
            PartnerSimTypeDesc: responseData.PartnerSimTypeDesc,
            LangId: responseData.LangId
          }));
          dispatch(setLoggedIn(true));
          setLoginErrorMsg('');
        }
      }

      login().catch(err => {
        console.error(err);
        setLoggingIn(false);
        setLoginErrorMsg('Something went wrong with login().');
        return;
      });
    }

  return (
    <View style={{ ...PageLayoutStyles.container, flex: 1, flexDirection: 'column', justifyContent: 'space-between', backgroundColor: Colors.darkBlue }}>
      <View>
        <Text style={{ ...TextStyles.mobileTitle, color: Colors.white }}>Sign In</Text>
        <View style={{ marginTop: Sizes.large }}>
            <AppTextInput onChangeText={(text) => setUsername(text.trim())} placeholder="Username"></AppTextInput>
        </View>
        <View style={{ marginTop: Sizes.large }}>
            <AppTextInput onChangeText={(text) => setPassword(text)} placeholder="Password" secureTextEntry></AppTextInput>
        </View>
      </View>

      <View style={{ marginBottom: Sizes.extraLarge }}>
        <Pressable style={{ alignItems: 'center', marginBottom: Sizes.large }} onPress={() => navigation.navigate("ForgotPassword")}>
          <Text style={{ color: Colors.white, fontFamily: FontFamilys.regular }}> Forgot your <Text style={{ textDecorationLine: 'underline' }}>password?</Text></Text>
        </Pressable>
        <AppButton text="Log In" shape="rect" onPress={handleLogin} loading={loggingIn}></AppButton>
        {loginErrorMsg.length > 0 && (
          <View style={{ marginVertical: Sizes.medium }}>
            <AppAlert text={loginErrorMsg}></AppAlert>
          </View>
        )}
      </View>
    </View>
  )
}
