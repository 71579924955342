import { AffiliateLinkLanguage } from "../types/enums/AffiliateLinkLanguage";
import { LangIdType } from "../types/enums/LangIdType";
import { BoxOnePrepaidPlan } from "../types/models/BoxOnePrepaidPlan";
import { Client } from "../types/models/Client";
import { ClientReportEntry } from "../types/models/ClientReportEntry";
import { CommissionReportEntry } from "../types/models/CommissionReportEntry";
import { CustomerStatusHistory } from "../types/models/CustomerStatusHistory";
import { ParsedPlan } from "../types/models/ParsedPlan";

/**
 * Returns strings with only numbers in it.
 * @param value 
 * @returns 
 */
export function numbersOnly(value: string) {
  return value.toString().replace(/\D/g, "")
};

/**
 * Returns a "stringed" version of a month for date compatibility.
 * i.e. int 5 = June = str '06'
 * @param month
 * @returns string
 */
export const parseMonth = (month: number) => {
  let stringMonth = `${month + 1}`;
  if (stringMonth === '0') {
    stringMonth = '12';
  }
  if (stringMonth === '13') {
    stringMonth = '1';
  }
  if (stringMonth.length === 1) {
    stringMonth = '0' + stringMonth;
  }
  return stringMonth;
}

/**
 * Returns the latest status_history in status_history_changes
 * @param data 
 * @returns latest status from a statusHistory
 */
export const findLatestStatus = (data: CustomerStatusHistory) => {
  let status = "";
  let timestamp = "";

  for (let i in data.status_history_changes) {
    if (!status) {
      status = data.status_history_changes[i].status_name;
      timestamp = data.status_history_changes[i].time_stamp;
    } else {
      if (status && (new Date(timestamp) < new Date(data.status_history_changes[i].time_stamp)) && data.status_history_changes[i].status_name.toLowerCase() === "limited") {
        status = data.status_history_changes[i].status_name;
        timestamp = data.status_history_changes[i].time_stamp;
      }
    }
  }

  return { status: status, timestamp: timestamp };
}

/**
 * Sorts a Client Report by Activation Date.
 * @param report
 * @returns sorted ClientReportEntry[]
 */
export const sortByActivationDate = (report: ClientReportEntry[]) => {
  let sortedReport = report.sort((entryA, entryB) => {
    if (entryA.ActivationDate === "") {
      return 1;
    }
    if (entryB.ActivationDate === "") {
      return -1;
    }

    const activationDateA = new Date(entryA.ActivationDate);
    const activationDateB = new Date(entryB.ActivationDate);

    if (activationDateA < activationDateB) {
      return 1;
    }
    if (activationDateA > activationDateB) {
      return -1;
    }
    return 0;
  })

  return sortedReport;
}

/**
 * Sorts a Commission Report by Activation Date.
 * @param report
 * @returns sorted CommissionReportEntry[]
 */
export const sortByActivationDateCommission = (report: CommissionReportEntry[]) => {
  let sortedReport = report.sort((entryA, entryB) => {
    if (entryA.ActivationDate === "") {
      return 1;
    }
    if (entryB.ActivationDate === "") {
      return -1;
    }

    const activationDateA = new Date(entryA.ActivationDate);
    const activationDateB = new Date(entryB.ActivationDate);

    if (activationDateA < activationDateB) {
      return 1;
    }
    if (activationDateA > activationDateB) {
      return -1;
    }
    return 0;
  })

  return sortedReport;
}

/**
 * Formats a given phone number with hyphens and a country code.
 * @param phoneNumberString 
 * @param marketer  // if it's for marketer (Marketer Info Sections)
 * @param client   // if it's a client (i.e. a client or commission report)
 * @returns string
 */
export const formatPhoneNumber = (phoneNumberString: string, marketer = false, client = false) => {
  if (client && phoneNumberString) {
    let finalString = "";
    if (phoneNumberString.length === 11) {
      finalString = phoneNumberString.slice(1, 4) + "-" + phoneNumberString.slice(4, 7) + "-" + phoneNumberString.slice(7, 11);
    } else {
      finalString = phoneNumberString.slice(0, 3) + "-" + phoneNumberString.slice(3, 6) + "-" + phoneNumberString.slice(6, 10);
    }
    return finalString;
  }
  if (phoneNumberString) {
    // strip all special characters
    let formattedNumberString = phoneNumberString.replace(/[^0-9]/g, "");
    let finalString;
    switch (formattedNumberString.length) {
      case (11):
        if (formattedNumberString.slice(0, 1) === '1') {
          finalString = "+1 (" + formattedNumberString.slice(1, 4) + ")-" + formattedNumberString.slice(4, 7) + "-" + formattedNumberString.slice(7, 11);
        }
        // special case: India
        if (formattedNumberString.slice(0, 2) === '91') {
          finalString = "+(" + formattedNumberString.slice(0, 2) + ") " + formattedNumberString.slice(2, 6) + "-" + formattedNumberString.slice(6, 11);
        }
        if (formattedNumberString.slice(0, 2) !== '91' && formattedNumberString.slice(0, 1) !== '1') {
          finalString = "+(" + formattedNumberString.slice(0, 2) + ") " + formattedNumberString.slice(2, 3) + "-" + formattedNumberString.slice(5, 3) + "-" + formattedNumberString.slice(8, 3);
        }
        break;
      case (10):
        if (marketer) {
          finalString = "+1 (" + formattedNumberString.slice(0, 3) + ") " + formattedNumberString.slice(3, 6) + "-" + formattedNumberString.slice(6, 10);
        } else {
          finalString = formattedNumberString.slice(0, 3) + "-" + formattedNumberString.slice(3, 6) + "-" + formattedNumberString.slice(6, 10);
        }
        break;
      default:
        finalString = '';
    }
    return finalString;
  } else {
    return '';
  }
}

/**
 * Validates an email for Portaone. Portaone self server web care login only allows certain characters. The rules for this are here: The value must contain only latin letters, digits, '-', '_', '@', and '.'
 * @param email 
 * @returns 
 */
export const validateEmail = (email: string): boolean => {
  // Regular expression pattern to match the email address format
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Match the email address against the pattern
  const match = pattern.test(email);

  // If the match is successful, the email address is valid
  return match;
}

/**
 * Returns the proper AffiliateLinkLanguage based on the passed langId.
 * @param langId 
 */
export const getAffiliateLinkLanguageFromLangId = (langId: LangIdType | undefined) => {
  switch (langId) {
    case (LangIdType.Japanese):
      return AffiliateLinkLanguage.Japanese
    case (LangIdType.Chinese):
      return AffiliateLinkLanguage.Chinese
    case (LangIdType.Korean):
      return AffiliateLinkLanguage.Korean
    case (LangIdType.Vietnamese):
      return AffiliateLinkLanguage.Vietnamese
    case (LangIdType.Portuguese):
      return AffiliateLinkLanguage.Portuguese
    default:
      return ""
  }
}

/**
 * Gets carrier full name.
 * @param short 
 * @returns 
 */
export const getCarrierFullName = (short: string) => {
  if (short === "T") {
    return "Telus";
  } else {
    return "Rogers";
  }
}

/**
 * Shows data.
 * @param data 
 * @returns 
 */
export const formatPhoneData = (data: string | number, planTypeD: string | number) => {
  if (data === "Voice" || Number(data) === 0 || data === "Voice Only") {
    return "Voice";
  }
  if (Number(data) > 9000 || data === "Unlimited") {
    return "Unlimited";
  }
  if (typeof data === 'string' && (data.indexOf('MB') !== -1 || data.indexOf('GB') !== -1)) {
    return data;
  }
  if (Number(data) > 99 && Number(planTypeD) === 90) {
    return data + " GB";
  }
  if (Number(data) > 99) {
    return data + " MB";
  }
  return data + " GB";
};

/**
 * Turns plans from the activationService "getPostpaidPlans" into ParsedPlans
 * @param plans 
 */
export const mapBoxOnePrepaidPlans = (plans: BoxOnePrepaidPlan[], isOt = false) => {
  let parsedPlans: ParsedPlan[] = plans.map((plan: BoxOnePrepaidPlan) => {
    return {
      planId: plan.PlanId,
      plan_fee: plan.Fee,
      carrier: getCarrierFullName(plan.Carriers),
      pfCode: plan.PlanCode,
      data: formatPhoneData((plan.Data / (1024 * 1024)).toString(), plan.PlanTypeD),
      planTypeD: plan.PlanTypeD,
      calling: plan.Calling,
      message: plan.Message,
      plintronPlanId: null,
      subscriptionId: null,
      isOt: isOt
    }
  });
  return parsedPlans;
}

/**
 * Returns the proper label depending on planTypeD
 * @param days 
 * @returns string indicating timespan
 */
export const getTimeFromPlanTypeD = (days: string, isOt = false) => {
  if (isOt) {
    return days == '1' ? `for ${days} day` : `for ${days} days`;
  } else {
    switch (days) {
      case "7":
        return "/week";
      case "30":
        return "/month";
      case "90":
        return "/90 days";
      default:
        return "/month";
    }
  }
}


export const getFormattedDate = (date: Date) => {
  let year = date.getFullYear();

  let month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : '0' + month;

  let day = date.getDate().toString();
  day = day.length > 1 ? day : '0' + day;

  return month + '/' + day + '/' + year;
}

export const getPlanType = (client: Client) => {
  if (!client.ClientType) {
    return client.isPostpaid.toLowerCase() === "true" ? "Monthly" : "Prepaid";
  }
  if (["cp", 'ot'].indexOf(client.ClientType.toLowerCase()) > -1) {
    return `${client.ClientType} Prepaid`;
  }
  return client.ClientType;
};

export const has31Days = (month: number) => {
  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12];
  return monthsWith31Days.indexOf(month) !== -1;
};

export const getBillingPeriod = (startingDate: Date) => {
  const startingDateDay = startingDate.getDate();
  const startingMonth = startingDate.getMonth() + 1;
  const startingYear = startingDate.getFullYear();
  const isLeapYear = startingYear % 4 === 0;

  if (startingMonth === 1) { // Jan
    if (startingDateDay === 30) {
      return isLeapYear ? 31 : 30;
    }
    if (startingDateDay === 31) {
      return isLeapYear ? 30 : 29;
    }
    return 31;
  }

  if (has31Days(startingMonth)) { // Month contain 31 Days
    return 31; // Back end is reverted to Original Logic.
  }

  if (startingMonth === 2) { // Feb
    return isLeapYear ? 29 : 28;
  }
  return 30;
};

export const getTimeDifferenceInDays = (endDate: Date | number, startDate: Date | number) => {
  const end: number = +endDate;
  const start: number = +startDate;

  const diff: number = Math.abs(end - start);
  return Math.floor(diff / 86400000);
};