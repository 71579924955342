import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { AppButton } from '../components/AppButton';
import { AppGap } from '../components/AppGap';
import Colors from '../constants/Colors';
import Sizes from '../constants/Sizes';
import TextStyles from '../constants/TextStyles';
import useHorizontalContainerMargin from '../hooks/useHorizontalContainerMargin';
import useIsMobile from '../hooks/useIsMobile';
import { RootState } from '../store';
import { RootStackScreenProps } from '../types';

export default function NotFoundScreen({ navigation }: RootStackScreenProps<'NotFound'>) {
  const { loggedIn } = useSelector((state: RootState) => state.user);
  const isMobile = useIsMobile();
  const marginHorizontal = useHorizontalContainerMargin();
  const handleNavigation = () => {
    if (navigation.canGoBack()) {
      navigation.popToTop();
      return;
    }
    navigation.replace(loggedIn ? 'Root' : 'Landing');
  }

  return (
    <>
      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', padding: 20, marginHorizontal: marginHorizontal }}>
        <Text style={{ ...TextStyles.title, textAlign: 'center', color: isMobile ? Colors.white : Colors.black }}>Whoops!</Text>
        <Text style={{ ...TextStyles.subtitle, color: isMobile ? Colors.white : Colors.black, marginTop: Sizes.medium, textAlign: 'center' }}>The page you're looking for doesn't exist!</Text>
        <AppGap direction="vertical" size={20}></AppGap>
        <AppButton text={loggedIn ? 'Go to Dashboard' : 'Sign In'} onPress={handleNavigation}></AppButton>
      </View>
    </>
  );
}