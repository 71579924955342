export const carriers = [
  { key: 1, name: 'Bell' },
  { key: 2, name: 'Fido' },
  { key: 3, name: 'Rogers' },
  { key: 4, name: 'Telus' },
  { key: 5, name: 'Koodo' },
  { key: 6, name: 'Public Mobile' },
  { key: 7, name: 'Chatr' },
  { key: 8, name: 'Lucky Mobile' },
  { key: 9, name: 'Virgin' },
  { key: 10, name: 'Freedom' },
  { key: 11, name: 'Shaw' },
  { key: 12, name: 'CTExcel' },
  { key: 13, name: 'Others' },
];