export default {
  green: '#82C36D',
  dimGreen: '#F1F8EF',
  red: '#E93F25',
  grey: 'rgba(51, 51, 51, 0.5)',
  lightGrey: 'rgba(112, 112, 112, 0.1)',
  darkGrey: '#767577',
  black: '#333333',
  white: '#fff',
  offwhite: '#F9FCF8',
  blue: '#0494CA',
  dimRed: 'rgba(233, 63, 37, 0.15)',
  darkBlue: '#005068',
  lightBlue: '#ECFAFF',
  lightGreen: '#E3FADA',
  blueOverlay: 'rgba(4, 148, 202, 0.5)',
  greenOverlay: 'rgba(130, 195, 109, 0.5)',
  successGreen: "#008000"
};